import React from "react";
import Particles from "react-tsparticles";

const FloatingBubbles = () => {
  return (
    <Particles
      options={{
        particles: {
          number: { value: 50 },
          size: { value: 10, random: true },
          move: { direction: "top", speed: 0.5 },
          opacity: { value: 0.3, random: true },
          shape: { type: "circle" },
        },
        background: { color: "#ffffff" },
      }}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}
    />
  );
};

export default FloatingBubbles;
