/**
 * Formats the given amount as a currency string in Euros.
 *
 * @param {number} amount - The amount to be formatted.
 * @returns {string} The formatted currency string.
 */
export const formatCurrency = (amount) => {
  return new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  }).format(amount);
};
