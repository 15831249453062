// import React from 'react';

// interface CountdownUnitProps {
//   value: number;
//   label: string;
// }

// export function CountdownUnit({ value, label }: CountdownUnitProps) {
//   return (
//     <div className="bg-white/5 backdrop-blur border border-white/10 rounded-lg p-4 text-center">
//       <div className="text-4xl font-heading font-bold mb-1 bg-gradient-to-r from-primary-400 to-primary-200 bg-clip-text text-transparent">
//         {value}
//       </div>
//       <div className="text-sm text-primary-200 font-medium">{label}</div>
//     </div>
//   );
// }
// ---------------------------------------------------------------------------------
import React from "react";

export function CountdownUnit({ value, label }) {
  return (
    <div
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        backdropFilter: "blur(10px)",
        border: "1px solid rgba(255, 255, 255, 0.1)",
        borderRadius: "8px",
        padding: "16px",
        textAlign: "center",
      }}
    >
      <div
        style={{
          fontSize: "2rem",
          fontWeight: "bold",
          marginBottom: "4px",
          background: "linear-gradient(to right, #6D28D9, #818CF8)", // Replace with your primary colors
          WebkitBackgroundClip: "text",
          color: "transparent",
        }}
      >
        {value}
      </div>
      <div
        style={{
          fontSize: "0.875rem",
          color: "#A5B4FC", // Replace with your primary-200 color
          fontWeight: "500",
        }}
      >
        {label}
      </div>
    </div>
  );
}
