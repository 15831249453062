export const services = [
  {
    id: "massage-relaxant",
    name: "Massage Relaxant",
    description:
      "Un massage apaisant pour réduire le stress et la tension musculaire.",
    duration: 60,
    price: 75,
    schedule: {
      days: ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi"],
      hours: ["09:00", "10:00", "11:00", "14:00", "15:00", "16:00"],
    },
  },
  {
    id: "soin-visage",
    name: "Soin du Visage",
    description:
      "Un traitement complet pour une peau rayonnante et revitalisée.",
    duration: 45,
    price: 65,
    schedule: {
      days: ["Lundi", "Mercredi", "Vendredi", "Samedi"],
      hours: ["10:00", "11:00", "14:00", "15:00"],
    },
  },
  {
    id: "manucure",
    name: "Manucure Professionnelle",
    description: "Soin complet des mains avec massage et pose de vernis.",
    duration: 30,
    price: 45,
    schedule: {
      days: ["Mardi", "Jeudi", "Samedi"],
      hours: ["09:00", "10:00", "11:00", "14:00", "15:00"],
    },
  },
];


