export const COMMISSION_BASE = {
  id: "base",
  nom: "Commission Standard",
  pourcentage: 10,
  description: "Commission standard pour les nouveaux participants",
};

export const COMMISSION_FIDELE = {
  id: "fidele",
  nom: "Commission Fidélité",
  pourcentage: 15,
  description: "Commission pour les participants réguliers",
};

/**
 * Calculates the commission type based on the number of participations.
 *
 * @param {number} nombreParticipations - The number of participations by a participant.
 * @returns {object} - The commission object, either COMMISSION_FIDELE for regular participants or COMMISSION_BASE for new participants.
 */
export const calculerCommission = (nombreParticipations) => {
  return nombreParticipations > 1 ? COMMISSION_FIDELE : COMMISSION_BASE;
};
