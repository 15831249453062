import React from "react";
import { Bell } from "lucide-react";

import { useDeadlineStore } from "./deadlineStore.js";

/**
 * A component that displays a list of alerts as a sticky notification
 * at the bottom right of the screen. Unread alerts are highlighted with a
 * blue border on the left side. Each alert can be marked as read by
 * clicking on the "Marquer comme lu" button.
 */
export function AlertList() {
  // Fetching alerts and the function to mark them as read from the store
  const alerts = useDeadlineStore((state) => state.alerts);
  const markAlertAsRead = useDeadlineStore((state) => state.markAlertAsRead);

  return (
    <div
      style={{
        position: "fixed",
        bottom: "1rem",
        right: "1rem",
        maxWidth: "20rem",
        width: "100%",
      }}
    >
      {alerts
        .filter((alert) => !alert.isRead) // Show only unread alerts
        .map((alert) => (
          <div
            key={alert.id}
            style={{
              backgroundColor: "white",
              borderRadius: "0.5rem",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              padding: "1rem",
              marginBottom: "0.5rem",
              borderLeft: "4px solid #3b82f6",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <Bell
              style={{
                width: "1.25rem",
                height: "1.25rem",
                color: "#3b82f6",
                marginRight: "0.75rem",
                flexShrink: 0,
              }}
            />
            <div style={{ flexGrow: 1 }}>
              <p style={{ fontSize: "0.875rem", color: "#374151" }}>
                {alert.message}
              </p>
              <button
                onClick={() => markAlertAsRead(alert.id)}
                style={{
                  fontSize: "0.75rem",
                  color: "#3b82f6",
                  marginTop: "0.5rem",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                Marquer comme lu
              </button>
            </div>
          </div>
        ))}
    </div>
  );
}
