import { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import E_Trainings_Formateur from "./components/E-Trainings/E_Trainings_Formateur";
import E_Trainings_Verivication from "./components/E-Trainings/E_Trainings_Verivication";
import E_Trainings_Verivication_Detail from "./components/E-Trainings/E_Trainings_Verivication_Detail";
import { Formation_Dinitiation } from "./components/E-Trainings/Formation_Dinitiation";
import { Formation_Dinitiation_Course } from "./components/E-Trainings/Formation_Dinitiation_Course";
// import { Navbar } from './components/Navbar';
import CourseSingle from "./pages/CourseSingle";
import { E_Trainings } from "./pages/E_Trainings";
import { Home } from "./pages/Home";
import Dashboard_Login from "./components/dashboard_navbar/Dashboard_Login";
import Formation_Participant from "./components/E-Trainings/Formation_Participant";
import Formation_Participant_Course from "./components/E-Trainings/Formation_Participant_Course";
import Formation_Participant_Course_Details from "./components/E-Trainings/Formation_Participant_Course_Details";
import E_Trainings_Checkout from "./components/E-Trainings/E_Trainings_Checkout";
import E_Trainings_Course_price from "./components/E-Trainings/E_Trainings_Course_price";
import E_Trainings_Course_groups from "./components/E-Trainings/E_Trainings_Course_groups";
import E_Trainings_Certificate_Detai from "./components/E-Trainings/E_Trainings_Certificate_Detai";
import Formation_Dinitiation_Course_Details from "./components/E-Trainings/Formation_Dinitiation_Course_Details";
// import Dashboard_Register from "./components/dashboard_navbar/Dashboard_Register";

import "./App.css";
import Commercial_Online from "./pages/Commercial_Online";
import CommercialOnlineLogin from "./components/E-Trainings/commercial_online/CommercialOnlineLogin";
import CommercialOnlineRegister from "./components/E-Trainings/commercial_online/CommercialOnlineRegister";
import FomateurRegister from "./components/E-Trainings/fomateur_Register/FomateurRegister";
import NavBar from "./components/navigation/NavBar";
import RecruitmentForm from "./components/reactitment/RecruitmentForm";
import Hero from "./components/reactitment/Hero";
// import FomateurRegister from './components/E-Trainings/fomateur_Register/FomateurRegister';

/**
 * App is the main component of the application, it contains the routes to the different pages
 *
 * @param {boolean} auth - if true, the user is authenticated and can access the Formateur page
 * @param {function} setAuth - a function to set the auth state
 * @returns {JSX.Element} - the JSX element of the App component
 */
function App() {
  const [auth, setAuth] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {}, [auth]);

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/E-Trainings");
    }
  }, [location.pathname]);

  return (
    <div className="App">
      <NavBar />
      <Routes>
        {/* home */}
        {/* <Route path="/" element={<Home />} /> */}
        {/* E-Trainings */}
        <Route path="/E-Trainings" element={<E_Trainings />} />
        {/* E-Trainings > Verivication */}
        <Route path="/Verivication" element={<E_Trainings_Verivication />} />
        {/* E-Trainings > Verivication > Verivication-Detail */}
        <Route
          path="/Verivication-Detail/:id"
          element={<E_Trainings_Verivication_Detail />}
        />

        {/* E-Trainings > Verivication > E_Trainings_Certificate_Detai */}
        <Route
          path="/E_Trainings_Certificate_Detai/:farmation_id/:certificate_index"
          element={<E_Trainings_Certificate_Detai />}
        />
        {/* E-Trainings > formation-dinitiation */}
        <Route
          path="/formation-dinitiation"
          element={<Formation_Dinitiation />}
        />
        {/* E-Trainings > formation-dinitiation > ourse */}
        <Route
          path="/formation-dinitiation-ourse"
          element={<Formation_Dinitiation_Course />}
        />
        {/* E-Trainings > formation-dinitiation > Formation_Dinitiation_Course_Details */}
        <Route
          path="/Formation-Dinitiation_Course-Details"
          element={<Formation_Dinitiation_Course_Details />}
        />

        {/* E-Trainings > ourse > course-single */}
        <Route path="/course-single/:id" element={<CourseSingle />} />
        {/* E-Trainings > ourse > course-single > Course price */}
        <Route
          path="/Course-price/:id"
          element={<E_Trainings_Course_price />}
        />
        {/* E-Trainings > ourse > course-single > Course price > Checkout */}
        <Route path="/Course-Checkout" element={<E_Trainings_Checkout />} />
        {/* E-Trainings > ourse > course-single > Course price > Checkout > Course groups */}
        <Route path="/Course-groups" element={<E_Trainings_Course_groups />} />
        {/* E-Trainings > Dashboard_Register */}
        <Route
          path="/Dashboard-Register"
          element={
            // auth ? (
            //   <E_Trainings_Formateur setAuth={setAuth} />
            // ) : (
            // )
            <FomateurRegister />
            // <Dashboard_Register />
          }
        />
        {/* E-Trainings > Formateur */}
        <Route
          path="/Formateur"
          element={
            // auth ? (
            //   <E_Trainings_Formateur setAuth={setAuth} />
            // ) : (
            // )
            <Dashboard_Login setAuth={setAuth} />
          }
        />
        <Route
          path="/E_Trainings-Formateur"
          element={<E_Trainings_Formateur setAuth={setAuth} />}
        />
        {/* E-Trainings > Participant */}
        <Route path="/Participant" element={<Formation_Participant />} />
        {/* E-Trainings > Participant > Course */}
        <Route
          path="/Formation-Participant-Course"
          element={<Formation_Participant_Course />}
        />
        {/* E-Trainings > Participant > Course > Course Details */}
        <Route
          path="/Formation-Participant-Course-Details/:id"
          element={<Formation_Participant_Course_Details />}
        />
        {/* E-Trainings > Commercial Online */}
        <Route path="/Commercial-Online" element={<CommercialOnlineLogin />} />
        <Route
          path="/Commercial-Online-Register"
          element={<CommercialOnlineRegister />}
        />
        {/* E-Trainings > Commercial Online dachboard */}
        <Route
          path="/Commercial-Online-dachboard"
          element={<Commercial_Online />}
        />
        {/* reactitment */}
        <Route path="/recruitment" element={<Hero />} />
        {/* reactitment > recruter */}
        <Route path="/recruter" element={<RecruitmentForm />} />
      </Routes>
    </div>
  );
}

export default App;
