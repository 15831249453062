import React from "react";
import RegistrationForm from "./formation_gratuit/RegistrationForm";
import CourseInfo from "./formation_gratuit/CourseInfo";
import Box from "@mui/material/Box";

import "./formation_gratuit/style.css";

/**
 * Formation_Dinitiation component
 *
 * This component is used for the formation d'initiation page.
 *
 * @returns {JSX.Element} The JSX element for the Formation_Dinitiation component.
 */
export const Formation_Dinitiation = () => {
  return (
    <>
      <Box
        sx={{
          marginTop: "80px",
        }}
        className="Formation_Dinitiation"
      >
        <div className="min-h-screen bg-gradient-to-br from-blue-50 to-blue-100">
          <div className="container mx-auto px-4 py-12 md:py-24">
            <div className="grid md:grid-cols-2 gap-12 items-center">
              <RegistrationForm />
              <CourseInfo />
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};
