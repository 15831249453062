import React, { useState } from "react";
import {
  BookOpen,
  Video,
  MessageSquareText,
  GraduationCap,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

import "../../styles/E-Trainings/participant/css/tailwind.css";

function Formation_Participant() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    console.log("Email submitted:", email);
    navigate("/Formation-Participant-Course");
  };

  return (
    <Box
      sx={{
        marginTop: "80px",
      }}
      className="Formation_Participant"
    >
      <div className="min-h-screen bg-gradient-to-br from-blue-50 to-blue-100">
        <div className="container mx-auto px-4 py-12 md:py-24">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            {/* Left Column - Form Section */}
            <div className="bg-white p-8 rounded-2xl shadow-lg">
              <h2 className="text-3xl font-bold text-gray-800 mb-6">
                Commencez votre parcours d'apprentissage
              </h2>
              <p className="text-gray-600 mb-8">
                Rejoignez notre plateforme éducative et accédez à des ressources
                exclusives
              </p>

              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Adresse e-mail
                  </label>
                  <div className="relative">
                    <input
                      type="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Entrez l'e-mail pour commencer"
                      className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-300 focus:border-blue-300 transition-all"
                      required
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  className="w-full py-3 px-6 text-white font-medium rounded-lg bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-blue-600 hover:to-indigo-700 transition-all duration-200 transform hover:-translate-y-0.5"
                >
                  Envoyer
                </button>
              </form>
            </div>

            {/* Right Column - Features */}
            <div className="bg-blue-600 p-8 rounded-2xl text-white">
              <h1 className="text-3xl font-bold mb-8">
                ESPACE PARTICIPANT :
                <br />
                Accédez à vos ressources
              </h1>

              <div className="grid gap-6">
                <FeatureCard
                  icon={<Video className="w-6 h-6" />}
                  title="Enregistrement vidéo"
                  description="Accédez à vos vidéos enregistrées à tout moment"
                />

                <FeatureCard
                  icon={<BookOpen className="w-6 h-6" />}
                  title="Support de cours"
                  description="Consultez les supports pédagogiques"
                />

                <FeatureCard
                  icon={<GraduationCap className="w-6 h-6" />}
                  title="Bibliothèque numérique"
                  description="Une vaste collection de ressources éducatives"
                />

                <FeatureCard
                  icon={<MessageSquareText className="w-6 h-6" />}
                  title="Assistant-conseil"
                  description="Un accompagnement personnalisé pour votre réussite"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}

function FeatureCard({ icon, title, description }) {
  return (
    <div className="flex items-start space-x-4 p-4 rounded-lg bg-white/10">
      <div className="flex-shrink-0">{icon}</div>
      <div>
        <h3 className="font-semibold mb-1">{title}</h3>
        <p className="text-blue-50 text-sm">{description}</p>
      </div>
    </div>
  );
}

export default Formation_Participant;
