import React from "react";
import { TestimonialsHeader } from "./TestimonialsHeader.jsx";
import { TestimonialCard } from "./TestimonialCard.jsx";
import { CompanyRating } from "./CompanyRating.jsx";
import { testimonials, companies } from "./testimonials_data.js";

import "./style.css";

const TestimonialsSection = () => {
  return (
    <section
      style={{
        paddingBottom: "0px",
      }}
      className="TestimonialsSection py-24 bg-gradient-to-b from-white to-gray-50"
    >
      <div className="max-w-6xl mx-auto px-4">
        <TestimonialsHeader />

        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard key={index} {...testimonial} />
          ))}
        </div>

        {/* <div className="grid md:grid-cols-3 gap-8">
          {companies.map((company, index) => (
            <CompanyRating key={index} {...company} />
          ))}
        </div> */}
      </div>
    </section>
  );
};

export default TestimonialsSection;
