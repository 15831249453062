export const questions = [
  {
    id: "sector",
    text: "Dans quel secteur d'activité recherchez-vous ?",
    options: [
      "Technologie",
      "Finance",
      "Santé",
      "Education",
      "Commerce",
      "Industrie",
    ],
  },
  {
    id: "experience",
    text: "Quel niveau d'expérience recherchez-vous ?",
    options: [
      "Débutant (0-2 ans)",
      "Intermédiaire (2-5 ans)",
      "Confirmé (5-8 ans)",
      "Senior (8+ ans)",
    ],
  },
  {
    id: "contract",
    text: "Quel type de contrat proposez-vous ?",
    options: ["CDI", "CDD", "Freelance", "Stage", "Alternance"],
  },
  {
    id: "location",
    text: "Où est situé le poste ?",
    options: ["Paris", "Lyon", "Marseille", "Bordeaux", "Lille", "Télétravail"],
  },
  {
    id: "skills",
    text: "Quelles sont les compétences principales requises ?",
    options: [
      "Développement Web",
      "Marketing Digital",
      "Gestion de Projet",
      "Design",
      "Vente",
      "Communication",
    ],
  },
];
