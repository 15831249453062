import React, { useState } from "react";
import { Box } from "@mui/material";

import { TrainingCalendar } from "./TrainingCalendar";
import { DashboardHeader } from "./DashboardHeader";
import { SessionForm } from "./SessionForm";
import { SessionCard } from "./SessionCard";
import { mockSessions } from "./mockSessions";

import "./style.css";

/**
 * A React component that renders a section of a website dedicated to
 * e-trainings. It displays a list of courses, with filters by category and by
 * level (beginner, intermediate, advanced). The courses are displayed in a
 * grid, with each course represented by a card containing its title, category,
 * level, duration, schedule, and a link to its details page. The component uses
 * the useState hook to keep track of the active filter and the selected
 * category.
 *
 * @returns {JSX.Element} The rendered JSX element.
 */
function Formations() {
  const [sessions, setSessions] = useState(mockSessions);
  const [selectedSession, setSelectedSession] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);

  /**
   * Adds a new training session to the list of sessions.
   *
   * Generates a unique ID for the session and updates the sessions state
   * with the new session. It also hides the session form and clears the
   * selected slot state.
   *
   * @param {Object} sessionData - The data for the session to be added,
   * excluding the session ID.
   */
  const handleAddSession = (sessionData) => {
    const newSession = {
      ...sessionData,
      id: Math.random().toString(36).substr(2, 9),
    };
    setSessions([...sessions, newSession]);
    setShowForm(false);
    setSelectedSlot(null);
  };

  /**
   * Handles the selection of a time slot on the calendar.
   *
   * This function updates the state with the selected slot information,
   * clears any previously selected session, and displays the session form.
   *
   * @param {Object} slotInfo - An object containing the start and end times of the selected slot.
   */
  const handleSelectSlot = (slotInfo) => {
    setSelectedSlot({
      start: slotInfo.start,
      end: slotInfo.start,
    });
    setSelectedSession(null);
    setShowForm(true);
  };

  return (
    <div
      style={{
        width: "100%",
      }}
      className="Formations min-h-screen bg-gray-100"
    >
      <div className=" mx-auto  py-8">
        <DashboardHeader />

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              md: "2fr 1fr",
              xs: "1fr",
            },
            gap: "1rem",
          }}
        >
          <div>
            <TrainingCalendar
              events={sessions}
              onSelectEvent={(event) => {
                setSelectedSession(event);
                setShowForm(false);
              }}
              onSelectSlot={handleSelectSlot}
            />
          </div>

          <div>
            <div className="bg-white rounded shadow p-4">
              <h2 className="h5 font-weight-bold mb-4">
                {showForm ? "Nouvelle séance" : "Détails de la Session"}
              </h2>

              {showForm ? (
                <SessionForm
                  onSubmit={handleAddSession}
                  onCancel={() => {
                    setShowForm(false);
                    setSelectedSlot(null);
                  }}
                  initialData={
                    selectedSlot
                      ? {
                          id: "",
                          title: "",
                          theme: "",
                          trainer: "",
                          start: selectedSlot.start,
                          // start: Date.now(),
                          end: selectedSlot.end,
                          // end: Date.now(),
                          description: "",
                        }
                      : undefined
                  }
                />
              ) : selectedSession ? (
                <SessionCard session={selectedSession} />
              ) : (
                <p className="text-muted">
                  Sélectionnez une session pour voir les détails ou cliquez sur
                  le calendrier pour en créer une nouvelle
                </p>
              )}
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
}

export default Formations;
