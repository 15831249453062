import React from "react";
import { CheckCircle } from "lucide-react";

const objectives = [
  "Identifier et évaluer les risques en hygiène, sécurité et environnement.",
  "Veiller au respect et à l'application des consignes de sécurité transmises.",
  "Prévoir les équipements nécessaires à la prévention des situations d'accidents.",
  "Développer les performances HSE.",
  "Maîtriser la réglementation en vigueur en matière d'hygiène, sécurité et environnement.",
];

const Objectives = ({ course }) => {
  return (
    <div className="py-24 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-title text-center text-gray-900 mb-16">
          Objectifs de la Formation
        </h2>
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {course.Objectifs_list?.map((objective, index) => (
            <div
              key={index}
              className="bg-gray-50 rounded-xl p-8 shadow-sm hover:shadow-md transition-shadow"
            >
              <div className="flex items-start space-x-4">
                <CheckCircle className="w-6 h-6 text-green-500 flex-shrink-0 mt-1" />
                <p className="text-lg font-medium text-gray-700 leading-relaxed">
                  {objective}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Objectives;
