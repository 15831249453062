import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";

import { get_All } from "../../functions/restApi";
import Header from "./certifivate/Header";
import { Header as EvaluationHeader } from "./evaluation/Header";
import MenuCard from "./certifivate/MenuCard";
import Certificate from "./about/Certificate";
import { evaluationData } from "./evaluation/evaluationData";
import EvaluationGrid from "./evaluation/EvaluationGrid";

import "../../styles/E-Trainings/validation/css/E_Trainings_Certificate_Detai.css";
import "react-modal-video/scss/modal-video.scss";

/**
 * E_Trainings_Certificate_Detai is a React functional component that displays
 * a detailed view of an e-training certificate for a specific student. It
 * fetches data using the student's formation ID and certificate index from
 * the URL parameters. The component allows navigation between different
 * sections of the certificate, such as "About", "Evaluation", and "Gallery".
 *
 * @returns {JSX.Element} The JSX representation of the certificate detail page.
 */
const E_Trainings_Certificate_Detai = () => {
  const [sections, setSections] = useState("about");
  const [active, setActive] = useState(false);
  const [student, setStudent] = useState({});
  const [certificate, setCertificate] = useState({});

  const { farmation_id, certificate_index } = useParams();

  useEffect(() => {
    getStudent(farmation_id);
  }, [farmation_id]);

  /**
   * Fetches a specific student's data from the backend API by their
   * formation ID, and updates the state with the student's certificate
   * and student information.
   *
   * @param {string} id - The student's formation ID.
   * @returns {Promise<void>} A promise that resolves when the student's
   * data is fetched and the state is updated.
   */
  const getStudent = async (id) => {
    try {
      const res = await get_All(
        `https://ma-training-consulting-company-site-backend-one.vercel.app/api/get-one-validation-Student/${id}`
      );

      if (res) {
        setCertificate(
          res.data.student.students_certificates[certificate_index]
        );
        setStudent(res.data.student);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Box
        sx={{
          marginTop: {
            lg: "87px",
            md: "108px",
            xs: "-4px",
          },
        }}
        className=" Verivication Verivication-Detail E_Trainings_Certificate_Detai"
      >
        {/* Header */}
        <Box
          component="header"
          id="site_header"
          sx={{
            paddingRight: "3px",
            zIndex: "13",
            marginTop: {
              lg: "-6px",
              md: "-28px",
              xs: "83px",
            },
            transition: "all .5s",
            padding: "0px",
            paddingBottom: "97px",
          }}
          className={`header ${active ? "" : "mobile-menu-hide"}`}
        >
          <div className="min-h-screen bg-gradient-to-br from-blue-50 via-white to-purple-50 p-6">
            <div className="max-w-lg mx-auto space-y-6">
              <Header
                code={certificate?.certificate_id}
                userName={student?.students_full_name}
              />

              <MenuCard
                icon="📄"
                title="À propos du certificat"
                description="Consultez les détails de votre attestation et sa validité"
                onClick={() => {
                  setSections("about");
                  setActive(false);
                }}
              />

              <MenuCard
                icon="📊"
                title="Évaluation de participation"
                description="Vérifiez votre score et les critères d'évaluation"
                onClick={() => {
                  setSections("evaluation");
                  setActive(false);
                }}
              />

              <MenuCard
                icon="🏆"
                title="Galerie & recommandations"
                description="Accédez à vos réalisations et recommandations"
                onClick={() => {
                  setSections("gallery");
                  setActive(false);
                }}
              />
            </div>
          </div>
        </Box>
        {/* /Header */}
        {/* Mobile Header */}
        <Box
          sx={{
            background: "tra",
            zIndex: 2,
            marginTop: {
              md: "-28px",
              xs: "80px",
            },
          }}
          className="mobile-header mobile-visible d-lg-none d-flex align-items-center justify-content-end px-3 "
        >
          <a
            style={{
              cursor: "pointer",
              fontSize: "20px",
            }}
            onClick={() => setActive(!active)}
            className="menu-toggle mobile-visible"
          >
            <i className="fa fa-bars" />
          </a>
        </Box>
        {/* /Mobile Header */}
        {/* Main Content */}
        <div id="main" className="site-main">
          {/* Page changer wrapper */}
          <div>
            {/* Subpages */}
            <Box
              sx={{
                paddingLeft: {
                  lg: "416px",
                  xs: "10px",
                },
                paddingRight: "10px",
              }}
              className="subpages"
            >
              {/* About Subpage */}
              {sections === "about" && (
                <Box
                  component="section"
                  className="home pt-page pt-page-1 pl-page-2 section-with-bg section-paddings-0 about
                pt-page-current about
               "
                  sx={{
                    height: "100vh",
                    paddingTop: {
                      md: "0px",
                      xs: "100px",
                    },
                  }}
                >
                  <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
                    <Certificate
                      certificate={certificate}
                      name={student?.students_full_name}
                    />
                  </div>
                </Box>
              )}
              {/* End of About Subpage */}
              {/* Evaluation Me Subpage */}
              {sections === "evaluation" && (
                <Box
                  component="section"
                  sx={{
                    paddingTop: {
                      lg: "0px",
                      xs: "100px",
                    },
                  }}
                  className="about_me pt-page pt-page-2 
                pt-page-current evaluation
              "
                >
                  <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 p-8">
                    <div className="max-w-7xl mx-auto">
                      <EvaluationHeader />
                      <EvaluationGrid
                        sections={Object.values(evaluationData)}
                        evaluation_certificat={certificate}
                      />
                    </div>
                  </div>
                </Box>
              )}
              {/* End of Evaluation Me Subpage */}
              {/* Gallery Subpage */}
              {sections === "gallery" && (
                <Box
                  component="section"
                  className="resume pt-page pt-page-3 
                pt-page-current gallery
              "
                  sx={{
                    paddingBottom: "20px",
                    paddingTop: {
                      md: "0px",
                      xs: "94px",
                    },
                  }}
                >
                  {/* ---------------------------------------------------------- */}
                  <div className="letter-container">
                    <div className="decorative-element" />
                    <div className="content">
                      <div className="header-content">
                        <h1 className="title">
                          {" "}
                          {
                            certificate?.recommandation_Galerie_1_titre_partie_1
                          }{" "}
                        </h1>
                        <div className="participant-info">
                          <div className="label">Nom du participant</div>
                          <div className="participant-name">
                            {" "}
                            {
                              certificate?.recommandation_Galerie_1_titre_partie_2
                            }
                          </div>
                        </div>
                        <div className="company">MA-TRAINING CONSULTING</div>
                        <div className="date">
                          {" "}
                          {certificate?.recommandation_Galerie_1_date}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="formation-card">
                    <div className="decorative-circle" />
                    <h1 className="formation-title">FORMATION</h1>
                    <div className="course-container">
                      <h2 className="course-title">
                        {certificate?.Formation_title}
                      </h2>
                      <p className="course-description">
                        {certificate?.Formation_description}
                      </p>
                    </div>
                  </div>

                  <div className="recommendation-card my-3">
                    <div className="background-decoration" />
                    <div className="content">
                      <div className="quote-mark">"</div>
                      <div className="recommendation-text">
                        {certificate?.recommandation_Galerie_2_description}
                      </div>

                      <div className="note">
                        <p className="note-text">
                          <strong>NB :</strong> La formation consultative et les
                          coachings ont été réalisés avec succès en ligne via
                          l'application Microsoft Teams. Veuillez consulter les
                          photos ci-jointes comme preuve de la participation
                          réussie de{" "}
                          <span className="highlight">
                            {" "}
                            {
                              certificate?.recommandation_Galerie_1_titre_partie_2
                            }
                          </span>{" "}
                          à ce session de formation consultative
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="letter-container">
                    <div className="decorative-element" />
                    <div className="row">
                      <div className="col-12 col-md-6 d-flex justify-content-center my-2 ">
                        <div className="frame-container">
                          <div className="image-wrapper">
                            <img
                              src={certificate?.recommandation_Galerie_1_image}
                              alt="HSE Formation"
                              className="main-image"
                            />
                            <div className="glow" />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 d-flex justify-content-center my-2 ">
                        <div className="frame-container">
                          <div className="image-wrapper">
                            <img
                              src={certificate?.recommandation_Galerie_2_image}
                              alt="HSE Formation"
                              className="main-image"
                            />
                            <div className="glow" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div></div>
                  <section className="contact-section">
                    <div className="signature">MA-TRAINING CONSULTING</div>
                    <p className="text-end mt-4 text-white">
                      {" "}
                      Animé par le consultant :{" "}
                      {certificate?.certificate_Consultant}{" "}
                    </p>
                  </section>
                </Box>
              )}
            </Box>
          </div>
        </div>
      </Box>
    </>
  );
};

export default E_Trainings_Certificate_Detai;
