import axios from "axios";
/**
 * Post data to a url
 * @param {string} url - url to post to
 * @param {object} data - data to post
 * @returns {Promise} - resolves to the response object, rejects to the error object
 */
export const post = async (url, data) => {
  try {
    const res = await axios.post(url, data);
    if (res.data) {
      return res;
    }
  } catch (error) {
    return console.error("Error:", error);
  }
};
/**
 * Patch data to a url
 * @param {string} url - url to patch to
 * @param {object} data - data to patch
 * @returns {Promise} - resolves to the response object, rejects to the error object
 */
export const update = async (url, data) => {
  try {
    const res = await axios.patch(url, data);
    if (res.data) {
      return res;
    }
  } catch (error) {
    return console.error("Error:", error);
  }
};
/**
 * Get data from a url
 * @param {string} url - url to get data from
 * @returns {Promise} - resolves to the response object, rejects to the error object
 */
export const get_All = async (url) => {
  try {
    const res = await axios.get(url);

    if (res) {
      return res;
    }
  } catch (error) {
    return console.error("Error:", error);
  }
};

export const searchApi = async (url, id) => {
  try {
    const res = await axios.get(url, { params: { id } });

    if (res) {
      return res;
    }
  } catch (error) {
    return console.error("Error:", error);
  }
};
