import React from 'react';

import { useDeadlineStore } from './deadlineStore.js';
import DeadlineComponent from './DeadlineComponent.js';

/**
 * Renders a list of deadlines with visual indicators of their status.
 *
 * This component displays a list of deadlines retrieved from the store,
 * each showing the title, due date, service type, and assigned person.
 * The deadlines are visually styled to indicate their status: completed
 * with a green checkmark, overdue with a red warning symbol, and upcoming
 * with a yellow clock symbol.
 *
 * @returns {ReactElement} A React component displaying the list of deadlines.
 */
export function DeadlineList() {
  const deadlines = useDeadlineStore(state => state.deadlines);

  return (
    <div className="space-y-4 px-4 ">
      <div className="grid gap-4">
        {deadlines.map(deadline => (
          <DeadlineComponent key={deadline.id} deadline={deadline} />
        ))}
      </div>
    </div>
  );
}
