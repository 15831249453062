import React, { useState } from "react";
import { Box } from "@mui/material";
import { Layout, Calendar, Users } from "lucide-react";

import Navbar from "../components/dashboard_navbar/formateur/Navbar";
import Seller_Dashboard from "../components/E-Trainings/commercial_online/seller_dashboard/Seller_Dashboard";
import { redeemPointsForFreeClient } from "../components/E-Trainings/commercial_online/seller_dashboard/pointsCalculator";
import Catalogue_Des_Services from "../components/E-Trainings/commercial_online/catalogue_des_Services/Catalogue_Des_Services";
import Tableau_De_Bord_Financier from "../components/E-Trainings/commercial_online/tableau_te_bord_financier/Tableau_De_Bord_Financier";
import DiminuirPpartenir from "../components/E-Trainings/commercial_online/diminuir_partenir/DiminuirPpartenir";

import "../styles/dashboard/css/style.css";
import "../styles/bootstrap.min.css";

/**
 * E_Trainings_Formateur component
 *
 * This component renders the E-Trainings dashboard for a formateur
 *
 * @param {function} setAuth - function to set the authentication state
 * @returns {JSX.Element} - the E-Trainings formateur dashboard component
 */
const Commercial_Online = ({ setAuth }) => {
  const [navigate, setNavigate] = useState("Dashboard");
  const [seller, setSeller] = useState({
    id: "1",
    name: "John Doe",
    points: 450,
    referralCode: "JOHNDOE2024",
    clientCount: 0,
  });

  const menuItems = [
    { icon: Layout, label: "Dashboard", path: "Dashboard" },
    { icon: Calendar, label: "Formations", path: "Formations" },
    { icon: Users, label: "Participants", path: "participants" },
    { icon: Users, label: "Devenir Partenaire", path: "diminuir_partenir" },
  ];

  /**
   * Handles the user redeeming their points for a free client
   *
   * Updates the seller's points and client count by calling the
   * redeemPointsForFreeClient function and updating the state with the
   * new values.
   */
  const handleRedeemPoints = () => {
    setSeller((prev) => ({
      ...prev,
      points: redeemPointsForFreeClient(prev.points),
      clientCount: prev.clientCount + 1,
    }));
  };

  return (
    <div
      style={{
        marginTop: "80px",
      }}
    >
      <Navbar
        setNavigate={setNavigate}
        navigate={navigate}
        menuItems={menuItems}
        setAuth={setAuth}
      />

      <Box
        sx={{
          paddingLeft: {
            lg: "270px",
          },
        }}
        component="section"
        className="nftmax-adashboard bg-gray-100 nftmax-show"
      >
        <div className="row">
          <div className="col-xxl-11 col-12 nftmax-main__column">
            <div
              style={{
                marginTop: "50px",
              }}
              className="nftmax-body Formateur"
            >
              {navigate === "Dashboard" && (
                <Seller_Dashboard
                  seller={seller}
                  onRedeemPoints={handleRedeemPoints}
                />
              )}
              {navigate === "Formations" && <Catalogue_Des_Services />}
              {navigate === "participants" && <Tableau_De_Bord_Financier />}
              {navigate === "diminuir_partenir" && <DiminuirPpartenir />}
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default Commercial_Online;
