import React from "react";
import { AdvantageCard } from "./AdvantageCard.jsx";
import { advantages } from "./advantages.js";

export function AdvantagesList() {
  const styles = {
    container: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
      gap: "32px",
    },
  };

  return (
    <div style={styles.container}>
      {advantages.map((advantage, index) => (
        <AdvantageCard key={index} {...advantage} />
      ))}
    </div>
  );
}
