import React, { useState } from "react";

import { Header } from "./verivication/Header.jsx";
import { VerificationInput } from "./verivication/VerificationInput.jsx";
import { StatusMessage } from "./verivication/StatusMessage.jsx";
import { RecentRecipients } from "./verivication/RecentRecipients.jsx";
import { searchApi } from "../../functions/restApi";

import "../../styles/E-Trainings/validation/css/main.css";

/**
 * E_Trainings_Verivication component.
 *
 * This component renders a search form where a user can input an
 * attestation ID and verify it. If the ID is valid, it will fetch the
 * student data associated with the ID and display it in a table.
 *
 * @return {React.ReactElement}
 */
const E_Trainings_Verivication = () => {
  const [students, setStudents] = useState([]);
  const [verificationStatus, setVerificationStatus] = useState("idle");

  /**
   * Handles the verification of an attestation ID. Sets the verification
   * status to 'loading', and then attempts to fetch the student data
   * associated with the ID. If the fetch is successful and the ID is
   * valid, sets the verification status to 'success' and updates the
   * students state with the fetched data. Otherwise, sets the
   * verification status to 'error' and resets the students state.
   *
   * @param {string} id - The attestation ID to verify.
   */
  const handleVerify = async (id) => {
    setVerificationStatus("loading");

    try {
      const res = await searchApi(
        "https://ma-training-consulting-company-site-backend-one.vercel.app/api/search-validation-Student",
        id
      );

      if (res.data.students.length > 0) {
        setVerificationStatus("success");
        setStudents(res.data.students);
        return;
      }
      setStudents([]);
      setVerificationStatus("error");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div
        style={{
          marginTop: "80px",
        }}
        className="E_Trainings_Verivication min-h-screen bg-gray-50"
      >
        <div className="max-w-4xl mx-auto px-4 py-16">
          <div className="grid gap-8 lg:grid-cols-[1fr,360px]">
            <div className="space-y-8">
              <Header />
              <VerificationInput
                onVerify={handleVerify}
                isLoading={verificationStatus === "loading"}
              />
              {verificationStatus === "success" && (
                <StatusMessage
                  status="success"
                  message="Attestation vérifiée avec succès!"
                />
              )}
              {verificationStatus === "error" && (
                <StatusMessage
                  status="error"
                  message="ID d'attestation invalide. Veuillez réessayer."
                />
              )}
            </div>

            <div className="lg:row-span-2">
              <RecentRecipients recipients={students} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default E_Trainings_Verivication;
