export const advantages = [
  {
    title: "Accès à des experts",
    description:
      "Bénéficiez de l'expérience de professionnels reconnus dans leur domaine.",
  },
  {
    title: "Formation flexible",
    description: "Apprenez à votre rythme avec des sessions en ligne.",
  },
  {
    title: "Support continu",
    description:
      "Recevez des conseils et un accompagnement même après la formation.",
  },
  {
    title: "Ressources enrichissantes",
    description:
      "Accédez à une bibliothèque de documents essentiels pour approfondir vos connaissances.",
  },
  {
    title: "Communauté dynamique",
    description:
      "Échangez avec d'autres apprenants et partagez vos expériences.",
  },
  {
    title: "Accès aux formations et conseils",
    description:
      "Restez toujours à jour avec les dernières tendances et nouveautés dans votre domaine.",
  },
];
