import React from "react";
import { Box } from "@mui/material";
import { Users, Calendar, DollarSign, Star } from "lucide-react";

/**
 * A stat card component. It displays a label, a value, an icon, and an optional trend.
 *
 * @param {{ icon: React.ComponentType, label: string, value: number|string, trend?: number }} props
 * @returns {JSX.Element}
 */
function StatCard({ icon: Icon, label, value, trend }) {
  return (
    <div className="bg-white rounded-xl p-6 shadow-sm">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-gray-500 text-sm">{label}</p>
          <p className="text-2xl font-bold mt-1">{value}</p>
        </div>
        <div className="bg-blue-100 p-3 rounded-lg">
          <Icon className="w-6 h-6 text-blue-600" />
        </div>
      </div>
      {trend !== undefined && (
        <div className="mt-4 flex items-center">
          <span className={trend > 0 ? "text-green-500" : "text-red-500"}>
            {trend > 0 ? "+" : ""}
            {trend}%
          </span>
          <span className="text-gray-500 text-sm ml-2"> Pour ce mois </span>
        </div>
      )}
    </div>
  );
}

/**
 * A component that displays a few statistics about the training company.
 *
 * These statistics are the number of active formations, the number of participants, the revenue of the month, and the average rating.
 * Each statistic is displayed with an icon, a label, a value, and a trend (optional).
 * The trend is displayed as a percentage and is compared to the same value last month.
 * If the trend is positive, the text is green; if the trend is negative, the text is red.
 * @returns {JSX.Element} The rendered component.
 */
export function Stats() {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: {
          md: "repeat(4, minmax(0, 1fr))",
          sm: "repeat(2, minmax(0, 1fr))",
          xs: "repeat(1, minmax(0, 1fr))",
        },
      }}
      className=" gap-2"
    >
      <StatCard
        icon={Calendar}
        label="Formations actives"
        value={12}
        trend={8}
      />
      <StatCard icon={Users} label="Participants" value={156} trend={12} />
      <StatCard
        icon={DollarSign}
        label="Revenus du mois"
        value="€8,560"
        trend={-3}
      />
      <StatCard icon={Star} label="Note moyenne" value="4.8" trend={5} />
    </Box>
  );
}
