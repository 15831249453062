import React from "react";
import { SectionTitle } from "./SectionTitle.jsx";
import { AdvantagesList } from "./AdvantagesList.jsx";
import { ImageSection } from "./ImageSection.jsx";
import { Box } from "@mui/material";

const AboutSection = () => {
  return (
    <section className="py-16 px-4 bg-white">
      <div className="max-w-6xl mx-auto">
        <div className="mb-12">
          <SectionTitle>À PROPOS</SectionTitle>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                md: "repeat(2, minmax(0, 1fr))",
                xs: "repeat(1, minmax(0, 1fr))",
              },
              gap: "12px",
              alignItems: "center",
            }}
          >
            <p className="text-gray-600 leading-relaxed">
              Nous sommes spécialisés dans les formations et le coaching
              professionnels, accompagnés de conseils dispensés par des experts
              dans divers domaines. Nos programmes se déroulent en ligne,
              alliant théorie et pratique via Microsoft Teams, ce qui favorise
              une interaction directe avec les formateurs. Notre engagement est
              de vous fournir les connaissances et compétences nécessaires pour
              réussir, tout en garantissant une expérience d'apprentissage
              interactive et efficace.
            </p>
            <ImageSection />
          </Box>
        </div>

        <div>
          <SectionTitle>NOS AVANTAGES</SectionTitle>
          <AdvantagesList />
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
