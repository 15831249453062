import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";

import { get_All } from "../../functions/restApi";
import { formation_payant } from "../../db";

import "../../styles/E-Trainings/participant/css/style.css";

/**
 * Formation_Participant_Course_Details is a component that renders the details of
 * a course, including the course curriculum, instructor, and reviews.
 *
 * The component fetches the course data from the API and updates the state with
 * the fetched course data. If the request fails, it logs an error to the console
 * and calls the `getDataStatic` function to retrieve the course data from the
 * local database.
 *
 * The component renders a tabbed interface with four tabs: curriculum, overview,
 * instructor, and reviews. The curriculum tab displays the course curriculum in
 * a list of lessons. The overview tab displays an iframe containing a chatbot
 * assistant. The instructor tab displays the instructor's profile with their
 * image, name, experience, and position. The reviews tab displays a form for
 * submitting a review, including a text area for comments and a file input for
 * uploading a video.
 *
 * @component
 * @returns {React.ReactElement} The rendered component.
 */
const Formation_Participant_Course_Details = () => {
  const [active, setActive] = useState("curriculum");
  const [course, setCourse] = useState();
  const { id } = useParams();

  /**
   * Fetches a single course by its ID from the API and updates the course state.
   *
   * This function sends a GET request to the specified API endpoint to retrieve
   * the details of a single course based on the provided course ID from the URL
   * parameters. Upon a successful response, the course state is updated with the
   * fetched course data. If the request fails, logs an error to the console and
   * calls the `getDataStatic` function to retrieve the course data from the local
   * database.
   *
   * @async
   * @function
   * @returns {Promise<void>} Resolves when the data is fetched and the state is updated.
   * Logs an error to the console if the request fails.
   */
  const get_one_cours = async () => {
    try {
      const res = await get_All(
        `https://ma-training-consulting-company-site-backend-one.vercel.app/api/get-one-cours/${id}`
      );

      if (res.data) {
        setCourse(res.data.cours);
      }
    } catch (error) {
      getDataStatic();
      console.log(error);
    }
  };

  useEffect(() => {
    get_one_cours();
  }, [id]);

  /**
   * Retrieves a course from the local database by its ID and updates the course state.
   *
   * This function is called when the API request to fetch a single course by its ID
   * fails. It filters the formation_payant array by the course ID and updates the
   * course state with the first item in the filtered array.
   *
   * @function
   * @returns {void}
   */
  const getDataStatic = () => {
    const numericId = parseInt(id, 10);
    const filteredCourse = formation_payant.filter(
      (item) => item.id === numericId
    );
    setCourse(filteredCourse[0]);
  };

  return (
    <>
      <main
        style={{
          marginTop: "90px",
        }}
        className="Formation_Participant_Course_Details"
      >
        {/* main content */}
        <div className="py-[120px] xl:py-[80px] md:py-[60px]">
          <div className="container">
            <div className="flex gap-[30px] lg:gap-[20px] md:flex-col md:items-center">
              <div className="left max-w-full grow">
                <div>
                  {/* tabs container */}
                  <div>
                    {/* tab navs  */}
                    <div className="ed-course-details-tab-navs border border-[#E5E5E5] rounded-[10px] p-[20px] lg:p-[15px] flex gap-[16px] *:h-[56px] sm:*:h-[46px] *:rounded-[8px] *:flex-auto *:bg-edpurple/[06%] *:px-[20px] lg:*:px-[15px] *:font-semibold overflow-auto">
                      <button
                        onClick={() => setActive("curriculum")}
                        className={`tab-nav ${
                          active === "curriculum" ? "active" : ""
                        }`}
                      >
                        Cours
                      </button>
                      <button
                        onClick={() => setActive("overview")}
                        className={`tab-nav ${
                          active === "overview" ? "active" : ""
                        }`}
                      >
                        Assistant de conseil
                      </button>

                      <button
                        onClick={() => setActive("instructor")}
                        className={`tab-nav ${
                          active === "instructor" ? "active" : ""
                        }`}
                      >
                        Expert
                      </button>
                      <button
                        onClick={() => setActive("reviews")}
                        className={`tab-nav ${
                          active === "reviews" ? "active" : ""
                        }`}
                        data-tab="reviews"
                      >
                        Témoignages
                      </button>
                    </div>
                    {/* tabs */}
                    <div className="ed-course-details-tabs">
                      {/* tab 01 */}
                      {active === "overview" && (
                        <div
                          id="overview"
                          className="ed-tab duration-[400ms] active"
                        >
                          <div className="my-2 row gap-2 ">
                            <div className=" col-12">
                              <iframe
                                src="https://assistant-ia.vercel.app/"
                                width="100%"
                                style={{ border: "none", height: "700px" }}
                                title="Iframe Example"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {/* tab 02 */}
                      {active === "curriculum" && (
                        <div
                          id="curriculum"
                          className="ed-tab duration-[400ms]"
                        >
                          <div className="mb-[10px]">
                            <div className="space-y-[10px]">
                              <p className="text-edgray">
                                Remarque: Nous vous prions de télécharger le PDF
                                et l'enregistrement vidéo dans un délai de 3
                                jours après leur mise à disposition sur notre
                                site.
                              </p>
                            </div>
                          </div>

                          <div className="mt-[32px]">
                            <h5 className="font-semibold text-[20px] text-edblue mb-[16px]">
                              Subjects/Courses
                            </h5>
                            <div className="space-y-[12px]">
                              {course?.Sessions?.map((session, index) => (
                                <div key={index} className="lesson-card">
                                  <div className="lesson-info">
                                    <div className="lesson-number">
                                      {" "}
                                      {index + 1}{" "}
                                    </div>
                                    <h3>Introduction</h3>
                                  </div>
                                  <div className="lesson-actions">
                                    <button className="action-btn qcm-btn">
                                      <i className="fas fa-question-circle"></i>{" "}
                                      QCM
                                    </button>
                                    <a
                                      href={session.videoLink}
                                      target="_blanck"
                                      className="action-btn video-btn"
                                    >
                                      <i className="fas fa-play"></i> Vidéo
                                    </a>
                                    <a
                                      href={session.pdfLink}
                                      target="_blanck"
                                      className="action-btn pdf-btn"
                                    >
                                      <i className="fas fa-file-pdf"></i> PDF
                                    </a>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>

                          {/* curriculum #2 */}
                          <div className="mt-[32px]">
                            <div className="space-y-[12px]"></div>
                          </div>
                        </div>
                      )}
                      {/* tab 03 */}
                      {active === "instructor" && (
                        <div
                          id="instructor"
                          className="ed-tab duration-[400ms]"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: {
                                md: "row",
                                xs: "column",
                              },
                            }}
                            className="flex sm:flex-col items-center md:items-start gap-x-[30px] gap-y-[15px] mt-[40px]"
                          >
                            <div
                              style={{
                                flexShrink: 0,
                              }}
                              className="img shrink-0"
                            >
                              <img
                                src="https://edutics.temptics.com/assets/img/teacher-2.jpg"
                                alt="team member image"
                                className="w-[270px] xxs:max-w-full aspect-[74/75] rounded-[12px]"
                              />
                            </div>
                            {/* txt */}
                            <div className="txt">
                              <h3 className="text-[22px] xxs:text-[20px] font-semibold">
                                Savannah Nguyen
                              </h3>
                              <ul className="infos border-y border-[#E5E5E5] mt-[21px] lg:mt-[18px] mb-[20px] py-[18px] lg:py-[14px] xs:py-[11px] flex flex-wrap gap-x-[40px] xl:gap-x-[20px] gap-y-[10px] text-[16px] text-etBlack">
                                <li className="text-[16px]">
                                  <span className="font-medium text-edpurple">
                                    Experience:
                                  </span>{" "}
                                  10 Years
                                </li>
                                <li className="text-[16px]">
                                  <span className="font-medium text-edpurple">
                                    Consultant:
                                  </span>{" "}
                                  Maths Teacher
                                </li>
                              </ul>
                            </div>
                          </Box>
                        </div>
                      )}
                      {/* tab 04 */}
                      {active === "reviews" && (
                        <div id="reviews" className="ed-tab duration-[400ms]">
                          <form
                            action="/submit"
                            method="post"
                            encType="multipart/form-data"
                          >
                            {" "}
                            <label htmlFor="comments">Comments:</label>{" "}
                            <textarea
                              id="comments"
                              name="comments"
                              rows={4}
                              cols={50}
                              placeholder="Entrez vos commentaires ici..."
                              defaultValue={""}
                              style={{ padding: "20px" }}
                            />
                            <br />
                            <br /> <label htmlFor="video">
                              Upload Video:
                            </label>{" "}
                            <input
                              type="file"
                              id="video"
                              name="video"
                              accept="video/*"
                              placeholder="Insérez votre vidéo de témoignage"
                              style={{ border: "none" }}
                            />
                            <br />
                            <br />{" "}
                            <button
                              style={{
                                backgroundColor: "#5b3aee",
                                color: "#fff",
                                padding: "10px 20px",
                              }}
                              type="submit"
                              className="tab-nav active"
                            >
                              envoyer
                            </button>{" "}
                          </form>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Formation_Participant_Course_Details;
