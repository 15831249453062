import React from "react";
import { Award } from "lucide-react";
import { Link } from "react-router-dom";

/**
 * A component that displays a list of recent recipients of a certificate.
 *
 * @param {{ recipients: Recipient[] }} props
 * @prop {Recipient[]} recipients A list of recent recipients of a certificate.
 * @returns {JSX.Element} A component that displays a list of recent recipients.
 */
export function RecentRecipients({ recipients }) {
  return (
    <>
      <div className="bg-white rounded-xl shadow-sm border border-gray-100">
        <div className="p-4 border-b border-gray-100">
          <div className="flex items-center gap-2">
            <Award className="w-5 h-5 text-blue-600" />
            <h2 className="font-semibold text-gray-900">
              Derniers Attestation
            </h2>
          </div>
        </div>
        <div className="divide-y divide-gray-100 my-3 ">
          {recipients?.length > 0 &&
            recipients.map((item) => (
              <div key={item._id} className="profile-card mx-auto">
                <div className="header">
                  <img
                    src={item.students_image}
                    alt="Photo de profil"
                    className="profile-image"
                  />
                  <div className="participant-label">Participant</div>
                  <h1 className="profile-name"> {item.students_full_name} </h1>
                  <div className="domain-title">
                    {" "}
                    Domaine {item.students_specialization}
                  </div>
                </div>
                <div className="content">
                  <p className="section-title">
                    Thèmes suivies et conseils personnalisés dans le domaine
                    QHSE :
                  </p>

                  {item?.students_certificates?.length > 0 &&
                    item.students_certificates?.map((cert, i) => (
                      <div key={i} className="formation-item">
                        <div className="formation-name">
                          {cert.Formation_title}
                        </div>
                        <Link
                          to={`/E_Trainings_Certificate_Detai/${item._id}/${i}`}
                          className="arrow-icon"
                        >
                          <svg
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M9 5l7 7-7 7"
                            />
                          </svg>
                        </Link>
                      </div>
                    ))}

                  <div className="evaluation-section">
                    <div className="evaluation-content">
                      <h3 className="evaluation-title">Évaluation générale</h3>
                      <Link
                        to={`/Verivication-Detail/${item._id}`}
                        className="detail-button"
                      >
                        Voir les détails
                        <svg
                          width={16}
                          height={16}
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                        </svg>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}
