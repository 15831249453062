import React from "react";

import { format } from "date-fns";
import { Calendar, Users, CheckCircle, XCircle } from "lucide-react";
import { useTrainingStore } from "./useTrainingStore";

/**
 * A React component that renders a list of training sessions. Each session
 * displays its title, trainer, and date range. The list allows for status
 * updates of each training, such as marking it as completed or cancelled.
 *
 * The component uses the `useTrainingStore` to access the list of trainings
 * and update their statuses. It also includes the logic to determine the
 * color coding of each training's status.
 *
 * The status color is determined by the `getStatusColor` function, which
 * returns a CSS class based on the training's status. The component uses
 * `useEffect` to log the status color whenever it changes.
 *
 * @returns {React.ReactElement} A list of training sessions with options to
 * update their status.
 */
export function TrainingList() {
  const trainings = useTrainingStore((state) => state.trainings);
  const updateStatus = useTrainingStore((state) => state.updateTrainingStatus);

  /**
   * Returns a CSS class name representing the color associated with the given status.
   *
   * @param {string} status - The status of the training session, e.g., "completed", "cancelled".
   * @returns {string} A string representing the CSS class for the color associated with the status.
   *                   "completed" returns "text-green-600", "cancelled" returns "text-red-600",
   *                   and any other status returns "text-blue-600".
   */
  const getStatusColor = (status) => {
    switch (status) {
      case "completed":
        return "text-green-600";
      case "cancelled":
        return "text-red-600";
      default:
        return "text-blue-600";
    }
  };

  return (
    <div className="space-y-4">
      {trainings.map((training) => (
        <div
          key={training.id}
          className={`bg-white rounded-lg p-4 shadow-sm border-l-4 my-3 ${getStatusColor(
            training.status
          )}`}
        >
          <div className="flex justify-between items-start">
            <div>
              <h3
                className={`font-semibold text-lg  ${getStatusColor(
                  training.status
                )} `}
              >
                {training?.title}
              </h3>
              <div className="mt-2 space-y-2 text-gray-600">
                <div className="flex items-center gap-2">
                  <Calendar className="w-4 h-4" />
                  <span>
                    {format(new Date(training?.startDate), "PPP")} -
                    {format(new Date(training?.endDate), "PPP")}
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <Users className="w-4 h-4" />
                  <span>{training?.trainer}</span>
                </div>
              </div>
            </div>

            <div className="flex gap-2">
              <button
                onClick={() => updateStatus(training.id, "completed")}
                className="p-2 hover:bg-green-100 rounded-full transition-colors"
                title="Marquer comme terminée"
              >
                <CheckCircle className="w-5 h-5 text-green-600" />
              </button>
              <button
                onClick={() => updateStatus(training.id, "cancelled")}
                className="p-2 hover:bg-red-100 rounded-full transition-colors"
                title="Annuler la formation"
              >
                <XCircle className="w-5 h-5 text-red-600" />
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
