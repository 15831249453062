import { createSlice } from "@reduxjs/toolkit";

const cours_payant_Slice = createSlice({
  name: "cours_payant",
  initialState: {
    price: {},
  },
  reducers: {
    addPrice: (state, action) => {
      state.price = action.payload;
    },
  },
});

export const { addPrice } = cours_payant_Slice.actions;
export default cours_payant_Slice.reducer;
