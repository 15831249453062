import React from "react";
import { Calendar, Clock, Eye } from "lucide-react";

export function EventCard({ title, date, time, month, day, year }) {
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <div className="flex items-start p-4">
        <div className="flex-shrink-0 bg-indigo-600 text-white rounded-lg p-3 text-center w-20">
          <span className="block text-sm font-semibold">{year}</span>
          <span className="block text-2xl font-bold">{day}</span>
          <span className="block text-sm uppercase">{month}</span>
        </div>
        <div className="ml-4 flex-1">
          <h3 className="text-lg font-semibold text-gray-900 mb-2">{title}</h3>
          <div className="flex items-center text-sm text-gray-600 mb-2">
            <Calendar className="w-4 h-4 mr-1" />
            <span>{date}</span>
          </div>
          <div className="flex items-center text-sm text-gray-600 mb-4">
            <Clock className="w-4 h-4 mr-1" />
            <span>{time}</span>
          </div>
          <button className="flex items-center text-sky-600 hover:text-sky-700">
            <Eye className="w-4 h-4 mr-1" />
            Voir les détails
          </button>
        </div>
      </div>
    </div>
  );
}
