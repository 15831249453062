import React, { useState } from "react";
import { User, Mail, Phone } from "lucide-react";
import { useNavigate } from "react-router-dom";

const RegistrationForm = () => {
  const navigate = useNavigate();
  const [login, setLogin] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    whatsapp: "",
  });

  const handleLogin = (e) => {
    e.preventDefault();
    console.log("Login:", formData.email);
    navigate("/formation-dinitiation-ourse");
  };

  const handleRegister = (e) => {
    e.preventDefault();
    console.log("Register:", formData);
  };

  return (
    <div className="bg-white p-8 rounded-2xl shadow-lg">
      <h2 className="text-3xl font-bold text-gray-800 mb-4">
        {" "}
        {login ? "Se Connecter" : "S'inscrire"}{" "}
      </h2>
      <p className="text-blue-600 mb-6">
        {!login ? "Vous avez déjà un compte?" : "Vous n'avez pas de compte ?"}
        <a
          style={{
            cursor: "pointer",
          }}
          onClick={() => setLogin(!login)}
          className="text-blue-500 hover:text-blue-700 ml-1"
        >
          {!login ? "Se Connecter" : "S'inscrire"}
        </a>
      </p>

      <form
        onSubmit={login ? handleLogin : handleRegister}
        className="space-y-6"
      >
        {!login && (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              nom et prénom
            </label>
            <div className="relative">
              <span className="absolute left-3 top-3 text-gray-400">
                <User size={20} />
              </span>
              <input
                type="text"
                value={formData.fullName}
                onChange={(e) =>
                  setFormData({ ...formData, fullName: e.target.value })
                }
                placeholder="Entrez votre nom complet"
                className="w-full pl-10 pr-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-300 focus:border-blue-300"
                required
              />
            </div>
          </div>
        )}

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Email address
          </label>
          <div className="relative">
            <span className="absolute left-3 top-3 text-gray-400">
              <Mail size={20} />
            </span>
            <input
              type="email"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              placeholder="Entrez votre adresse e-mail"
              className="w-full pl-10 pr-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-300 focus:border-blue-300"
              required
            />
          </div>
        </div>

        {!login && (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              WhatsApp
            </label>
            <div className="relative">
              <span className="absolute left-3 top-3 text-gray-400">
                <Phone size={20} />
              </span>
              <input
                type="tel"
                value={formData.whatsapp}
                onChange={(e) =>
                  setFormData({ ...formData, whatsapp: e.target.value })
                }
                placeholder="Entrez votre numéro WhatsApp"
                className="w-full pl-10 pr-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-300 focus:border-blue-300"
                required
              />
            </div>
          </div>
        )}

        <button
          type="submit"
          className="w-full py-3 px-6 text-white font-medium rounded-lg bg-gradient-to-r from-purple-500 to-blue-500 hover:from-purple-600 hover:to-blue-600 transition-all duration-200"
        >
          {!login ? "S'inscrire" : "Se Connecter"}
        </button>
      </form>
    </div>
  );
};

export default RegistrationForm;
