import React from "react";
import { motion } from "framer-motion";

export default function MetricProgress({ value, isLowScore }) {
  const getProgressColor = (score, isLow = false) => {
    if (isLow) return "bg-red-500 shadow-red-200";
    if (score < 30) return "bg-red-500 shadow-red-200";
    if (score < 50) return "bg-orange-500 shadow-orange-200";
    if (score < 70) return "bg-yellow-500 shadow-yellow-200";
    if (score < 90) return "bg-green-500 shadow-green-200";
    return "bg-emerald-500 shadow-emerald-200";
  };

  return (
    <div className="relative h-2.5 bg-gray-100 rounded-full overflow-hidden">
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: `${value}%` }}
        transition={{ duration: 0.8, ease: "easeOut" }}
        className={`h-full rounded-full shadow-sm ${getProgressColor(
          value,
          isLowScore
        )}`}
      />
    </div>
  );
}
