import React from 'react';
import { Edit2, Trash2 } from 'lucide-react';

import StatusBadge from './StatusBadge';
import StatusActions from './StatusActions';

/**
 * PaymentTable component.
 *
 * This component renders a table displaying a list of payments with details such as
 * client, date, amount, and status. It also provides action buttons for editing and
 * deleting payments, as well as changing their status.
 *
 * @param {object[]} payments - An array of payment objects. Each object should have the following properties:
 *   - id: A unique identifier for the payment.
 *   - client: The name of the client associated with the payment.
 *   - date: The date of the payment.
 *   - amount: The amount of the payment.
 *   - status: The current status of the payment (e.g., 'completed', 'pending', 'cancelled').
 * @param {number} partnerPercentage - The percentage of the total amount that is the partner's commission.
 * @param {function} onEditPayment - Callback function to handle editing a payment.
 * @param {function} onDeletePayment - Callback function to handle deleting a payment.
 * @param {function} onStatusChange - Callback function to handle status changes for a payment.
 *
 * @returns {JSX.Element} The rendered PaymentTable component.
 */
function PaymentTable({
  payments,
  partnerPercentage,
  onEditPayment,
  onDeletePayment,
  onStatusChange,
}) {
  const totalAmount = payments.reduce(
    (sum, payment) =>
      payment.status !== 'cancelled' ? sum + payment.amount : sum,
    0
  );
  const partnerAmount = (totalAmount * partnerPercentage) / 100;
  const remainingAmount = totalAmount - partnerAmount;

  return (
    <div className="bg-white rounded-lg shadow-sm overflow-hidden">
      <div className="overflow-x-auto">
        <table className="max-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Nom compléte
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Théme de formation
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date limite de paiement
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Montant
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Statut
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {payments.map(payment => (
              <tr key={payment.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {payment.client}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  Théme
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {payment.date}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {payment.amount.toLocaleString('fr-FR', {
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <StatusBadge status={payment.status} />
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium flex items-center space-x-4">
                  <StatusActions
                    payment={payment}
                    onStatusChange={onStatusChange}
                  />
                  <div className="flex space-x-2 ml-4">
                    <button
                      onClick={() => onEditPayment(payment)}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      <Edit2 className="w-4 h-4" />
                    </button>
                    <button
                      onClick={() => onDeletePayment(payment.id)}
                      className="text-red-600 hover:text-red-900"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot className="bg-gray-50">
            <tr>
              <td
                colSpan={2}
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
              >
                Total
              </td>
              <td
                colSpan={3}
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
              >
                {totalAmount.toLocaleString('fr-FR', {
                  style: 'currency',
                  currency: 'EUR',
                })}
              </td>
            </tr>
            <tr>
              <td
                colSpan={2}
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
              >
                Commission Partenaire (10%)
              </td>
              <td
                colSpan={3}
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-red-600"
              >
                -
                {partnerAmount.toLocaleString('fr-FR', {
                  style: 'currency',
                  currency: 'EUR',
                })}
              </td>
            </tr>
            <tr>
              <td
                colSpan={2}
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
              >
                Montant à transférer ( le 26 / 27/ 28 de chaque moids )
              </td>
              <td
                colSpan={3}
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-green-600"
              >
                {remainingAmount.toLocaleString('fr-FR', {
                  style: 'currency',
                  currency: 'EUR',
                })}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}

export default PaymentTable;
