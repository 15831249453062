import React from "react";
import { CheckCircle2, XCircle } from "lucide-react";

export function StatusMessage({ status, message }) {
  const isSuccess = status === "success";
  const Icon = isSuccess ? CheckCircle2 : XCircle;

  return (
    <div
      className={`flex items-center gap-2 p-4 rounded-lg ${
        isSuccess ? "bg-green-50 text-green-700" : "bg-red-50 text-red-700"
      }`}
    >
      <Icon className="w-5 h-5 flex-shrink-0" />
      <span>{message}</span>
    </div>
  );
}
