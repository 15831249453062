import React, { useEffect, useRef } from "react";

import "../../styles/header_css/templatemo-grad-school.css";
import { Box } from "@mui/material";

/**
 * Renders the header section for E-Trainings with a background video.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.class_1 - The object containing video and text details.
 * @param {string} props.class_1.video - The URL of the video to be displayed.
 * @param {string} props.class_1.Sous_Titre - The subtitle to be displayed in the header.
 * @param {string} props.class_1.grand_titre_partie_1 - The first part of the main title.
 * @param {string} props.class_1.grand_titre_partie_2 - The second part of the main title.
 *
 * This component uses a video as the background of the header and overlays text on it.
 * The video plays automatically and is muted in a loop.
 */
export const E_Trainings_header = ({ class_1 }) => {
  const videoRef = useRef();

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [class_1]);

  return (
    <Box
      component="section"
      sx={{
        marginTop: "80px",
      }}
      className="section main-banner"
      id="top"
      data-section="section1"
    >
      <video ref={videoRef} autoPlay muted loop id="bg-video">
        <source src={class_1?.video} type="video/mp4" />
      </video>
      <div className="video-overlay header-text">
        <div className="caption">
          <h6> {class_1?.Sous_Titre} </h6>
          <h2>
            <em> {class_1?.grand_titre_partie_1} </em>{" "}
            {class_1?.grand_titre_partie_2}
          </h2>
        </div>
      </div>
    </Box>
  );
};
