import React from 'react';
import { Calendar, Filter } from 'lucide-react';

const months = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
];

const services = ['Service A', 'Service B', 'Service C'];

/**
 * DashboardHeader
 *
 * Un composant React qui affiche le titre de la page de tableau de bord des
 * paiements, ainsi que les liens vers les différentes pages de gestion des
 * paiements.
 *
 * @param {string} currentMonth le mois actuel.
 * @param {number} currentYear l'ann e actuelle.
 * @param {function} onMonthChange la fonction  appeler lorsque le mois change.
 * @param {function} onYearChange la fonction  appeler lorsque l'ann e change.
 * @param {string} service le service actuel.
 * @param {function} onServiceChange la fonction  appeler lorsque le service
 * change.
 *
 * @returns {JSX.Element} Le JSX  l ment  afficher.
 */
function DashboardHeader({
  currentMonth,
  currentYear,
  onMonthChange,
  onYearChange,
}) {
  return (
    <div className="bg-white p-6 rounded-lg shadow-sm mb-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-800">
          Tableau de Bord des Paiements
        </h1>
        <div className="flex gap-4">
          <div className="flex items-center gap-2">
            <Calendar
              style={{
                width: '50px',
                height: '50px',
              }}
              className="w-5 h-5 text-gray-500"
            />
            <select
              value={currentMonth}
              onChange={e => onMonthChange(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
            >
              {months.map(month => (
                <option key={month} value={month}>
                  {month}
                </option>
              ))}
            </select>
            <select
              value={currentYear}
              onChange={e => onYearChange(parseInt(e.target.value))}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
            >
              {Array.from({ length: 5 }, (_, i) => currentYear - 2 + i).map(
                year => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                )
              )}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardHeader;
