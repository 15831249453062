// import React, { useState } from 'react';

// import './style.css';

// /**
//  * CommercialOnlineRegister is a React component that renders a multi-step form for
//  * signing up for a commercial online service. The form has three steps, each of which
//  * is represented by a separate component. The component handles the step navigation
//  * and submission of the form.
//  *
//  * @return {React.ReactElement} The rendered form.
//  */
// const CommercialOnlineRegister = () => {
//   const [currentStep, setCurrentStep] = useState(1);

//   /**
//    * Handles the user clicking the "Next" button. If the current step is not the last
//    * step, it increments the current step by one. If the current step is the last
//    * step, it submits the form and displays an "Form submitted!" alert.
//    */
//   const handleNext = () => {
//     if (currentStep < 3) {
//       setCurrentStep(currentStep + 1);
//     } else {
//       // Submit form logic here
//       alert('Form submitted!');
//     }
//   };

//   /**
//    * Handles the user clicking the "Back" button. If the current step is greater
//    * than one, it decrements the current step by one.
//    */
//   const handleBack = () => {
//     if (currentStep > 1) {
//       setCurrentStep(currentStep - 1);
//     }
//   };

//   return (
//     <div
//       style={{
//         marginTop: '120px',
//       }}
//       className="CommercialOnlineRegister formbold-main-wrapper"
//     >
//       <div className="formbold-form-wrapper">
//         <div className="formbold-steps">
//           <ul>
//             <li
//               className={`formbold-step-menu1 ${currentStep === 1 ? 'active' : ''}`}
//             >
//               <span>1</span> Sign Up
//             </li>
//             <li
//               className={`formbold-step-menu2 ${currentStep === 2 ? 'active' : ''}`}
//             >
//               <span>2</span> Message
//             </li>
//             <li
//               className={`formbold-step-menu3 ${currentStep === 3 ? 'active' : ''}`}
//             >
//               <span>3</span> Confirm
//             </li>
//           </ul>
//         </div>

//         {currentStep === 1 && <StepOne />}
//         {currentStep === 2 && <StepTwo />}
//         {currentStep === 3 && <StepThree />}

//         <div className="formbold-form-btn-wrapper">
//           <button
//             className="formbold-back-btn"
//             onClick={handleBack}
//             style={{ display: currentStep === 1 ? 'none' : 'block' }}
//           >
//             Back
//           </button>
//           <button className="formbold-btn" onClick={handleNext}>
//             {currentStep === 3 ? 'Submit' : 'Next Step'}
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// const StepOne = () => (
//   <div className="formbold-form-step-1 active">
//     <div className="formbold-input-flex">
//       <div>
//         <label htmlFor="firstname" className="formbold-form-label">
//           {' '}
//           First name{' '}
//         </label>
//         <input
//           type="text"
//           name="firstname"
//           placeholder="Andrio"
//           id="firstname"
//           className="formbold-form-input"
//         />
//       </div>
//       <div>
//         <label htmlFor="lastname" className="formbold-form-label">
//           {' '}
//           Last name{' '}
//         </label>
//         <input
//           type="text"
//           name="lastname"
//           placeholder="Dolee"
//           id="lastname"
//           className="formbold-form-input"
//         />
//       </div>
//     </div>
//     <div className="formbold-input-flex">
//       <div>
//         <label htmlFor="dob" className="formbold-form-label">
//           {' '}
//           Date of Birth{' '}
//         </label>
//         <input
//           type="date"
//           name="dob"
//           id="dob"
//           className="formbold-form-input"
//         />
//       </div>
//       <div>
//         <label htmlFor="email" className="formbold-form-label">
//           {' '}
//           Email Address{' '}
//         </label>
//         <input
//           type="email"
//           name="email"
//           placeholder="example@mail.com"
//           id="email"
//           className="formbold-form-input"
//         />
//       </div>
//     </div>
//     <div>
//       <label htmlFor="address" className="formbold-form-label">
//         {' '}
//         Address{' '}
//       </label>
//       <input
//         type="text"
//         name="address"
//         id="address"
//         placeholder="Flat 4, 24 Castle Street, Perth, PH1 3JY"
//         className="formbold-form-input"
//       />
//     </div>
//   </div>
// );

// const StepTwo = () => (
//   <div className="formbold-form-step-2 active">
//     <div>
//       <label htmlFor="message" className="formbold-form-label">
//         {' '}
//         Message{' '}
//       </label>
//       <textarea
//         rows="6"
//         name="message"
//         id="message"
//         placeholder="Type your message"
//         className="formbold-form-input"
//       ></textarea>
//     </div>
//   </div>
// );

// const StepThree = () => (
//   <div className="formbold-form-step-3 active">
//     <div className="formbold-form-confirm">
//       <p>
//         Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
//         tempor incididunt.
//       </p>
//       <div>
//         <button className="formbold-confirm-btn active">Yes! I want it.</button>
//         <button className="formbold-confirm-btn">No! I don’t want it.</button>
//       </div>
//     </div>
//   </div>
// );

// export default CommercialOnlineRegister;
// -------------------------------------------------------------------------------------------------

import React, { useState } from "react";
import { UserPlus, Star, BookOpen, CheckCircle } from "lucide-react";

import "./style.css";

function CommercialOnlineRegister() {
  const [formData, setFormData] = useState({
    fullName: "",
    birthDate: "",
    nationality: "",
    experience: "",
    communicationSkills: "",
    canPresentTraining: false,
    acceptTerms: false,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    console.log("Form submitted:", formData);
  };

  return (
    <div
      style={{
        marginTop: "80px",
      }}
      className="CommercialOnlineRegister min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 py-12 px-4 sm:px-6 lg:px-8"
    >
      <div className="max-w-3xl mx-auto bg-white rounded-xl shadow-lg">
        <div className="bg-indigo-600 rounded-t-xl p-6 text-white text-center">
          <UserPlus className="w-12 h-12 mx-auto mb-4" />
          <h1 className="text-3xl font-bold">Formulaire d'Inscription</h1>
          <p className="mt-2">Plateforme E-commerciaux Gagnants</p>
        </div>

        <form onSubmit={handleSubmit} className="p-8 space-y-6">
          {/* Personal Information */}
          <div className="space-y-4">
            <h2 className="text-xl font-semibold flex items-center gap-2">
              <Star className="w-5 h-5 text-indigo-600" />
              Informations personnelles
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Nom complet
                </label>
                <input
                  type="text"
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 p-2"
                  value={formData.fullName}
                  onChange={(e) =>
                    setFormData({ ...formData, fullName: e.target.value })
                  }
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Date de naissance
                </label>
                <input
                  type="date"
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 p-2"
                  value={formData.birthDate}
                  onChange={(e) =>
                    setFormData({ ...formData, birthDate: e.target.value })
                  }
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Nationalité
                </label>
                <input
                  type="text"
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 p-2"
                  value={formData.nationality}
                  onChange={(e) =>
                    setFormData({ ...formData, nationality: e.target.value })
                  }
                />
              </div>
            </div>
          </div>

          {/* Professional Experience */}
          <div className="space-y-4">
            <h2 className="text-xl font-semibold flex items-center gap-2">
              <BookOpen className="w-5 h-5 text-indigo-600" />
              Expérience professionnelle
            </h2>
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Expérience dans la vente de services ou de formations
              </label>
              <div className="space-y-2">
                {[
                  "Débutant (0-1 an)",
                  "Intermédiaire (1-3 ans)",
                  "Avancé (3+ ans)",
                ].map((option) => (
                  <label key={option} className="flex items-center space-x-3">
                    <input
                      type="radio"
                      name="experience"
                      value={option}
                      onChange={(e) =>
                        setFormData({ ...formData, experience: e.target.value })
                      }
                      className="h-4 w-4 text-indigo-600 focus:ring-indigo-500"
                    />
                    <span className="text-gray-700">{option}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>

          {/* Skills */}
          <div className="space-y-4">
            <h2 className="text-xl font-semibold flex items-center gap-2">
              <CheckCircle className="w-5 h-5 text-indigo-600" />
              Compétences
            </h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Niveau en communication
                </label>
                <select
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 p-2 "
                  value={formData.communicationSkills}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      communicationSkills: e.target.value,
                    })
                  }
                >
                  <option value="">Sélectionnez un niveau</option>
                  <option value="debutant">Débutant</option>
                  <option value="intermediaire">Intermédiaire</option>
                  <option value="avance">Avancé</option>
                  <option value="expert">Expert</option>
                </select>
              </div>

              <div className="flex items-center space-x-3">
                <input
                  type="checkbox"
                  id="canPresentTraining"
                  checked={formData.canPresentTraining}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      canPresentTraining: e.target.checked,
                    })
                  }
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500"
                />
                <label
                  htmlFor="canPresentTraining"
                  className="text-sm text-gray-700"
                >
                  Capable d'animer une formation avec présentation
                </label>
              </div>
            </div>
          </div>

          {/* Terms and Conditions */}
          <div className="space-y-4 border-t pt-6">
            <div className="flex items-start space-x-3">
              <input
                type="checkbox"
                id="terms"
                required
                checked={formData.acceptTerms}
                onChange={(e) =>
                  setFormData({ ...formData, acceptTerms: e.target.checked })
                }
                className="h-4 w-4 mt-1 text-indigo-600 focus:ring-indigo-500"
              />
              <label htmlFor="terms" className="text-sm text-gray-700">
                <span className="font-medium">
                  J'accepte les termes et conditions
                </span>
                <ul className="mt-2 text-xs text-gray-500 list-disc list-inside">
                  <li>
                    Participer à la formation obligatoire avant d'utiliser la
                    plateforme
                  </li>
                  <li>
                    Respecter les règles et conditions d'utilisation de la
                    plateforme
                  </li>
                  <li>
                    Promouvoir les formations en respectant l'image et les
                    valeurs de l'entreprise
                  </li>
                </ul>
              </label>
            </div>
          </div>

          {/* Submit Button */}
          <div className="pt-6">
            <button
              type="submit"
              className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors"
            >
              S'inscrire maintenant
            </button>
            <p className="mt-2 text-xs text-center text-gray-500">
              Notre équipe examinera votre candidature et vous contactera sous 5
              jours ouvrés.
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CommercialOnlineRegister;
