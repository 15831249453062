import React, { useEffect, useState } from "react";
import { Play, FileText, CheckSquare, Lock } from "lucide-react";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";

import { get_All } from "../../functions/restApi";
import { formation_payant } from "../../db";

import "../../styles/E-Trainings/participant/css/style.css";

/**
 * Formation_Participant_Course is a component that renders a list of formations and courses.
 * It fetches the course data from an API and displays them in a grid layout.
 * Each course card includes an image, title, number of lessons, and instructor details.
 * Courses can be locked, which is visually represented by an icon overlay.
 * The component uses the useState hook to manage the state of courses and the locked status.
 * It also uses the useEffect hook to fetch course data on component mount.
 *
 * @component
 * @returns {React.ReactElement} The rendered component.
 */
function Formation_Participant_Course() {
  const [cours, setCours] = useState([]);
  const [locked, setlocked] = useState(true);

  /**
   * Fetches all courses from the backend API and updates the state with the fetched course data.
   *
   * This function sends a GET request to the specified API endpoint to retrieve
   * all course data. Upon a successful response, the cours state is updated with
   * the fetched course data. If the request fails, an error is logged to the console.
   *
   * @async
   * @function
   * @returns {Promise<void>} Resolves when the data is fetched and the state is updated.
   * Logs an error to the console if the request fails.
   */
  const get_all_cours = async () => {
    try {
      const res = await get_All(
        "https://ma-training-consulting-company-site-backend-one.vercel.app/api/get-all-cours"
      );

      if (res.data) {
        setCours(res.data.cours);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    get_all_cours();
  }, []);

  return (
    <Box
      sx={{
        marginTop: {
          md: "99px",
          xs: "75px",
        },
      }}
      className="Formation_Participant_Course"
    >
      <div className="min-h-screen bg-gradient-to-b from-sky-50 to-white">
        <header className="bg-white shadow-sm">
          <div className="max-w-7xl mx-auto px-4 py-6">
            <h1 className="text-3xl font-semibold text-gray-900">
              Mes Formations
            </h1>
          </div>
        </header>

        <main className="max-w-7xl mx-auto px-4 py-8">
          <Box
            sx={{
              gridTemplateColumns: {
                lg: "repeat(4, minmax(0, 1fr))",
                md: "repeat(2, minmax(0, 1fr))",
                xs: "repeat(1, minmax(0, 1fr))",
              },
            }}
            className="grid gap-6"
          >
            {formation_payant?.map((formation) => (
              <div
                key={formation.id}
                className="bg-white rounded-lg shadow-md overflow-hidden transition-transform hover:scale-105"
              >
                <div className="relative h-48">
                  <img
                    src={formation.imgUrl}
                    alt={formation.title}
                    className="w-full h-full object-cover"
                  />
                  {locked && (
                    <div className="absolute top-2 right-2">
                      <Lock className="w-5 h-5 text-white" />
                    </div>
                  )}
                </div>

                <div className="p-4">
                  <Link
                    to={
                      formation.path !== "#" &&
                      `/Formation-Participant-Course-Details/${formation.id}`
                    }
                  >
                    <h3 className="text-lg font-semibold text-gray-900 mb-2">
                      {formation.title}
                    </h3>
                  </Link>

                  <div className="flex items-center text-sm text-gray-600 mb-4">
                    <span className="mr-2">
                      {!formation.lessons && 12} Leçons
                    </span>
                  </div>

                  <div className="border-t pt-4">
                    <div className="flex items-center justify-between text-sm">
                      <div className="flex space-x-4">
                        <button className="flex items-center text-sky-600 hover:text-sky-700">
                          <Play className="w-4 h-4 mr-1" />
                          Vidéos
                        </button>
                        <button className="flex items-center text-sky-600 hover:text-sky-700">
                          <CheckSquare className="w-4 h-4 mr-1" />
                          QCM
                        </button>
                        <button className="flex items-center text-sky-600 hover:text-sky-700">
                          <FileText className="w-4 h-4 mr-1" />
                          Support
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 flex items-center">
                    <span className="text-xs uppercase tracking-wider font-medium text-gray-500">
                      Formateur
                    </span>
                    <span className="ml-2 text-sm font-medium text-sky-600">
                      {!formation.instructor && "pas disponible"}
                    </span>
                  </div>
                </div>
              </div>
            ))}
            {cours?.map((formation) => (
              <div
                key={formation._id}
                className="bg-white rounded-lg shadow-md overflow-hidden transition-transform hover:scale-105"
              >
                <div className="relative h-48">
                  <img
                    src={formation.image}
                    alt={formation.title}
                    className="w-full h-full object-cover"
                  />
                  {locked && (
                    <div className="absolute top-2 right-2">
                      <Lock className="w-5 h-5 text-white" />
                    </div>
                  )}
                </div>

                <div className="p-4">
                  <Link
                    to={`/Formation-Participant-Course-Details/${formation._id}`}
                  >
                    <h3 className="text-lg font-semibold text-gray-900 mb-2">
                      {formation.title}
                    </h3>
                  </Link>
                  <div className="flex items-center text-sm text-gray-600 mb-4">
                    <span className="mr-2">
                      {!formation.lessons && 12} Leçons
                    </span>
                  </div>

                  <div className="border-t pt-4">
                    <div className="flex items-center justify-between text-sm">
                      <div className="flex space-x-4">
                        <button className="flex items-center text-sky-600 hover:text-sky-700">
                          <Play className="w-4 h-4 mr-1" />
                          Vidéos
                        </button>
                        <button className="flex items-center text-sky-600 hover:text-sky-700">
                          <CheckSquare className="w-4 h-4 mr-1" />
                          QCM
                        </button>
                        <button className="flex items-center text-sky-600 hover:text-sky-700">
                          <FileText className="w-4 h-4 mr-1" />
                          Support
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 flex items-center">
                    <span className="text-xs uppercase tracking-wider font-medium text-gray-500">
                      Formateur
                    </span>
                    <span className="ml-2 text-sm font-medium text-sky-600">
                      {!formation.instructor && "pas disponible"}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </Box>
        </main>
      </div>
    </Box>
  );
}

export default Formation_Participant_Course;
