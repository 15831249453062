export const navigationItems = [
  {
    title: "E-Trainings & Consulting",
    subtitle: "Formation professionnelle",
    href: "/E-Trainings",
    sub_menu: [
      {
        title: "Espaces",
        position_y: 1,
        menu: [
          {
            title: "Formateur",
            // path: "/Formateur",
            path: "#",
          },
          {
            title: "Participant",
            // path: "/Participant",
            path: "#",
          },
          {
            title: "Commercial Online",
            // path: "/Commercial-Online",
            path: "#",
          },
        ],
      },
      {
        title: "Devenir Partenaire",
        position_y: 2,
        menu: [
          {
            title: "Formateur",
            // path: "/Dashboard-Register",
            path: "#",
          },
          {
            title: "Commercial Online",
            // path: "/Commercial-Online-Register",
            path: "#",
          },
        ],
      },
    ],
  },
  {
    title: "Digitalisation",
    subtitle: "Solutions numériques",
    // href: "/digital",
    href: "#",
  },
  {
    title: "Recrutement",
    subtitle: "Team Building & RH",
    // href: "/recruitment",
    href: "#",
  },
  {
    title: "Partenariat",
    subtitle: "Rejoignez-nous",
    // href: "/partners",
    href: "#",
  },
  {
    title: "Contacts",
    subtitle: "Notre équipe",
    // href: "/contacts",
    href: "#",
  },
];
