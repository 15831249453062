// import React from 'react';

// interface SectionTitleProps {
//   children: React.ReactNode;
// }

// export function SectionTitle({ children }: SectionTitleProps) {
//   return (
//     <h2 className="text-3xl font-bold mb-6 flex items-center">
//       <span className="w-2 h-8 bg-yellow-400 mr-4"></span>
//       {children}
//     </h2>
//   );
// }
// -------------------------------------------------------------
import React from "react";

export function SectionTitle({ children }) {
  const styles = {
    container: {
      display: "flex",
      alignItems: "center",
      fontSize: "1.875rem", // 30px
      fontWeight: "bold",
      marginBottom: "1.5rem", // 24px
    },
    marker: {
      width: "8px",
      height: "32px",
      backgroundColor: "#facc15", // Yellow-400
      marginRight: "16px", // 4rem
    },
  };

  return (
    <h2 style={styles.container}>
      <span style={styles.marker}></span>
      {children}
    </h2>
  );
}
