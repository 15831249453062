import React, { useState } from "react";

import { questions } from "./data/questions.js";
import Results from "./Results.jsx";
import QuestionForm from "./QuestionForm.jsx";

/**
 * RecruitmentForm is a React component that renders a multi-step form for recruitment.
 * It manages the form's state, including the current step and the answers to the questions.
 * The component provides functionality to navigate between questions, submit answers, and
 * display the results at the end of the form.
 *
 * State:
 * - formState: Tracks the current step and the answers given by the user.
 * - showResults: Determines whether to show the results or continue displaying the form.
 *
 * Functions:
 * - handleAnswer: Updates the answer for the current question in the form state.
 * - handleNext: Advances to the next question or shows results if on the last question.
 * - handlePrevious: Returns to the previous question.
 *
 * @returns {JSX.Element} The rendered form or results component.
 */
function RecruitmentForm() {
  const [formState, setFormState] = useState({
    currentStep: 0,
    answers: {},
  });
  const [showResults, setShowResults] = useState(false);

  const currentQuestion = questions[formState.currentStep];
  const isFirstQuestion = formState.currentStep === 0;
  const isLastQuestion = formState.currentStep === questions.length - 1;

  /**
   * Updates the answer for the current question in the form state.
   * @param {string} value The value of the answer.
   */
  const handleAnswer = (value) => {
    setFormState((prev) => ({
      ...prev,
      answers: {
        ...prev.answers,
        [currentQuestion.id]: value,
      },
    }));
  };

  /**
   * Advances to the next question or shows results if on the last question.
   * If the last question is reached, sets showResults to true to display the results.
   * Otherwise, increments the current step by one.
   */
  const handleNext = () => {
    if (isLastQuestion) {
      setShowResults(true);
      return;
    }

    setFormState((prev) => ({
      ...prev,
      currentStep: prev.currentStep + 1,
    }));
  };

  /**
   * Returns to the previous question.
   * Decrements the current step by one.
   */
  const handlePrevious = () => {
    setFormState((prev) => ({
      ...prev,
      currentStep: prev.currentStep - 1,
    }));
  };

  if (showResults) {
    return <Results requirements={formState.answers} />;
  }

  return (
    <div
      style={{
        marginTop: "80px",
      }}
      className="reactitment-form bg-gray-50"
    >
      <main className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 ">
        <div className="bg-white rounded-2xl shadow-lg p-8">
          <div className="mb-8">
            <div className="h-2 bg-gray-200 rounded-full">
              <div
                className="h-2 bg-blue-600 rounded-full transition-all duration-300"
                style={{
                  width: `${
                    ((formState.currentStep + 1) / questions.length) * 100
                  }%`,
                }}
              />
            </div>
            <p className="text-sm text-gray-600 mt-2">
              Question {formState.currentStep + 1} sur {questions.length}
            </p>
          </div>

          <QuestionForm
            question={currentQuestion}
            answer={formState.answers[currentQuestion.id] || ""}
            onAnswer={handleAnswer}
            onNext={handleNext}
            onPrevious={handlePrevious}
            isFirst={isFirstQuestion}
            isLast={isLastQuestion}
          />
        </div>
      </main>
    </div>
  );
}

export default RecruitmentForm;
