import React from "react";
import { Users, BookOpen, Award } from "lucide-react";
import FeatureCard from "./FeatureCard.jsx";

const Features = () => {
  const features = [
    {
      Icon: Users,
      title: "Communauté Active",
      description: "Rejoignez une communauté dynamique de professionnels",
    },
    {
      Icon: BookOpen,
      title: "Formation Continue",
      description: "Accès à des ressources pédagogiques exclusives",
    },
    {
      Icon: Award,
      title: "Attestation d'expertise",
      description:
        "Validez vos compétences avec une attestation professionnelle reconnue",
    },
  ];

  return (
    <div className="bg-white rounded-2xl shadow-xl p-8 space-y-8">
      <h3 className="text-2xl font-bold text-blue-900 mb-6">
        Avantages Participants
      </h3>
      <div className="space-y-6">
        {features.map((feature) => (
          <FeatureCard key={feature.title} {...feature} />
        ))}
      </div>
    </div>
  );
};

export default Features;
