import React, { useState } from "react";
import { Box } from "@mui/material";
import { Sparkles } from "lucide-react";

import { services } from "./services.js";
import { ServiceCard } from "./ServiceCard.js";

import "./style.css";

/**
 * CatalogueDesServices component displays a list of available services and selected services
 *
 * @component
 * @returns {React.ReactElement} The service catalog component
 *
 * State:
 * - services_selected: Array of selected services
 *
 * Features:
 * - Displays available services in a responsive grid
 * - Shows selected services in a separate section
 * - Allows users to select services (if not already selected)
 */
const CatalogueDesServices = () => {
  const [services_selected, setServices_selected] = useState([]);

  return (
    <div className="Catalogue_Des_Services min-h-screen bg-gray-100 ">
      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 py-6 sm:px-6 lg:px-8">
          <div className="flex items-center gap-2">
            <Sparkles className="w-8 h-8 text-indigo-600" />
            <h1 className="text-3xl font-bold text-gray-900">
              Catalogue des Services
            </h1>
          </div>
        </div>
      </header>

      <main className="py-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <Box
            sx={{
              gridTemplateColumns: {
                md: "repeat(3, minmax(0, 1fr))",
                sm: "repeat(2, minmax(0, 1fr))",
                xs: "repeat(1, minmax(0, 1fr))",
              },
            }}
            className="grid gap-6 py-8"
          >
            {services.map((service) => (
              <ServiceCard
                key={service.id}
                service={service}
                services_selected={services_selected}
                setServices_selected={setServices_selected}
              />
            ))}
          </Box>
          <Box
            sx={{
              gridTemplateColumns: {
                md: "repeat(3, minmax(0, 1fr))",
                sm: "repeat(2, minmax(0, 1fr))",
                xs: "repeat(1, minmax(0, 1fr))",
              },
            }}
            className="grid gap-6 py-8"
          >
            {services_selected?.map((service) => (
              <ServiceCard key={service.id} service={service} />
            ))}
          </Box>
        </div>
      </main>
    </div>
  );
};

export default CatalogueDesServices;
