export const THEME = {
  primary: "#25D366", // WhatsApp green
  secondary: "#128C7E",
  background: "#ECE5DD",
  surface: "#FFFFFF",
  text: "#075E54",
};

/**
 * Validates if a given phone number matches the WhatsApp format.
 * The number should start with an optional '+' followed by 1 to 15 digits.
 *
 * @param {string} number - The phone number to validate.
 * @returns {boolean} - Returns true if the number is valid, false otherwise.
 */
export const validateWhatsApp = (number) => {
  const whatsappRegex = /^\+?[1-9]\d{1,14}$/;
  return whatsappRegex.test(number);
};

export const STATUT = {
  EN_ATTENTE: "en_attente",
  CONFIRME: "confirme",
  ANNULE: "annule",
};
