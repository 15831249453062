export const freeCourses = [
  {
    id: 1,
    title: 'Introduction à la Sécurité au Travail',
    description: 'Découvrez les fondamentaux de la sécurité en entreprise',
    image: 'https://images.unsplash.com/photo-1531493731235-d5c8bc19a6e6?auto=format&fit=crop&q=80&w=1000',
    duration: '20'
  },
  {
    id: 2,
    title: 'Bases de la Gestion QHSE',
    description: 'Les principes essentiels de la gestion QHSE',
    image: 'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?auto=format&fit=crop&q=80&w=1000',
    duration: '15'
  },
  {
    id: 3,
    title: 'Initiation au Développement Web',
    description: 'Premiers pas dans le développement web moderne',
    image: 'https://images.unsplash.com/photo-1498050108023-c5249f4df085?auto=format&fit=crop&q=80&w=1000',
    duration: '25'
  }
];