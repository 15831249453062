import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Mail, GraduationCap } from "lucide-react";

import "./style.css";

/**
 * Dashboard_Login component
 *
 * This component renders a login section for the dashboard, which includes
 * a form for users to input their email addresses and a button to navigate
 * to the E_Trainings-Formateur page. The component is styled with Tailwind CSS
 * classes and includes a promotional section with key features.
 *
 * @returns {JSX.Element} The JSX element for the Dashboard_Login component.
 */
export const Dashboard_Login = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(email);
    navigate("/E_Trainings-Formateur");
  };

  return (
    <>
      <div
        style={{
          marginTop: "80px",
        }}
        className="Dashboard_Login min-h-screen bg-gradient-to-br from-blue-50 via-blue-100 to-blue-200 flex items-center justify-center p-4"
      >
        <div className="w-full max-w-md">
          <div className="bg-white rounded-2xl shadow-xl p-8 space-y-8">
            {/* Logo et Titre */}
            <div className="text-center space-y-2">
              <div className="flex justify-center">
                <GraduationCap className="h-12 w-12 text-blue-600" />
              </div>
              <h1 className="text-2xl font-bold text-gray-800">
                ESPACE FORMATEURS / EXPERTS
              </h1>
              <p className="text-gray-500">
                Connectez-vous à votre espace personnel
              </p>
            </div>

            {/* Formulaire */}
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="space-y-2">
                <label
                  htmlFor="email"
                  className="text-sm font-medium text-gray-700"
                >
                  Adresse email
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Mail className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="email"
                    required
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white text-gray-900"
                    placeholder="Entrer Un Email Pour Commencer"
                  />
                </div>
              </div>

              <button
                type="submit"
                className="w-full py-3 px-4 bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white font-medium rounded-lg transition duration-200 ease-in-out transform hover:scale-[1.02]"
              >
                Commencer
              </button>
            </form>

            {/* Aide */}
            <div className="text-center">
              <a className="text-sm text-blue-600 hover:text-blue-800">
                Besoin d'aide ?
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard_Login;
