import React from "react";
import { Box } from "@mui/material";
import CoursesAndEvents from "./formation_gratuit/courses_Events/CoursesAndEvents";

import "./formation_gratuit/courses_Events/style.css";

/**
 * Formation_Dinitiation_Course component
 *
 * Contains the Formation_Dinitiation_Course page component
 *
 * @returns {JSX.Element} The Formation_Dinitiation_Course page component
 */
export const Formation_Dinitiation_Course = () => {
  return (
    <Box
      sx={{
        marginTop: {
          md: "100px",
          xs: "70px",
        },
      }}
      className="Formation_Dinitiation_Course"
    >
      <CoursesAndEvents />
    </Box>
  );
};
