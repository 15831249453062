import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import html2pdf from "html2pdf.js";
import { Calendar, Building2, FileEdit } from "lucide-react";

import { get_All } from "../../functions/restApi";
// import LineChart from "../LineChart";

import "../../styles/E-Trainings/validation/css/Tableau_De_Bord_Des_Performances.css";
import EvaluationCard from "./evaluation globale/EvaluationCard";

/**
 * E_Trainings_Verivication_Detail is a component that displays the details of a
 * student, including their evaluation, recommendation letter, and testimony.
 *
 * @returns {JSX.Element} The component to be rendered.
 */
const E_Trainings_Verivication_Detail = () => {
  const [sections, setSections] = useState("Évaluation");
  const [active, setActive] = useState(false);
  const [student, setStudent] = useState({});
  const { id } = useParams();
  const [play, setPlay] = useState(false);

  const videoRef = useRef();

  const pdfRef = useRef();

  const downloadPDF = () => {
    const element = pdfRef.current;
    const options = {
      margin: 10,
      filename: "lettre-de-recommandation.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf().set(options).from(element).save();
  };

  const [formData, setFormData] = useState({
    // recommendedName: "M. Savane Moussa",
    company: "MA-TRAINING CONSULTING",
    date: "2024-11-20",
    content: `Nous sommes heureux de vous recommander chaleureusement M. Savane Moussa, qui a participé avec succès à deux formations consultatives et coachings en ligne organisés par MA-TRAINING-CONSULTING. Ces formations se sont déroulées respectivement du 05/08/2024 au 03/09/2024 et portaient sur les thèmes suivants :
  
  DEVENIR RESPONSABLE QHSE
  Selon les fondamentaux des normes ISO 9001:2015, ISO 45001:2018, ISO 14001:2015 et ISO 19011:2018
  
  SUPERVISEUR HSE
  Selon les processus de l'évaluation des risques en santé et sécurité au travail (SST), de l'évaluation des aspects environnementaux, de l'analyse des accidents de travail, des outils de pilotage et de reporting HSE, ainsi que de la veille réglementaire HSE.
  
  Durant ces formations consultatives, M. Savane Moussa a fait preuve d'un grand intérêt et d'une forte implication dans les divers modules proposés. Il a acquis des compétences approfondies dans les domaines de la qualité, de l'hygiène, de la sécurité, de l'environnement et de la gestion des risques, en se basant sur des normes internationales reconnues.`,
    signature: "Consultant Farouk Medhoub",
  });

  useEffect(() => {
    getStudent(id);
  }, [id]);

  /**
   * Fetches a specific student's data from the backend API by their
   * formation ID, and updates the state with the student's information.
   *
   * @param {string} id - The student's formation ID.
   * @returns {Promise<void>} A promise that resolves when the student's
   * data is fetched and the state is updated.
   */
  const getStudent = async (id) => {
    try {
      const res = await get_All(
        `https://ma-training-consulting-company-site-backend-one.vercel.app/api/get-one-validation-Student/${id}`
      );

      if (res) {
        setStudent(res.data.student);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Box
        sx={{
          marginTop: {
            lg: "87px",
            xs: "80px",
          },
        }}
        id="page"
        className="page Verivication Verivication-Detail"
      >
        {/* Header */}
        <header
          style={{
            zIndex: "5",
            transition: "all .5s",
          }}
          id="site_header"
          className={`header ${active ? "" : "mobile-menu-hide"}`}
        >
          <div className="profile-container">
            <div className="navSide">
              <img
                src={student?.students_image}
                alt={student?.students_full_name}
                className="profile-image"
              />
              <h1
                style={{
                  fontSize: "20",
                }}
                className="name"
              >
                {student?.students_full_name}
              </h1>
            </div>
            <div className="menu-container">
              <div
                onClick={() => {
                  setSections("Évaluation");
                  setActive(false);
                }}
                className={`menu-item ${
                  sections === "Évaluation" ? "active" : ""
                } `}
              >
                <div className="menu-icon">
                  <svg
                    width={24}
                    height={24}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                    />
                  </svg>
                </div>
                <span className="menu-text">Évaluation Générale</span>
              </div>
              <div
                onClick={() => {
                  setSections("Recommandation");
                  setActive(false);
                }}
                className={`menu-item  ${
                  sections === "Recommandation" ? "active" : ""
                } `}
              >
                <div className="menu-icon">
                  <svg
                    width={24}
                    height={24}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                    />
                  </svg>
                </div>
                <span className="menu-text">Lettre de Recommandation</span>
              </div>
              <div
                onClick={() => {
                  setSections("Témoignage");
                  setActive(false);
                }}
                className={`menu-item  ${
                  sections === "Témoignage" ? "active" : ""
                } `}
              >
                <div className="menu-icon">
                  <svg
                    width={24}
                    height={24}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
                    />
                  </svg>
                </div>
                <span className="menu-text">Témoignage</span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <a
                  href={student?.students_cv ? student?.students_cv : "#"}
                  target={student?.students_cv ? "_blank" : "_self"}
                  className="download-btn"
                >
                  Télécharger CV
                  <svg
                    className="download-icon"
                    width={20}
                    height={20}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </header>
        {/* /Header */}
        {/* Mobile Header */}
        <Box
          sx={{
            zIndex: 3,
            // marginTop: "10px",
            top: "80px",
            display: {
              lg: "none",
              xs: "flex",
            },
            justifyContent: "end",
            alignItems: "center",
            background: "transparent",
            border: "none",
          }}
          className="mobile-header mobile-visible px-4"
        >
          <a
            style={{
              background: "#fff",
              padding: "10px 13px",
              borderRadius: "51%",
              cursor: "pointer",
            }}
            onClick={() => setActive(!active)}
          >
            <i className="fa fa-bars" />
          </a>
        </Box>
        {/* /Mobile Header */}
        {/* Main Content */}
        <Box
          sx={{
            marginTop: {
              lg: "-33px",
              lg: "-6px",
            },
          }}
          id="main"
          className="site-main"
        >
          {/* Page changer wrapper */}
          <div>
            {/* Subpages */}
            <Box
              sx={{
                paddingLeft: {
                  lg: "416px",
                  xs: "10px",
                },
                paddingRight: "10px",
              }}
              className="subpages"
            >
              {/* Évaluation Subpage */}
              {sections === "Évaluation" && (
                <Box
                  component="section"
                  className="home pt-page pt-page-1 pl-page-2 section-with-bg section-paddings-0 
                pt-page-current Évaluation
               "
                  sx={{
                    height: "100vh",
                    paddingTop: { lg: "30px", xs: "90px" },
                    paddingLeft: "20px",
                  }}
                >
                  <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 py-12">
                    <EvaluationCard
                      evaluation_Generale_score={
                        student?.evaluation_Generale_score
                      }
                    />
                  </div>
                </Box>
              )}
              {/* End of Évaluation Subpage */}
              {/* Recommandation Subpage */}
              {sections === "Recommandation" && (
                <section
                  className="about_me pt-page pt-page-2 
                pt-page-current Recommandation
              "
                  style={{
                    paddingLeft: "20px",
                    padding: "13px 0",
                    backgroundColor: "#f8f9fa",
                    color: "#2c3e50",
                    lineHeight: "1.6",
                  }}
                >
                  {/* <div
                    style={{
                      maxWidth: "800px",
                      margin: "2rem auto",
                      padding: "2rem",
                      background: "white",
                      boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
                      borderRadius: "10px",
                    }}
                    classname="container"
                  >
                    <div
                      style={{
                        textAlign: "center",
                        marginBottom: "2rem",
                        paddingBottom: "1rem ",
                        borderBottom: "2px solid #3498db",
                      }}
                      classname="header"
                    >
                      <Box
                        component="h1"
                        sx={{
                          fontSize: {
                            md: "50px",
                            xs: "40px",
                          },
                          lineHeight: 1,
                        }}
                      >
                        Lettre de Recommandation
                      </Box>
                      <p>MA-TRAINING CONSULTING</p>
                      <p classname="date">
                        {" "}
                        {student?.lettre_de_Recommandation_date}{" "}
                      </p>
                    </div>
                    <div
                      style={{
                        padding: "1rem 0",
                      }}
                      classname="content"
                    >
                      <p>{student?.lettre_de_Recommandation_description_1}</p>
                      {student.students_Formations?.length > 0 &&
                        student.students_Formations.map((item, index) => (
                          <div
                            key={index}
                            style={{
                              background: "#f8f9fa",
                              padding: "1rem",
                              margin: "1rem 0",
                              borderLeft: "4px solid #3498db",
                              borderRadius: "0 5px 5px 0",
                            }}
                            classname="formation-block"
                          >
                            <h3>{item.Formation_title}</h3>
                            <p>{item.Formation_description}</p>
                          </div>
                        ))}

                      <p>{student?.lettre_de_Recommandation_description_2}</p>
                    </div>
                    <div
                      style={{
                        marginTop: "2rem",
                        paddingTop: "1rem",
                        borderTop: "1px solid #eee",
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                      }}
                      classname="footer"
                    >
                      <div
                        style={{
                          fontSize: "0.9rem",
                          color: "#34495e",
                        }}
                        classname="contact-info"
                      >
                        <p>MA-TRAINING CONSULTING</p>
                        <p>3012, Sfax-Tunisie</p>
                        <p>1.5 rue farhat hached</p>
                        <p>Tél: +216.44172284</p>
                        <p>Email: digitallearningconsulting89@gmail.com</p>
                        <p>Site web: ma-training-consulting.company.site</p>
                      </div>
                      <div classname="signature">
                        <p>Signature &amp; cachet société</p>
                        <p>Consultant {student?.students_Consultant}</p>
                      </div>
                    </div>
                  </div> */}
                  {/* ---------------------------------------------------------- */}
                  <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
                    <div className="max-w-4xl mx-auto">
                      <div
                        ref={pdfRef}
                        className="bg-white shadow-lg rounded-lg overflow-hidden"
                      >
                        <div
                          style={{
                            backgroundColor: "rgb(3 105 161)",
                          }}
                          className="bg-sky-700 px-6 py-4 grid-center "
                        >
                          <h1 className="text-3xl font-bold text-white text-center">
                            Lettre de Recommandation
                          </h1>
                        </div>

                        <div className="p-6 space-y-6">
                          <div className="flex flex-wrap gap-4 text-gray-600 border-b border-gray-200 pb-4">
                            <div className="flex items-center gap-2">
                              <Building2
                                style={{
                                  color: "rgb(2 132 199)",
                                }}
                                className="w-5 h-5 text-sky-600"
                              />
                              <span>{formData.company}</span>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="flex items-center gap-2"
                            >
                              <Calendar
                                style={{
                                  color: "rgb(2 132 199)",
                                }}
                                classNam
                                className="w-5 h-5 text-sky-600"
                              />
                              <span>{formData.date}</span>
                            </div>
                          </div>

                          <div className="prose max-w-none">
                            <div className="space-y-4 text-gray-700 leading-relaxed">
                              {formData.content
                                .split("\n\n")
                                .map((paragraph, index) => (
                                  <p key={index} className="mb-4">
                                    {paragraph}
                                  </p>
                                ))}
                            </div>
                          </div>

                          <div className="mt-8 pt-4 border-t border-gray-200">
                            <div className="text-right">
                              <p className="text-gray-700 font-semibold text-end ">
                                {formData.signature}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-6 text-center">
                        <button
                          onClick={downloadPDF}
                          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                        >
                          <FileEdit className="w-5 h-5 mr-2" />
                          Télécharger la lettre
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
              )}
              {/* End of Recommandation Subpage */}
              {/* Témoignage Subpage */}
              {sections === "Témoignage" && (
                <section
                  className="resume pt-page pt-page-3 
                pt-page-current Témoignage
              "
                  style={{
                    paddingLeft: "20px",
                  }}
                >
                  <div className="container">
                    <h1 className="title">Témoignages</h1>
                    <h2 className="subtitle">
                      {student?.students_specialization}
                    </h2>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="video-container"
                    >
                      <video
                        ref={videoRef}
                        width="100%"
                        height="100%"
                        controls={play}
                        autoplay
                        muted={false}
                      >
                        <source
                          src={student?.students_video}
                          type="video/mp4"
                        />
                      </video>
                      <div
                        onClick={() => {
                          setPlay(true);
                          videoRef.current.play();
                        }}
                        style={{
                          display: play && "none",
                        }}
                        className="play-button"
                      />
                    </div>
                    <div className="testimonial-text">
                      {student?.students_testimony}
                      <div className="author">
                        - {student?.students_full_name}
                      </div>
                    </div>
                  </div>
                </section>
              )}
              {/* End Témoignage Subpage */}
            </Box>
          </div>
          {/* /Page changer wrapper */}
        </Box>
        {/* /Main Content */}
      </Box>
    </>
  );
};

export default E_Trainings_Verivication_Detail;
