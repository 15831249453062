import React from "react";
import EvaluationCard from "./EvaluationCard";

export default function EvaluationGrid({ evaluation_certificat }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <EvaluationCard
        title="Participation et engagement"
        metrics={[
          {
            label: "Niveau d'intérêt et de Participation aux sessions",
            value: evaluation_certificat?.students_skil_Niveau,
          },
          {
            label: "Interaction avec le formateur et les autres Participants",
            value: evaluation_certificat?.students_skil_Interaction,
          },
          {
            label: "Poser des questions et des clarifications",
            value: evaluation_certificat?.students_skil_Poser,
          },
        ]}
      />
      <EvaluationCard
        title="Performance Pratique et Applicative"
        metrics={[
          {
            label: "Capacité à appliquer les concepts et théories",
            value: evaluation_certificat?.students_skil_Capacite,
          },
          {
            label: "Qualité du travail pratique ou des projets présentés",
            value: evaluation_certificat?.students_skil_Qualite,
          },
          {
            label: "Capacité à résoudre les problèmes et à innover",
            value: evaluation_certificat?.students_skil_Capacite_a_resoudre,
          },
          {
            label: "Collaboration et travail d'équipe",
            value: evaluation_certificat?.students_skil_Collaboration,
          },
        ]}
      />
      <EvaluationCard
        title="Compétences Personnelles"
        metrics={[
          {
            label: "Compétences en communication efficace",
            value: evaluation_certificat?.students_skil_Competences,
          },
          {
            label: "Capacité de réflexion critique et analytique",
            value: evaluation_certificat?.students_skil_Capacite_de_reflexion,
          },
          {
            label: "Réceptivité aux retours d'information",
            value: evaluation_certificat?.students_skil_Receptivite,
          },
        ]}
      />
      <EvaluationCard
        title="Engagement et Motivation des Participants"
        metrics={[
          {
            label:
              "Niveau de motivation personnelle tout au long de la formation",
            value: evaluation_certificat?.students_skil_niveau_de_motivation,
          },
          {
            label: "Volonté de s'impliquer dans les activités proposées",
            value: evaluation_certificat?.students_skil_volonte_de_simpliquer,
          },
          {
            label: "Initiative dans les discussions et partage d'idées",
            value: evaluation_certificat?.students_skil_volonte_de_simpliquer,
          },
          {
            label: "Capacité à maintenir une attitude positive et proactive",
            value: evaluation_certificat?.students_skil_capacite_a_maintenir,
          },
          {
            label:
              "Engagement dans la réflexion sur ses propres apprentissages",
            value: evaluation_certificat?.students_skil_engagement_dans_la,
          },
        ]}
      />
    </div>
  );
}
