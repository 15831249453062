import React from "react";
import { Box } from "@mui/material";

import { Stats } from "./Stats";
import { TrainingForm } from "./TrainingForm";
import { TrainingList } from "./TrainingList";

import "./style.css";

/**
 * Dashboard component.
 *
 * This component renders the main dashboard view, which includes a welcome message,
 * statistics section, a form for adding new training, and a list of upcoming trainings.
 * It is styled with a flexible layout and ensures responsiveness with grid columns.
 *
 * @returns {JSX.Element} The rendered dashboard component.
 */
const Dashboard = () => {
  return (
    <>
      <main className="Dashboard flex-1 p-8">
        <div className="mb-5">
          <h1 className="text-2xl font-bold text-gray-800">Tableau de bord</h1>
          <p className="text-gray-600 mt-1">
            Bienvenue sur votre espace de gestion
          </p>
        </div>

        <Stats />

        <Box
          sx={{
            gridTemplateColumns: {
              md: "repeat(2, minmax(0, 1fr))",
              xs: "repeat(1, minmax(0, 1fr))",
            },
          }}
          className="mt-8 grid gap-6"
        >
          <div className="bg-white rounded-xl p-6 shadow-sm">
            <h2 className="text-xl font-bold mb-6">
              Nouvelle formation / événement
            </h2>
            <TrainingForm />
          </div>

          <div className="bg-white rounded-xl p-6 shadow-sm">
            <h2 className="text-xl font-bold mb-6">
              Formations / événements à venir
            </h2>
            <TrainingList />
          </div>
        </Box>
      </main>
    </>
  );
};

export default Dashboard;
