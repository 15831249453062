import React from "react";
import { motion } from "framer-motion";
import MetricProgress from "./MetricProgress";
import MetricScore from "./MetricScore";

export default function MetricItem({ metric, index }) {
  const isLowScore = metric.value <= 30;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3, delay: index * 0.1 }}
      className={`group p-4 rounded-lg transition-all ${
        isLowScore ? "bg-red-50 hover:bg-red-100" : "hover:bg-gray-50"
      }`}
    >
      <div className="flex justify-between items-center mb-2">
        <span
          className={`text-sm font-medium ${
            isLowScore ? "text-red-700" : "text-gray-700"
          }`}
        >
          {metric.label}
        </span>
        <MetricScore value={metric.value} size="sm" />
      </div>
      <MetricProgress value={metric.value} isLowScore={isLowScore} />
      {isLowScore && (
        <p className="mt-2 text-xs text-red-600">
          ⚠️ Cette compétence nécessite une attention particulière
        </p>
      )}
    </motion.div>
  );
}
