import React, { useState } from 'react';

import { DashboardStats } from './DashboardStats.js';
import { TransactionList } from './TransactionList.js';
import DashboardHeader from '../diminuir_partenir/DashboardHeader.js';

import './style.css';

// Mock data for demonstration
const mockStats = {
  totalTransactions: 150,
  totalRevenue: 25000,
  pendingPayments: 12,
};

const mockTransactions = [
  {
    id: '1',
    clientId: 'Client A',
    serviceId: 'Service 1',
    amount: 1500,
    sellerCommission: 150,
    date: '2024-03-15',
    status: 'completed',
  },
  {
    id: '2',
    clientId: 'Client B',
    serviceId: 'Service 2',
    amount: 2000,
    sellerCommission: 200,
    date: '2024-03-14',
    status: 'pending',
  },
];

/**
 * React component for the Financial Dashboard.
 *
 * This component displays a dashboard with some key statistics and a list of recent transactions.
 *
 * @returns {JSX.Element} The component.
 */
function TableauDeBordFinancier() {
  const [currentMonth, setCurrentMonth] = useState('Mars');
  const [currentYear, setCurrentYear] = useState(2024);

  return (
    <div className="Tableau_De_Bord_Financier min-h-screen bg-gray-100">
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900">
            Tableau de Bord Financier
          </h1>
        </div>
      </header>

      <main className="max-w-7xl mx-auto py-6 ">
        <DashboardStats stats={mockStats} />

        <DashboardHeader
          currentMonth={currentMonth}
          currentYear={currentYear}
          onMonthChange={setCurrentMonth}
          onYearChange={setCurrentYear}
        />

        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-xl font-semibold text-gray-900 mb-4">
            Transactions Récentes
          </h2>
          <TransactionList transactions={mockTransactions} />
        </div>
      </main>
    </div>
  );
}

export default TableauDeBordFinancier;
