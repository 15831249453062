import React from "react";
import {
  Activity,
  Shield,
  AlertTriangle,
  Siren,
  BarChart3,
} from "lucide-react";

const modules = [
  {
    title: "Évaluation des risques SST",
    icon: Activity,
  },
  {
    title: "Évaluation des aspects environnementaux",
    icon: Shield,
  },
  {
    title: "Risques SST",
    icon: AlertTriangle,
  },
  {
    title: "Situations d'urgence",
    icon: Siren,
  },
  {
    title: "Outils de pilotage et reporting HSE",
    icon: BarChart3,
  },
];

const Program = ({ course }) => {
  return (
    <div className="py-24 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-title text-center text-gray-900 mb-16">
          Programme
        </h2>

        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {course.Programme_list?.map((module, index) => {
            const Icon = module.icon;
            return (
              <div
                key={index}
                className="bg-white rounded-xl p-8 shadow-sm hover:shadow-md transition-shadow"
              >
                <div className="flex items-center space-x-4">
                  <div className="p-3 bg-blue-50 rounded-lg">
                    <i
                      className={`fa ${module.icon} w-6 h-6 text-blue-600`}
                    ></i>
                  </div>
                  <h3 className="text-lg font-semibold text-gray-900">
                    {module.text}
                  </h3>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Program;
