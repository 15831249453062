import React from "react";

/**
 * Un composant qui affiche un cadre de certificat.
 * Les éléments décoratifs sont positionnés de manière absolue pour
 * ne pas prendre de place dans le flux normal du document.
 * Le contenu est positionné de manière relative pour être placé
 * par-dessus les éléments décoratifs.
 * @param {{ children: ReactNode }} props Les props du composant.
 * @returns {ReactElement} Le composant CertificateFrame.
 */
const CertificateFrame = ({ children }) => {
  return (
    <div className="relative bg-white rounded-lg shadow-lg p-8 border-2 border-blue-100 overflow-hidden">
      {/* Éléments décoratifs */}
      <div className="absolute top-0 left-0 w-32 h-32 bg-blue-50 rounded-br-full opacity-50" />
      <div className="absolute top-0 right-0 w-32 h-32 bg-blue-50 rounded-bl-full opacity-50" />
      <div className="absolute bottom-0 left-0 w-32 h-32 bg-blue-50 rounded-tr-full opacity-50" />
      <div className="absolute bottom-0 right-0 w-32 h-32 bg-blue-50 rounded-tl-full opacity-50" />

      {/* Bordure décorative */}
      <div className="absolute inset-4 border-4 border-blue-100 rounded-lg opacity-30" />

      {/* Contenu */}
      <div className="relative z-10">{children}</div>
    </div>
  );
};

export default CertificateFrame;
