import React, { useEffect } from 'react';
import {
  Clock,
  User,
  BookOpen,
  Calendar as CalendarIcon,
  Box,
} from 'lucide-react';
import { Box as BoxButton } from '@mui/material';

import './style.css';

/**
 * A React component that renders a form for creating or editing a training session.
 *
 * The form contains fields for the title, theme, trainer, start date, end date, and description.
 * The component accepts an `onSubmit` function that will be called when the form is submitted,
 * an `onCancel` function that will be called when the cancel button is clicked, and an `initialData`
 * object that contains the initial values for the form fields.
 *
 * @param {Object} props - The component props.
 * @param {function} props.onSubmit - The function to be called when the form is submitted.
 * @param {function} props.onCancel - The function to be called when the cancel button is clicked.
 * @param {Object} props.initialData - The initial values for the form fields.
 */
export function SessionForm({ onSubmit, onCancel, initialData }) {
  const [formData, setFormData] = React.useState({
    title: initialData?.title || '',
    theme: initialData?.theme || '',
    trainer: initialData?.trainer || '',
    start: initialData?.start
      ? formatDateTimeLocal(new Date(initialData.start))
      : '',
    end: initialData?.end ? formatDateTimeLocal(new Date(initialData.end)) : '',
    description: initialData?.description || '',
  });

  /**
   * Handles the form submission event by preventing the default form action and
   * calling the `onSubmit` function with the form data.
   *
   * @param {Event} e - The form submission event.
   */
  const handleSubmit = e => {
    e.preventDefault();
    onSubmit({
      title: formData.title,
      theme: formData.theme,
      trainer: formData.trainer,
      start: new Date(formData.start),
      end: new Date(formData.end),
      description: formData.description,
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="mb-3">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          <div className="flex items-center gap-2">
            <BookOpen className="w-4 h-4" />
            Thème
          </div>
        </label>
        <input
          type="text"
          required
          value={formData.theme}
          onChange={e =>
            setFormData(prev => ({ ...prev, theme: e.target.value }))
          }
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <div className="mb-3">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          <div className="flex items-center gap-2">
            <BookOpen className="w-4 h-4" />
            séance
          </div>
        </label>

        <select
          value={formData.seance}
          defaultValue={1}
          onChange={e =>
            setFormData(prev => ({ ...prev, seance: e.target.value }))
          }
          style={{
            width: '100%',
            padding: '0.5rem',
            border: '1px solid #d1d5db',
            borderRadius: '0.375rem',
            outline: 'none',
            transition: 'box-shadow 0.2s',
          }}
        >
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
        </select>
      </div>

      <div className="mb-3">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          <div className="flex items-center gap-2">
            <BookOpen className="w-4 h-4" />
            Titre
          </div>
        </label>
        <input
          type="text"
          required
          value={formData.title}
          onChange={e =>
            setFormData(prev => ({ ...prev, title: e.target.value }))
          }
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <div className=" row gap-4 justify-content-around ">
        <div className="col-5 col-md-12 ">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            <div className="flex items-center gap-2">
              <Clock className="w-4 h-4" />
              Début
            </div>
          </label>
          <input
            type="datetime-local"
            required
            value={formData.start}
            onChange={e => {
              setFormData(prev => ({ ...prev, start: e.target.value }));
              setFormData(prev => ({ ...prev, end: e.target.value }));
            }}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div className="col-5 col-md-12 ">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            <div className="flex items-center gap-2">
              <Clock className="w-4 h-4" />
              Fin
            </div>
          </label>
          <div className="row border border-gray-300 rounded-md focus:outline-none">
            <div className="col-6 px-0 ">
              <input
                style={{
                  padding: '9.5px 5px 9.5px 8px',
                  border: 'none',
                  outline: 'none',
                }}
                type="date"
                value={formData.end.split('T')[0]}
                disabled
                // className="w-full border border-gray-300 rounded-md focus:outline-none"
                className="w-full "
              />
            </div>
            <div className="col-6 px-0 ">
              <input
                style={{
                  padding: '9.5px 5px 9.5px 8px',
                  border: 'none',
                  outline: 'none',
                }}
                type="time"
                required
                value={formData.end.split('T')[1]}
                onChange={e => {
                  const [date] = formData.end.split('T');
                  setFormData(prev => ({
                    ...prev,
                    end: `${date}T${e.target.value}`,
                  }));
                }}
                // className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                className="w-full px-3 py-2 "
              />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="mt-3">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          <div className="flex items-center gap-2">
            <CalendarIcon className="w-4 h-4" />
            Description
          </div>
        </label>
        <textarea
          value={formData.description}
          onChange={e =>
            setFormData(prev => ({ ...prev, description: e.target.value }))
          }
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          rows={3}
        />
      </div> */}

      <div className="flex justify-end space-x-3 pt-4">
        <BoxButton
          component="button"
          sx={{
            '&:hover': {
              backgroundColor: 'rgb(220, 220, 220)',
              color: 'rgb(31, 41, 55)',
            },
          }}
          type="button"
          onClick={onCancel}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md"
        >
          Annuler
        </BoxButton>
        <button
          type="submit"
          className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
        >
          Enregistrer
        </button>
      </div>
    </form>
  );
}

function formatDateTimeLocal(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}`;
}
