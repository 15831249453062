import { addHours } from "date-fns";

const today = new Date();

export const mockSessions = [
  {
    id: "1",
    title: "Introduction au JavaScript",
    theme: "Développement Web Frontend",
    start: addHours(today, 1),
    end: addHours(today, 3),
    trainer: "Sophie Martin",
    description: "Bases du JavaScript et manipulation du DOM",
  },
  {
    id: "2",
    title: "React Fondamentaux",
    theme: "Développement React",
    start: addHours(today, 24),
    end: addHours(today, 27),
    trainer: "Thomas Dubois",
    description: "Introduction aux composants React et état",
  },
  {
    id: "3",
    title: "API REST",
    theme: "Développement Backend",
    start: addHours(today, 48),
    end: addHours(today, 51),
    trainer: "Marie Lambert",
    description: "Conception et implémentation d'APIs REST",
  },
];

export const mockTrainers = [
  {
    id: "1",
    name: "Sophie Martin",
    email: "sophie.martin@formation.fr",
    specialization: "JavaScript & Frontend",
  },
  {
    id: "2",
    name: "Thomas Dubois",
    email: "thomas.dubois@formation.fr",
    specialization: "React & React Native",
  },
  {
    id: "3",
    name: "Marie Lambert",
    email: "marie.lambert@formation.fr",
    specialization: "Backend & APIs",
  },
];
