import React, { useEffect, useState } from 'react';
import { Clock } from 'lucide-react';

import { useDeadlineStore } from './deadlineStore.js';
import { formation_payant } from '../../../../db.js';

/**
 * A form component for adding a new deadline to the store.
 *
 * The form accepts a title, description, due date, service type, and
 * assigned to. When submitted, the form adds the new deadline to the
 * store and clears the form.
 *
 * @param {function} onRedeemPoints - Called after adding a new deadline.
 * @returns {ReactElement} The rendered form component.
 */
export function DeadlineForm({ onRedeemPoints, handleClose }) {
  const addDeadline = useDeadlineStore(state => state.addDeadline);
  const [formData, setFormData] = useState({
    serviceType: '',
  });

  useEffect(() => {
    setFormData(prevState => ({
      ...prevState,
      serviceType: formation_payant[0].title,
    }));
  }, []);

  /**
   * Handles the form submission event by preventing the default form action,
   * adding a new deadline to the store, and clearing the form. Finally, it
   * calls the `onRedeemPoints` function to update the points tracker.
   *
   * @param {Event} e - The form submission event.
   */
  const handleSubmit = e => {
    e.preventDefault();
    addDeadline({
      ...formData,
      dueDate: new Date(formData.dueDate),
    });

    onRedeemPoints();
    handleClose();
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        padding: '1.5rem',
        background: 'white',
        borderRadius: '0.75rem',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      }}
    >
      <div style={{ marginBottom: '1rem' }}>
        <label
          style={{
            display: 'flex',
            alignItems: 'center',
            color: '#4b5563',
            marginBottom: '0.5rem',
          }}
        >
          <Clock
            style={{
              width: '1.25rem',
              height: '1.25rem',
              marginRight: '0.5rem',
            }}
          />
          Type de Service
        </label>
        <select
          value={formData.serviceType}
          onChange={e =>
            setFormData({ ...formData, serviceType: e.target.value })
          }
          style={{
            width: '100%',
            padding: '0.5rem',
            border: '1px solid #d1d5db',
            borderRadius: '0.375rem',
            outline: 'none',
            transition: 'box-shadow 0.2s',
          }}
        >
          {formation_payant?.map((item, index) => (
            <option value={item.title}>{item.title}</option>
          ))}
        </select>
      </div>

      <button
        type="submit"
        style={{
          width: '100%',
          backgroundColor: '#2563eb',
          color: 'white',
          padding: '0.5rem 1rem',
          borderRadius: '0.375rem',
          textAlign: 'center',
          fontWeight: 'bold',
          transition: 'background-color 0.2s',
          border: 'none',
          cursor: 'pointer',
        }}
        onMouseOver={e => (e.target.style.backgroundColor = '#1d4ed8')}
        onMouseOut={e => (e.target.style.backgroundColor = '#2563eb')}
      >
        Ajouter
      </button>
    </form>
  );
}
