import React from "react";
import { Calendar, Clock, MapPin } from "lucide-react";

/**
 * A React component that renders a card representing an event.
 * The card displays the event image, title, date, time, location, and description.
 * It also includes a button to register for the event.
 *
 * @param {{ event: { image: string, title: string, date: string, time: string, location: string, description: string } }} props
 * @returns {JSX.Element} The rendered JSX element.
 */
export default function EventCard({ event }) {
  return (
    <div className="bg-white rounded-xl shadow-md overflow-hidden hover:shadow-lg transition-all">
      <img
        src={event.image}
        alt={event.title}
        className="w-full h-48 object-cover"
      />
      <div className="p-6">
        <h3 className="text-xl font-semibold text-gray-900 mb-2">
          {event.title}
        </h3>
        <div className="space-y-2 text-gray-600 mb-4">
          <div className="flex items-center">
            <Calendar className="w-5 h-5 mr-2" />
            {event.date}
          </div>
          <div className="flex items-center">
            <Clock className="w-5 h-5 mr-2" />
            {event.time}
          </div>
          <div className="flex items-center">
            <MapPin className="w-5 h-5 mr-2" />
            {event.location}
          </div>
        </div>
        <p className="text-gray-600 mb-4">{event.description}</p>
        <button className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition-colors">
          S'inscrire
        </button>
      </div>
    </div>
  );
}
