import React from "react";
import { motion } from "framer-motion";

export function Header() {
  return (
    <motion.header
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      className="mb-8 text-center"
    >
      <h1 className="text-4xl font-bold bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent">
        Tableau d'Évaluation
      </h1>
      <p className="text-gray-600 mt-2">
        Suivi détaillé des performances et compétences
      </p>
    </motion.header>
  );
}
