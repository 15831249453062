import React from 'react';
import { Clock, Euro } from 'lucide-react';

/**
 * ServiceCard component displays a service with its details and actions
 *
 * @component
 * @param {Object} props - Component properties
 * @param {Object} props.service - Service information
 * @param {string} props.service.id - Service identifier
 * @param {string} props.service.name - Service name
 * @param {string} props.service.description - Service description
 * @param {number} props.service.duration - Service duration in minutes
 * @param {number} props.service.price - Service price
 * @param {Object} props.service.schedule - Service availability schedule
 * @param {string[]} props.service.schedule.days - Available days
 * @param {string[]} props.service.schedule.hours - Available hours
 * @param {Object[]} props.services_selected - List of selected services
 * @param {Function} [props.setServices_selected] - Function to add service to selected services list
 * @param {Function} [props.onCheckAvailability] - Function to check service availability
 *
 * @returns {React.ReactElement} Service card component
 */
export const ServiceCard = ({ service, services_selected, setServices_selected }) => {
  return (
    <div className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow">
      <h3 className="text-xl font-semibold text-gray-800 mb-2">
        {service.name}
      </h3>
      <p className="text-gray-600 mb-4">{service.description}</p>

      <div className="flex items-center gap-4 mb-4">
        <div className="flex items-center gap-1">
          <Clock className="w-4 h-4 text-gray-500" />
          <span className="text-sm text-gray-600">{service.duration} min</span>
        </div>
        <div className="flex items-center gap-1">
          <Euro className="w-4 h-4 text-gray-500" />
          <span className="text-sm text-gray-600">{service.price}€</span>
        </div>
      </div>

      <div className="mb-4">
        <h4 className="text-sm font-semibold text-gray-700 mb-2">Horaires :</h4>
        <div className="text-sm text-gray-600">
          <p>{service.schedule.days.join(', ')}</p>
          <p>
            {service.schedule.hours[0]} -{' '}
            {service.schedule.hours[service.schedule.hours.length - 1]}
          </p>
        </div>
      </div>

      {setServices_selected && (
        <button
          onClick={() =>
            setServices_selected(prevState => [...prevState, service])
          }
          disabled={services_selected?.some(s => s.id === service.id)}
          className={`w-full py-2 px-4 rounded-md transition-colors ${
            services_selected?.some(s => s.id === service.id)
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-indigo-600 hover:bg-indigo-700 text-white'
          }`}
        >
          {services_selected?.some(s => s.id === service.id)
            ? 'Déjà sélectionné'
            : 'Vérifier les disponibilités'}
        </button>
      )}
    </div>
  );
};
