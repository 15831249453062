import React from "react";
import { FormInput } from "./FormInput.jsx";
import { FormSelect } from "./FormSelect.jsx";

const domains = [
  "Développement Web",
  "Marketing Digital",
  "Design Graphique",
  "Gestion de Projet",
  "Business & Entrepreneuriat",
];

export function RegistrationForm() {
  return (
    <form className="space-y-6">
      <h3 className="font-heading text-2xl font-semibold text-center mb-6 bg-gradient-to-r from-primary-400 to-primary-200 bg-clip-text text-transparent">
        Inscrivez-vous
      </h3>

      <FormSelect label="Domaine" options={domains} />

      <FormInput
        label="Nom et prénom"
        type="text"
        placeholder="Votre nom complet"
      />

      <FormInput label="Email" type="email" placeholder="votre@email.com" />

      <FormInput
        label="N°Whatsapp"
        type="tel"
        placeholder="+33 6 12 34 56 78"
      />

      <button
        type="submit"
        className="w-full bg-gradient-to-r from-primary-600 to-primary-500 hover:from-primary-700 hover:to-primary-600 text-white font-heading font-semibold py-3 px-6 rounded-lg transition-all duration-300 transform hover:scale-[1.02]"
      >
        ENVOYER
      </button>
    </form>
  );
}
