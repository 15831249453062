import React from "react";

import "../../styles/E-Trainings/Course-groups/style.css";

/**
 * A functional component that renders a section of a website dedicated to e-trainings,
 * with a heading, a paragraph of text, and two cards containing information about
 * the two commercial agents that can be contacted.
 * @returns {React.ReactElement} The rendered React element.
 */

const E_Trainings_Course_groups = () => {
  return (
    <div style={{ marginTop: "80px" }} className="E_Trainings_Course_groups">
      <div className="container">
        <div className="header-section">
          <h1>Service Commercial</h1>
          <p>
            Notre équipe commerciale est à votre écoute pour vous accompagner
            dans votre parcours de formation
          </p>
        </div>

        <div className="cards-container">
          <div className="service-card">
            <div className="card-header">
              <h3 className="card-title">Commercial 1</h3>
              <span className="status-badge">Informations</span>
            </div>
            <div className="card-content">
              <p className="card-description">
                Pour plus d'informations sur la formation, contactez notre
                Commercial 1 qui sera ravi de vous renseigner et de répondre à
                toutes vos questions.
              </p>
              <a
                href="https://chat.whatsapp.com/CtHK1NrZqhDCIQvKU6vcGU"
                target="_blanck"
                className="contact-button"
              >
                <i className="fab fa-whatsapp whatsapp-icon"></i>
                Contacter le Commercial 1
              </a>
            </div>
          </div>

          <div className="service-card">
            <div className="card-header confirmation">
              <h3 className="card-title">Commercial 2</h3>
              <span className="status-badge">Paiement</span>
            </div>
            <div className="card-content">
              <p className="card-description">
                Pour confirmer votre paiement et finaliser votre inscription,
                notre Commercial 2 vous guidera dans toutes les étapes de la
                procédure.
              </p>
              <a
                href="https://chat.whatsapp.com/KkZiL6Z9f3dEDTsjQluBKO"
                target="_blanck"
                className="contact-button confirmation"
              >
                <i className="fab fa-whatsapp whatsapp-icon"></i>
                Contacter le Commercial 2
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default E_Trainings_Course_groups;
