import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Calendar, Clock, Users, PlayCircle } from "lucide-react";
import { Box } from "@mui/material";

import { formation_payant } from "../db";
import { get_All } from "../functions/restApi";
import Objectives from "../components/E-Trainings/course_single/Objectives";
import Program from "../components/E-Trainings/course_single/Program";
import RegistrationForm from "../components/E-Trainings/course_single/RegistrationForm";

import "../styles/course_Single/style.css";

/**
 * Affiche une page de cours unique.
 *
 * Cette page affiche une formation unique avec ses objectifs, programme, séances
 * et un formulaire de pré-inscription. Les données de la formation sont
 * récupérées depuis la base de données.
 *
 * @return {ReactElement} La page de cours unique.
 */
const CourseSingle = () => {
  const { id } = useParams();
  const [course, setCourse] = useState({});
  const [formData, setFormData] = useState({
    Nom_et_prenom: "",
    email: "",
    phone_Number: "",
    categorie_categorie: "",
  });

  const handleChangeData = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      categorie_categorie: course.categorie,
    }));
  }, [course]);

  const navigate = useNavigate();

  // const fetchCountryCodes = async () => {
  //   try {
  //     const response = await get_All('https://restcountries.com/v3.1/all');

  //     const countryCodes = response.data.map(country => ({
  //       name: country.name.common,
  //       dialCode: `${country.idd.root || ''}${(country.idd.suffixes || [])[0] || ''}`,
  //     }));

  //     console.log(countryCodes);
  //   } catch (error) {
  //     console.error('Error fetching country codes:', error);
  //   }
  // };

  // fetchCountryCodes();

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   localStorage.setItem("participantP1", JSON.stringify(formData));

  //   navigate(`/Course-price/${id}`);
  // };

  /**
   * Fetches a single course by its ID from the API and updates the course state.
   *
   * This function sends a GET request to the specified API endpoint to retrieve
   * the details of a single course based on the provided course ID from the URL
   * parameters. Upon a successful response, the course state is updated with the
   * fetched course data. Logs any errors encountered during the fetch operation.
   */
  const get_one_cours = async () => {
    try {
      const res = await get_All(
        `https://ma-training-consulting-company-site-backend-one.vercel.app/api/get-one-cours/${id}`
      );

      if (res.data) {
        setCourse(res.data.cours);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    get_one_cours();
  }, [id]);

  useEffect(() => {
    const numericId = parseInt(id, 10);
    const filteredCourse = formation_payant.filter(
      (item) => item.id === numericId
    );
    setCourse(filteredCourse[0]);
  }, [id]);

  return (
    <Box
      sx={{
        marginTop: "80px",
      }}
      className="CourseSingle"
    >
      <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50">
        {/* Hero Section */}
        <div className="relative overflow-hidden bg-blue-600 text-white">
          <div className="absolute inset-0">
            <img
              src="https://images.unsplash.com/photo-1581094794329-c8112a89af12?auto=format&fit=crop&q=80"
              alt="Background"
              className="w-full h-full object-cover opacity-20"
            />
          </div>
          <div className="relative max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
            <div className="text-center">
              <h1 className="text-4xl font-bold tracking-tight sm:text-5xl lg:text-6xl">
                {course?.title}
              </h1>
              <p className="mt-4 text-xl text-blue-100">
                Planning des sessions de formation professionnelle
              </p>
            </div>
          </div>
        </div>

        {/* Video Preview */}
        <div className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
          <div className="relative rounded-2xl overflow-hidden shadow-xl">
            <div className="aspect-w-16 aspect-h-9 bg-gray-900">
              <a
                style={{
                  zIndex: 5,
                }}
                href={course?.videoLink}
                target="_blanck"
                className="absolute inset-0 flex items-center justify-center"
              >
                <PlayCircle className="w-20 h-20 text-white opacity-80" />
              </a>
              <img
                src={course?.videoImage}
                alt="Formation Preview"
                className="w-full h-full object-cover opacity-50"
              />
            </div>
          </div>
        </div>

        {/* Sessions List */}
        <div className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
          <div className="grid gap-8 md:grid-cols-2">
            {course?.Session_date?.map((session, index) => (
              <div
                key={index}
                className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
              >
                <div className="p-6">
                  <h3 className="text-xl font-semibold text-gray-900">
                    {session.text}
                  </h3>
                  <div className="mt-4 space-y-3">
                    <div className="flex items-center text-gray-600">
                      <Calendar className="w-5 h-5 mr-3 text-blue-600" />
                      <span>{session.date}</span>
                    </div>
                    <div className="flex items-center text-gray-600">
                      <Clock className="w-5 h-5 mr-3 text-blue-600" />
                      <span>{session.Horaire}</span>
                    </div>
                    <div className="flex items-center text-gray-600">
                      <Users className="w-5 h-5 mr-3 text-blue-600" />
                      <span>
                        Places disponibles: {session.availableSpots}/
                        {session.totalSpots}
                      </span>
                    </div>
                  </div>
                  <div className="mt-6">
                    <div className="flex flex-wrap gap-2">
                      {session.Jours?.map((day) => (
                        <span
                          key={day}
                          className="px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800"
                        >
                          {day}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50">
        <Objectives course={course} />
        <Program course={course} />
        <RegistrationForm id={id} />
      </div>
    </Box>
  );
};

export default CourseSingle;
