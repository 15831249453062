export const upcomingEvents = [
  {
    id: 1,
    title: 'Workshop: Sécurité sur le Lieu de Travail',
    date: '20 Oct 2024',
    time: '11:00 - 13:30',
    month: 'Oct',
    day: '20',
    year: '2024'
  },
  {
    id: 2,
    title: 'Webinaire: Nouvelles Normes QHSE 2024',
    date: '25 Oct 2024',
    time: '14:00 - 16:00',
    month: 'Oct',
    day: '25',
    year: '2024'
  }
];