import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import "./style.css";

/**
 * Navbar component
 *
 * This component renders a navigation bar with menu items for dashboard navigation.
 * It includes icons and labels for different sections such as Dashboard, Formations,
 * and Participants. The navigation bar is responsive and can toggle visibility.
 *
 * Props:
 * - setNavigate (function): Function to update the current navigation path.
 * - navigate (string): Current navigation path.
 * - setAuth (function): Function to update authentication state.
 *
 * State:
 * - navshow (boolean): Controls the visibility of the navigation menu.
 */
const Navbar = ({ setNavigate, navigate, menuItems, setAuth }) => {
  const [navshow, setNavshow] = useState(false);

  return (
    <>
      <AppBar
        sx={{
          flexGrow: 1,
          position: "fixed",
          top: "80px",
          width: "100%",
          background: "#fff",
          display: "none",
          zIndex: 1,
          display: "none",
          justifyContent: "end",
          zIndex: 5,
        }}
        className="nav-header  "
      >
        <Toolbar>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
            }}
            onClick={() => setNavshow(!navshow)}
            className="nftmax__sicon close-icon"
          >
            <i
              style={{
                color: "black",
              }}
              className="fa-solid fa-bars"
            ></i>
          </div>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          zIndex: 5,
          marginTop: {
            md: "0px",
            xs: "24px",
          },
        }}
        className={`nftmax-smenu ${navshow ? "nftmax-close" : ""}`}
      >
        <div
          className="Sidebar
           left-0 h-full w-64 bg-gradient-to-b from-blue-500 to-blue-600 text-white"
        >
          <div className="p-6">
            <h1 className="text-xl font-semibold font-inter">
              Espace de gestion
            </h1>
          </div>

          <nav className="mt-6">
            {menuItems.map((item, index) => (
              <a
                style={{
                  cursor: "pointer",
                }}
                key={index}
                onClick={() => {
                  setNavigate(item.path);
                  setNavshow(false);
                }}
                className={` flex items-center px-6 py-3 text-sm font-medium hover:bg-blue-400/20 transition-colors border-l-4 border-transparent ${
                  navigate === item.path && "border-white bg-blue-400/20"
                }`}
              >
                <item.icon className="h-5 w-5 mr-3" />
                {item.label}
              </a>
            ))}
          </nav>
        </div>
      </Box>
    </>
  );
};

export default Navbar;
