import React from "react";
import { GraduationCap, BookOpen, Users } from "lucide-react";

import { HeroCard } from "./HeroCard";

import "../../styles/E-Trainings/service/css/style.css";

/**
 * E_Trainings_services is a React component that renders a section of the
 * E-Trainings homepage, which displays three services: certification
 * verification, professional training, and initiation/perfectionnement.
 * The component uses an AOS library to animate the services when the user
 * scrolls to the section.
 */
export const E_Trainings_services = () => {
  const cards = [
    {
      icon: Users,
      title: "Authentification des Certifications",
      description:
        "Système sécurisé de validation des Attestations professionnelles. Garantissez l'authenticité de vos qualifications et renforcez la crédibilité de votre parcours professionnel.",
      buttonText: "DÉCOUVRIR",
      bgColor: "bg-navy-900",
      path: "/Verivication",
    },
    {
      icon: GraduationCap,
      title: "Maîtrise Professionnelle",
      description:
        "Programmes de formation accrédités, conçus avec des experts du secteur. Développez des compétences stratégiques adaptées aux besoins du marché actuel.",
      buttonText: "EXPLORER",
      bgColor: "bg-primary-600",
    },
    {
      icon: BookOpen,
      title: "Initiation & Perfectionnement",
      description:
        "Accédez à notre bibliothèque de ressources éducatives gratuites. Découvrez des contenus multimédias, des webinaires animés par des experts et des modules interactifs.",
      buttonText: "COMMENCER",
      bgColor: "bg-navy-900",
      // path: "/formation-dinitiation",
      path: "#",
    },
  ];

  return (
    <>
      <section className="services-section relative bg-gradient-to-b from-navy-950 to-navy-900 overflow-hidden py-4 ">
        {/* Background Elements */}
        <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1521737711867-e3b97375f902?auto=format&fit=crop&q=80')] bg-cover bg-center opacity-10"></div>
        <div className="absolute inset-0 bg-gradient-to-r from-navy-950/90 to-primary-900/80"></div>

        {/* Content */}
        <div className="relative z-10 max-w-7xl mx-auto px-4 pt-20 pb-12">
          {/* Cards Grid */}
          <div className="grid md:grid-cols-3 gap-6 lg:gap-8">
            {cards.map((card, index) => (
              <HeroCard key={index} {...card} />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};
