/**
 * Calculates the total points awarded based on the order amount.
 *
 * The function calculates base points at a rate of 1 point per $10 spent.
 * Additional bonus points are awarded for larger order amounts:
 * - An extra 5 points for orders of $100 or more.
 * - An extra 10 points for orders of $250 or more.
 * - An extra 25 points for orders of $500 or more.
 *
 * @param {number} orderAmount - The total amount of the order in dollars.
 * @returns {number} The total calculated points including base and bonus points.
 */
export const calculatePoints = (orderAmount) => {
  // Base points calculation: 1 point per $10 spent
  const basePoints = Math.floor(orderAmount / 10);

  // Bonus points for larger orders
  let bonusPoints = 0;
  if (orderAmount >= 100) bonusPoints += 5;
  if (orderAmount >= 250) bonusPoints += 10;
  if (orderAmount >= 500) bonusPoints += 25;

  return basePoints + bonusPoints;
};

/**
 * Checks if a seller has enough points to redeem a free client
 * @param {number} points - The current points of the seller
 * @returns {boolean} Whether the seller has enough points
 */
export const canRedeemForFreeClient = (points) => {
  const POINTS_NEEDED_FOR_FREE_CLIENT = 50;
  return points >= POINTS_NEEDED_FOR_FREE_CLIENT;
};

/**
 * Reduces the seller's points by the amount needed to redeem a free client
 * @param {number} currentPoints - The current points of the seller
 * @returns {number} The new points of the seller
 */
export const redeemPointsForFreeClient = (currentPoints) => {
  const POINTS_NEEDED_FOR_FREE_CLIENT = 50;
  return currentPoints - POINTS_NEEDED_FOR_FREE_CLIENT;
};
