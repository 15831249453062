import React from "react";
import {
  Award,
  Calendar,
  Clock,
  BookOpen,
  CheckCircle,
  Medal,
} from "lucide-react";
import CertificateFrame from "./CertificateFrame.jsx";

/**
 * Certificate component renders a certificate page with a frame, a formation title,
 * formation dates, duration, type, and acquired competences.
 *
 * @param {{ certificate: { Formation_title: string, certificate_imge: string }, name: string }} props
 * @returns {ReactElement} a Certificate component
 */
const Certificate = ({ certificate, name }) => {
  const competences = [
    "Connaissance des normes ISO",
    "Capacité à établir des systèmes de management conformes",
    "Compétences en audit interne",
    "Sensibilisation à l'amélioration continue",
  ];

  return (
    <section className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
      <CertificateFrame>
        <div className="text-center mb-8">
          <div className="flex justify-center items-center mb-4">
            <Award className="h-12 w-12 text-blue-600 mr-2" />
            <Medal className="h-16 w-16 text-blue-600" />
            <Award className="h-12 w-12 text-blue-600 ml-2" />
          </div>
          <div className="h-1 w-32 bg-blue-600 mx-auto mb-4" />
          <img
            style={{
              width: "50%",
              margin: "auto",
            }}
            src={certificate?.certificate_imge}
            alt="image"
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <div className="flex items-center justify-center space-x-4 text-gray-700 bg-blue-50 py-4 rounded-lg">
            <Calendar className="h-5 w-5 text-blue-600" />
            <span>05/08/2024 - 12/08/2024</span>
          </div>
          <div className="flex items-center justify-center space-x-4 text-gray-700 bg-blue-50 py-4 rounded-lg">
            <Clock className="h-5 w-5 text-blue-600" />
            <span>8 heures</span>
          </div>
          <div className="flex items-center justify-center space-x-4 text-gray-700 bg-blue-50 py-4 rounded-lg">
            <BookOpen className="h-5 w-5 text-blue-600" />
            <span>Formation en ligne</span>
          </div>
        </div>

        <div className="bg-blue-50 p-6 rounded-lg">
          <h3 className="text-xl font-semibold text-gray-900 mb-4">
            Compétences acquises
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {competences.map((competence, index) => (
              <div
                key={index}
                className="flex items-center space-x-3 bg-white p-3 rounded-lg"
              >
                <CheckCircle className="h-5 w-5 text-green-500 flex-shrink-0" />
                <span className="text-gray-700">{competence}</span>
              </div>
            ))}
          </div>
        </div>
      </CertificateFrame>
    </section>
  );
};

export default Certificate;
