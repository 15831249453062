import React from 'react';
import { Box } from '@mui/material';
import { CheckCircle, Clock, XCircle } from 'lucide-react';

import { formatCurrency } from './formatters';

/**
 * DashboardStats component.
 *
 * This component displays a set of financial statistics in a grid layout.
 * It presents total transactions, total revenue, and pending payments, each
 * with a corresponding icon and formatted value.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.stats - The statistics to display.
 * @param {number} props.stats.totalTransactions - The total number of transactions.
 * @param {number} props.stats.totalRevenue - The total revenue amount.
 * @param {number} props.stats.pendingPayments - The number of pending payments.
 *
 * @returns {JSX.Element} The rendered component.
 */
export function DashboardStats({ stats }) {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: {
          md: 'repeat(3, minmax(0, 1fr))',
          xs: 'repeat(1, minmax(0, 1fr))',
        },
        gap: '1rem',
      }}
      className="mb-8"
    >
      <div className="bg-white rounded-lg shadow p-6">
        <div className="flex items-center">
          <CheckCircle className="w-5 h-5 mx-3 text-green-600" />
          <div className="ml-4">
            <p className="text-sm font-medium text-gray-500">
              paiements terminés
            </p>
            <p className="text-2xl font-semibold text-gray-900">
              {stats.totalTransactions}
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow p-6">
        <div className="flex items-center">
          <Clock className="w-5 h-5 mx-3 text-yellow-500" />
          <div className="ml-4">
            <p className="text-sm font-medium text-gray-500">
              Paiements en attente
            </p>
            <p className="text-2xl font-semibold text-gray-900  ">
              {formatCurrency(stats.totalRevenue)}
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow p-6">
        <div className="flex items-center">
          <XCircle className="w-5 h-5 mx-3 text-red-600" />
          <div className="ml-4">
            <p className="text-sm font-medium text-gray-500">
              paiements annulés
            </p>
            <p className="text-2xl font-semibold text-gray-900 ">
              {stats.pendingPayments}
            </p>
          </div>
        </div>
      </div>
    </Box>
  );
}
