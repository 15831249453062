import { motion } from "framer-motion";

/**
 * MenuCard component renders a card with an icon, title, and description.
 * It supports click interaction with animation effects on hover and tap.
 *
 * Props:
 * @param {ReactNode} icon - The icon displayed within the card.
 * @param {string} title - The title text displayed in the card.
 * @param {string} description - The description text displayed in the card.
 * @param {function} onClick - Callback function called when the card is clicked.
 */

const MenuCard = ({ icon, title, description, onClick }) => {
  return (
    <motion.button
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      onClick={onClick}
      className="w-full bg-white rounded-xl shadow-md hover:shadow-lg transition-shadow duration-200 overflow-hidden"
    >
      <div className="p-6">
        <div className="flex items-start justify-between mb-4">
          <span className="text-3xl">{icon}</span>
        </div>

        <div className="text-left">
          <h3 className="text-lg font-semibold text-gray-900 mb-2">{title}</h3>
          <p className="text-sm text-gray-600">{description}</p>
        </div>
      </div>
    </motion.button>
  );
};

export default MenuCard;
