import React from "react";
import PropTypes from "prop-types";

export function StatCard({ icon: Icon, value, label, color }) {
  return (
    <div className="bg-white/10 backdrop-blur-lg rounded-2xl p-6 text-white transform hover:scale-105 transition-all duration-300">
      <div className="flex items-center space-x-4">
        <div className={`p-3 rounded-xl bg-white/20 ${color}`}>
          <Icon className="w-6 h-6" />
        </div>
        <div>
          <div className="text-3xl font-bold mb-1">{value}</div>
          <div className="text-sm text-blue-100">{label}</div>
        </div>
      </div>
    </div>
  );
}

StatCard.propTypes = {
  icon: PropTypes.elementType.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};
