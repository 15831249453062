import React from "react";
import { motion } from "framer-motion";
import MetricItem from "./metrics/MetricItem";

export default function EvaluationCard({ section, index, title, metrics }) {
  const averageScore =
    section?.metrics.reduce((acc, curr) => acc + curr.value, 0) /
    section?.metrics.length;
  const hasLowScores = section?.metrics.some((metric) => metric.value <= 30);

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.3, delay: index * 0.1 }}
      className={`rounded-xl p-6 shadow-lg transition-all ${
        hasLowScores ? "bg-white border-2 border-red-200" : "bg-white"
      }`}
    >
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold text-gray-800">
          {section?.title ? section?.title : title}
        </h2>
      </div>
      <div className="space-y-4">
        {section?.metrics
          ? section?.metrics?.map((metric, idx) => (
              <MetricItem key={metric.label} metric={metric} index={idx} />
            ))
          : metrics?.map((metric, idx) => (
              <MetricItem key={metric.label} metric={metric} index={idx} />
            ))}
      </div>
    </motion.div>
  );
}
