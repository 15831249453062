import { create } from "zustand";

export const useDeadlineStore = create((set) => ({
  deadlines: [],
  alerts: [],

  /**
   * Adds a new deadline to the store with the given data.
   * @param {{ title: string, description: string, dueDate: Date, serviceType: string, assignedTo: string }} deadlineData
   * The deadline data to add to the store.
   * @returns {void}
   */
  addDeadline: (deadlineData) => {
    set((state) => ({
      deadlines: [
        ...state.deadlines,
        {
          ...deadlineData,
          id: crypto.randomUUID(),
          status: "pending",
        },
      ],
    }));
  },

  /**
   * Updates the deadline with the given id in the store with the given deadline data.
   * @param {string} id
   * The id of the deadline to update.
   * @param {{ title: string, description: string, dueDate: Date, serviceType: string, assignedTo: string }} deadline
   * The deadline data to update the deadline with.
   * @returns {void}
   */
  updateDeadline: (id, deadline) => {
    set((state) => ({
      deadlines: state.deadlines.map((d) =>
        d.id === id ? { ...d, ...deadline } : d
      ),
    }));
  },

  /**
   * Deletes the deadline with the given id from the store.
   * @param {string} id
   * The id of the deadline to delete.
   * @returns {void}
   */
  deleteDeadline: (id) => {
    set((state) => ({
      deadlines: state.deadlines.filter((d) => d.id !== id),
      alerts: state.alerts.filter((a) => a.deadlineId !== id),
    }));
  },

  /**
   * Marks the alert with the given id as read in the store.
   * @param {string} id
   * The id of the alert to mark as read.
   * @returns {void}
   */
  markAlertAsRead: (id) => {
    set((state) => ({
      alerts: state.alerts.map((alert) =>
        alert.id === id ? { ...alert, isRead: true } : alert
      ),
    }));
  },
}));
