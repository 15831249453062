export const testimonials = [
  {
    name: "Pierre Dubois",
    role: "Finance",
    image:
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?auto=format&fit=crop&q=80&w=200",
    content:
      "Une expérience exceptionnelle ! L'équipe a su répondre parfaitement à nos attentes avec professionnalisme et efficacité. Je recommande vivement leurs services à toute entreprise cherchant des solutions innovantes.",
  },
  {
    name: "Marie Laurent",
    role: "Marketing",
    image:
      "https://images.unsplash.com/photo-1580489944761-15a19d654956?auto=format&fit=crop&q=80&w=200",
    content:
      "Un service client remarquable et des résultats qui dépassent nos attentes. L'équipe a fait preuve d'une grande écoute et d'une expertise pointue dans la réalisation de notre projet.",
  },
  {
    name: "Thomas Martin",
    role: "Direction",
    image:
      "https://images.unsplash.com/photo-1519085360753-af0119f7cbe7?auto=format&fit=crop&q=80&w=200",
    content:
      "Collaboration exceptionnelle ! Leur approche innovante et leur compréhension approfondie de nos besoins ont permis d'obtenir des résultats remarquables pour notre entreprise.",
  },
];

export const companies = [
  {
    logo: "https://placehold.co/200x50/ffffff/000000.png?text=Toy+Jesh",
    rating: 5,
  },
  {
    logo: "https://placehold.co/200x50/ffffff/000000.png?text=Contex",
    rating: 5,
  },
  {
    logo: "https://placehold.co/200x50/ffffff/000000.png?text=Sefrov",
    rating: 5,
  },
];
