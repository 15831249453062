import React from "react";
import { ChevronRight, ChevronLeft } from "lucide-react";

/**
 * A React component that renders a form for answering a question.
 *
 * @param {{ question: { id: string, text: string, options: string[] }, answer: string, onAnswer: (answer: string) => void, onNext: () => void, onPrevious: () => void, isFirst: boolean, isLast: boolean }} props - The component props.
 * @returns {JSX.Element} - The JSX of the question form.
 */
export default function QuestionForm({
  question,
  answer,
  onAnswer,
  onNext,
  onPrevious,
  isFirst,
  isLast,
}) {
  return (
    <div className="w-full max-w-2xl mx-auto p-6">
      <h2 className="text-2xl font-semibold text-gray-800 mb-6">
        {question.text}
      </h2>
      <div className="space-y-4">
        {question.options.map((option) => (
          <label
            key={option}
            className={`block p-4 border rounded-lg cursor-pointer transition-colors
              ${
                answer === option
                  ? "border-blue-500 bg-blue-50"
                  : "border-gray-200 hover:border-blue-200"
              }`}
          >
            <input
              type="radio"
              name={question.id}
              value={option}
              checked={answer === option}
              onChange={(e) => onAnswer(e.target.value)}
              className="mr-3"
            />
            {option}
          </label>
        ))}
      </div>

      <div className="flex justify-between mt-8">
        <button
          onClick={onPrevious}
          disabled={isFirst}
          className={`flex items-center px-4 py-2 rounded-lg
            ${
              isFirst
                ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                : "bg-gray-100 text-gray-600 hover:bg-gray-200"
            }`}
        >
          <ChevronLeft className="w-5 h-5 mr-2" />
          Précédent
        </button>

        <button
          onClick={onNext}
          disabled={!answer}
          className={`flex items-center px-4 py-2 rounded-lg
            ${
              !answer
                ? "bg-blue-100 text-blue-400 cursor-not-allowed"
                : "bg-blue-600 text-white hover:bg-blue-700"
            }`}
        >
          {isLast ? "Terminer" : "Suivant"}
          <ChevronRight className="w-5 h-5 ml-2" />
        </button>
      </div>
    </div>
  );
}
