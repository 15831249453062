import React from 'react';
import { CheckCircle, Clock, XCircle } from 'lucide-react';

/**
 * Renders action buttons for changing the status of a payment.
 *
 * Provides buttons to mark a payment as 'completed', 'pending', or 'cancelled'.
 * Each button is disabled if the payment already has the respective status.
 *
 * @param {object} payment - The payment object containing details of the payment.
 * @param {function} onStatusChange - Callback function to handle status changes.
 */
function StatusActions({ payment, onStatusChange }) {
  return (
    <div className="flex space-x-2">
      <button
        onClick={() => onStatusChange(payment.id, 'completed')}
        disabled={payment.status === 'completed'}
        className={`p-1 rounded-full ${
          payment.status === 'completed'
            ? 'text-green-300 cursor-not-allowed'
            : 'text-green-600 hover:bg-green-100'
        }`}
        title="Marquer comme complété"
      >
        <CheckCircle className="w-5 h-5" />
      </button>

      <button
        onClick={() => onStatusChange(payment.id, 'pending')}
        disabled={payment.status === 'pending'}
        className={`p-1 rounded-full ${
          payment.status === 'pending'
            ? 'text-yellow-300 cursor-not-allowed'
            : 'text-yellow-600 hover:bg-yellow-100'
        }`}
        title="Marquer comme en attente"
      >
        <Clock className="w-5 h-5" />
      </button>

      <button
        onClick={() => onStatusChange(payment.id, 'cancelled')}
        disabled={payment.status === 'cancelled'}
        className={`p-1 rounded-full ${
          payment.status === 'cancelled'
            ? 'text-red-300 cursor-not-allowed'
            : 'text-red-600 hover:bg-red-100'
        }`}
        title="Marquer comme annulé"
      >
        <XCircle className="w-5 h-5" />
      </button>
    </div>
  );
}

export default StatusActions;
