import React from "react";
import { Clock, User, BookOpen } from "lucide-react";
import { format } from "date-fns";
import { fr } from "date-fns/locale";

/**
 * SessionCard
 *
 * Component to display a session with its theme, trainer, and schedule
 *
 * @param {object} session - the session object to display
 *
 * @returns {ReactElement} a React component
 */
export function SessionCard({ session }) {
  return (
    <div className="bg-white rounded-lg shadow-md p-6 mb-4">
      <h3 className="text-xl font-semibold mb-2">{session.title}</h3>
      <div className="space-y-3">
        <div className="flex items-center text-gray-600">
          <BookOpen className="w-5 h-5 mr-2" />
          <span>{session._def.extendedProps.theme}</span>
        </div>
        <div className="flex items-center text-gray-600">
          <User className="w-5 h-5 mr-2" />
          <span>{session._def.extendedProps.trainer}</span>
        </div>
        <div className="flex items-center text-gray-600">
          <Clock className="w-5 h-5 mr-2" />
          <span>
            {format(session?._instance.range.start, "EEEE d MMMM yyyy", {
              locale: fr,
            })}
            <br />
            {format(session?._instance.range.start, "HH:mm")} -{" "}
            {format(session._instance.range.end, "HH:mm")}
          </span>
        </div>
      </div>
    </div>
  );
}
