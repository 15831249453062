// import React from 'react';
// import { CourseFeatures } from './CourseFeatures';

// export function CourseInfo() {
//   return (
//     <div className="bg-gradient-to-br from-blue-600 to-blue-800 p-8 rounded-2xl text-white">
//       <h1 className="text-4xl font-bold mb-4 tracking-tight">
//         Formation en ligne interactive
//       </h1>
//       <p className="text-blue-100 text-lg font-medium leading-relaxed">
//         Une expérience d'apprentissage complète combinant théorie et pratique
//       </p>

//       <CourseFeatures />
//     </div>
//   );
// }
// ---------------------------------------------------------------------------------------
import React from "react";
import { CourseFeatures } from "./CourseFeatures.jsx";

const CourseInfo = () => {
  return (
    <div className="bg-gradient-to-br from-blue-600 to-blue-800 p-8 rounded-2xl text-white">
      <h1 className="text-4xl font-bold mb-4 tracking-tight">
        Formation en ligne interactive
      </h1>
      <p className="text-blue-100 text-lg font-medium leading-relaxed mb-4 ">
        Une expérience d'apprentissage complète combinant théorie et pratique
      </p>

      <CourseFeatures />
    </div>
  );
};

export default CourseInfo;
