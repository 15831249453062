import React from "react";

const FeatureCard = ({ Icon, title, description }) => {
  return (
    <div className="flex items-start space-x-4">
      <div className="bg-blue-100 p-3 rounded-lg">
        <Icon className="text-blue-600" size={24} />
      </div>
      <div>
        <h4 className="font-semibold text-blue-900">{title}</h4>
        <p className="text-blue-700">{description}</p>
      </div>
    </div>
  );
};

export default FeatureCard;
