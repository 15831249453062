import React from "react";

import { CountdownTimer } from "./countdown/CountdownTimer.jsx";
import { RegistrationForm } from "./forms/RegistrationForm.jsx";

import "./style.css";

/**
 * A component that displays the AccessPlus page.
 *
 * It contains a countdown timer, a registration form and a section of testimonials.
 *
 * @returns {JSX.Element} The AccessPlus page component.
 */
export function AccessPlus() {
  return (
    <>
      <div className="AccessPlus min-h-screen bg-navy-950 text-white relative overflow-hidden font-sans">
        <div className="absolute inset-0 bg-gradient-to-r from-navy-900/90 to-primary-900/80 z-10"></div>
        <img
          src="https://images.unsplash.com/photo-1516321318423-f06f85e504b3?auto=format&fit=crop&q=80&w=2070"
          alt="Background"
          className="absolute inset-0 w-full h-full object-cover"
        />

        <div className="relative z-20 max-w-6xl mx-auto px-4 py-20">
          <div className="grid lg:grid-cols-2 gap-12 items-center">
            <div>
              <h1 className="font-heading text-5xl lg:text-6xl font-bold mb-4 tracking-tight leading-tight">
                Access Plus{" "}
                <span className="text-primary-400">pendant une année</span>
              </h1>
              <h2 className="font-heading text-2xl lg:text-3xl mb-8 tracking-tight text-gray-200">
                Réservez tous les thème en fonction de votre domaine
              </h2>
              <CountdownTimer />
            </div>

            <div className="bg-white/5 backdrop-blur-lg rounded-2xl p-8 border border-white/10">
              <RegistrationForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
