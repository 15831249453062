import React from 'react';
import { Edit2, Trash2, UserPlus } from 'lucide-react';

/**
 * A React component that renders a table displaying a list of clients.
 *
 * This component renders a table with the following columns: Name, Email, Phone, Address, and Actions.
 * Each row represents a client, and the Actions column contains two buttons: Edit, and Delete.
 *
 * @param {object[]} clients - An array of client objects. Each object should have the following properties:
 *   - id: A unique identifier for the client.
 *   - name: The name of the client.
 *   - email: The email address of the client.
 *   - phone: The phone number of the client.
 *   - address: The address of the client.
 *
 * @param {function} onEdit - A callback function that is called when the Edit button is clicked.
 *   The function should take a client object as an argument.
 *
 * @param {function} onDelete - A callback function that is called when the Delete button is clicked.
 *   The function should take a client ID as an argument.
 *
 * @param {function} onAddNew - A callback function that is called when the "Nouveau Client" button is clicked.
 *
 * @returns {JSX.Element} The rendered JSX element.
 */
const ClientList = ({ clients, onEdit, onDelete, onAddNew }) => {
  return (
    <div className="bg-white rounded-lg shadow-sm overflow-hidden">
      <div className="p-6 flex justify-between items-center">
        <h2 className="text-xl font-semibold text-gray-800">
          Liste des Clients
        </h2>
        <button
          onClick={onAddNew}
          className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
        >
          <UserPlus className="w-4 h-4" />
          Nouveau Client
        </button>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                Nom compléte
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                Théme de formation
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                Montant
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                Date limite de paiement
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"></th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {clients.map(client => (
              <tr key={client.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {client.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {client.email}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {client.phone}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {client.address}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  <div className="flex space-x-2">
                    <button
                      onClick={() => onEdit(client)}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      <Edit2 className="w-4 h-4" />
                    </button>
                    <button
                      onClick={() => onDelete(client.id)}
                      className="text-red-600 hover:text-red-900"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ClientList;
