import React from "react";
import { Book } from "lucide-react";
import { Link } from "react-router-dom";

export function CourseCard({ title, description, image, duration }) {
  return (
    <Link
      to="/Formation-Dinitiation_Course-Details"
      className="bg-white rounded-lg shadow-md overflow-hidden transition-transform hover:scale-105"
    >
      <div className="relative h-48">
        <img src={image} alt={title} className="w-full h-full object-cover" />
      </div>
      <div className="p-4">
        <h3 className="text-lg font-semibold text-gray-900 mb-2">{title}</h3>
        <p className="text-sm text-gray-600 mb-4">{description}</p>
        <div className="flex items-center text-sky-600">
          <Book className="w-4 h-4 mr-2" />
          <span className="text-sm">{duration} min de lecture</span>
        </div>
      </div>
    </Link>
  );
}
