export const events = [
  {
    id: 1,
    title: "Forum Tech Talents 2024",
    date: "15 Avril 2024",
    time: "9h00 - 18h00",
    location: "Paris Expo Porte de Versailles",
    description:
      "Le plus grand salon de recrutement tech en France. Rencontrez plus de 200 entreprises innovantes.",
    image:
      "https://images.unsplash.com/photo-1540575467063-178a50c2df87?auto=format&fit=crop&w=800&h=400",
  },
  {
    id: 2,
    title: "Job Dating Digital",
    date: "20 Avril 2024",
    time: "14h00 - 20h00",
    location: "Lyon - La Sucrière",
    description:
      "Entretiens express de 15 minutes avec les meilleurs profils du digital.",
    image:
      "https://images.unsplash.com/photo-1515187029135-18ee286d815b?auto=format&fit=crop&w=800&h=400",
  },
  {
    id: 3,
    title: "Startup Meetup",
    date: "5 Mai 2024",
    time: "18h00 - 22h00",
    location: "Station F - Paris",
    description:
      "Networking et recrutement dans l'écosystème startup. Pitchez votre entreprise!",
    image:
      "https://images.unsplash.com/photo-1523580494863-6f3031224c94?auto=format&fit=crop&w=800&h=400",
  },
];
