import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Autocomplete, TextField } from "@mui/material";
import { get_All } from "../../functions/restApi";
import { formation_payant } from "../../db";
import { addPrice } from "../../redux/Slices/cours_payant_Slice";
import FloatingBubbles from "../FloatingBubbles";

import "../../styles/E-Trainings/Course-price/style.css";

/**
 * A functional component that renders a section about the courses' prices.
 * The component fetches the latest exchange rates from the free tier of
 * exchangerate-api.com and stores them in the state. It also fetches the
 * price of the course with the given id from the backend API and stores it
 * in the state. Finally, it renders a list of plan cards with the converted
 * prices in the current currency set in the component state.
 *
 * @returns {React.ReactElement} The rendered React element.
 * @private
 */
const E_Trainings_Course_price = () => {
  const { id } = useParams();
  const [currency, setCurrency] = useState("USD");
  const [rates, setRates] = useState({});
  const [priceUSD, setPriceUSD] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [avantages] = useState({
    premium: [
      "Formation en direct avec instructeur",
      "Attestation de participation",
      "Attestation d'expertise (authentifiée sur notre site web)",
      "Examen QCM et cas pratique",
      "Évaluation (de participation et d'expertise)",
      "Accès aux supports et à l'enregistrement vidéo (mises à jour régulières)",
      "Accès illimité aux ressources documentaires et coaching personnalisé",
      "Autres...",
    ],
    standard: [
      "Vidéos enregistrées",
      "Supports de formation",
      "Accès aux supports et à l'enregistrement vidéo (mises à jour régulières)",
      "Accès illimité aux ressources documentaires et coaching personnalisé",
      "Ressources de base",
      "Forum communautaire",
      "Lettre de recommandation",
      "Profitez d'une réduction unique de 10 % sur toute formation avec n'importe quel plan",
    ],
  });

  /**
   * Fetches the latest exchange rates from the free tier of exchangerate-api.com
   * and stores them in the state.
   *
   * @function
   * @returns {Promise<void>}
   * @private
   */
  const getExchangeRate = async () => {
    try {
      const response = await get_All(
        `https://api.exchangerate-api.com/v4/latest/USD`
      );
      setRates(response.data.rates);
    } catch (error) {
      console.error("Error fetching exchange rate:", error);
    }
  };

  useEffect(() => {
    getExchangeRate();
  }, []);

  /**
   * Fetches the course with the given id from the backend API and updates
   * the state with the course's price.
   *
   * @function
   * @async
   * @param {string} id - The id of the course to fetch.
   * @returns {Promise<void>}
   * @private
   */
  const get_one_cours = async () => {
    try {
      const res = await get_All(
        `https://ma-training-consulting-company-site-backend-one.vercel.app/api/get-one-cours/${id}`
      );

      if (res.data) {
        setPriceUSD(res.data.cours.price);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    get_one_cours();
  }, [id]);

  useEffect(() => {
    const numericId = parseInt(id, 10);
    const filteredCourse = formation_payant.filter(
      (item) => item.id === numericId
    );
    setPriceUSD(filteredCourse[0]?.price);
  }, [id]);

  /**
   * Converts a price in USD to the current currency set in the component state,
   * using the latest exchange rate from exchangerate-api.com.
   *
   * @function
   * @param {number} priceInUSD The price in USD to be converted.
   * @returns {{ price: string, exchangeRate: number }} An object containing the
   *   converted price as a string, and the exchange rate used as a number.
   * @private
   */
  const convertPrice = (priceInUSD) => {
    const exchangeRate = rates[currency] || 1;
    return { price: (priceInUSD * exchangeRate).toFixed(2), exchangeRate };
  };

  /**
   * Handles a change in the selected currency by updating the component state.
   * If newValue is falsy, it defaults to "USD".
   *
   * @function
   * @param {Event} event The change event.
   * @param {string} [newValue] The new currency code.
   * @private
   */
  const handleCurrencyChange = (event, newValue) => {
    setCurrency(newValue || "USD");
  };

  return (
    <>
      <div style={{ marginTop: "80px" }} className="E_Trainings_Course_price">
        <div className="background-animation">
          <FloatingBubbles />
        </div>
        <div className="container">
          <header>
            <h1>Formation Professionnelle</h1>
            <p>Choisissez votre plan de formation idéal</p>
          </header>

          <div className="currency-selector">
            <Autocomplete
              id="currency-autocomplete"
              options={Object.keys(rates)}
              value={currency}
              onChange={handleCurrencyChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choisir la devise"
                  variant="outlined"
                />
              )}
              sx={{ width: "100%" }}
            />
          </div>

          <div className="plans-container">
            <div className="plan-card">
              <div className="plan-header">
                <div className="plan-title">Premium</div>
                <div className="plan-price">
                  {currency} {""}
                  {convertPrice(priceUSD?.Premium).price}
                </div>
              </div>
              <ul className="plan-features">
                {avantages.premium.map((avantage, index) => (
                  <li key={index}> {avantage} </li>
                ))}
              </ul>
              <button
                onClick={() => {
                  dispatch(
                    addPrice({
                      currency,
                      price: convertPrice(priceUSD?.Premium).price,
                      exchangeRate: convertPrice(priceUSD?.Premium)
                        .exchangeRate,
                    })
                  );
                  localStorage.setItem(
                    "participantP2",
                    JSON.stringify({
                      type_de_formation: "Premium",
                      currency,
                      price: Number(convertPrice(priceUSD?.Premium).price),
                      exchangeRate: convertPrice(priceUSD?.Premium)
                        .exchangeRate,
                    })
                  );
                  navigate("/Course-Checkout");
                }}
                className="plan-button"
              >
                Choisir ce plan
              </button>
            </div>

            <div className="plan-card">
              <div className="plan-header">
                <div className="plan-title">Standard</div>
                <div className="plan-price">
                  {currency} {convertPrice(priceUSD?.Standard).price}
                </div>
              </div>
              <ul className="plan-features">
                {avantages.standard.map((avantage, index) => (
                  <li key={index}> {avantage} </li>
                ))}
              </ul>
              <button
                onClick={() => {
                  dispatch(
                    addPrice({
                      currency,
                      price: convertPrice(priceUSD?.Standard).price,
                      exchangeRate: convertPrice(priceUSD?.Standard)
                        .exchangeRate,
                    })
                  );
                  localStorage.setItem(
                    "participantP2",
                    JSON.stringify({
                      type_de_formation: "Standard",
                      currency,
                      price: Number(convertPrice(priceUSD?.Standard).price),
                      exchangeRate: convertPrice(priceUSD?.Standard)
                        .exchangeRate,
                    })
                  );
                  navigate("/Course-Checkout");
                }}
                className="plan-button"
              >
                Choisir ce plan
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default E_Trainings_Course_price;
