import React, { useState } from "react";
import { Send, AlertCircle } from "lucide-react";

import { useNavigate } from "react-router-dom";

/**
 * Form for pre-registering for a course.
 *
 * @param {string} id - The id of the course to pre-register for.
 * @returns {JSX.Element} The form component.
 */
const RegistrationForm = ({ id }) => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    company: "",
    session: "janvier2025",
  });

  const navigate = useNavigate();

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  /**
   * Validate the form data.
   *
   * This method checks that the name, email, phone, and company fields are not empty.
   * It also checks that the email is in a valid format.
   *
   * @returns {boolean} True if the form is valid, false otherwise.
   */
  const validateForm = () => {
    const newErrors = {};

    if (!formData.fullName.trim()) {
      newErrors.fullName = "Le nom est requis";
    }

    if (!formData.email.trim()) {
      newErrors.email = "L'email est requis";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "L'email n'est pas valide";
    }

    if (!formData.phone.trim()) {
      newErrors.phone = "Le numéro de téléphone est requis";
    }

    if (!formData.company.trim()) {
      newErrors.company = "Le nom de l'entreprise est requis";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  /**
   * handleSubmit
   *
   * Handles the form submission process by preventing the default form action,
   * validating the form data, setting the submitting state, and simulating a
   * submission delay. If the form is valid, it saves the form data to local
   * storage, resets the form fields, and navigates to the course price page.
   * Logs any errors encountered during submission.
   *
   * @param {Object} e - The event object from the form submission event.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setIsSubmitting(true);

    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setSubmitSuccess(true);
      localStorage.setItem("participantP1", JSON.stringify(formData));
      setFormData({
        fullName: "",
        email: "",
        phone: "",
        company: "",
        session: "janvier2025",
      });
      navigate(`/Course-price/${id}`);
    } catch (error) {
      console.error("Erreur lors de la soumission:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  return (
    <div className="py-16 bg-white">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900">Pré-Inscription</h2>
          <p className="mt-4 text-lg text-gray-600">
            Pour en savoir plus sur les avantages que vous pourrez obtenir
            pendant et après la formation, veuillez remplir le formulaire
            ci-dessous.
          </p>
        </div>

        {submitSuccess && (
          <div className="mb-8 bg-green-50 border border-green-200 rounded-lg p-4">
            <p className="text-green-700 text-center">
              Votre pré-inscription a été enregistrée avec succès ! Nous vous
              contacterons bientôt.
            </p>
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="bg-white shadow-sm rounded-lg p-8 border border-gray-200">
            <div className="space-y-6">
              {/* Nom complet */}
              <div>
                <label
                  htmlFor="fullName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Nom complet
                </label>
                <div className="mt-1">
                  <input
                    style={{
                      padding: "12px",
                    }}
                    type="text"
                    name="fullName"
                    id="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    className={`block w-full rounded-md ${
                      errors.fullName ? "border-red-300" : "border-gray-300"
                    } shadow-sm focus:border-blue-500 focus:ring-blue-500`}
                  />
                  {errors.fullName && (
                    <p className="mt-1 text-sm text-red-600 flex items-center">
                      <AlertCircle className="w-4 h-4 mr-1" />
                      {errors.fullName}
                    </p>
                  )}
                </div>
              </div>

              {/* Email */}
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email professionnel
                </label>
                <div className="mt-1">
                  <input
                    style={{
                      padding: "12px",
                    }}
                    type="email"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    className={`block w-full rounded-md ${
                      errors.email ? "border-red-300" : "border-gray-300"
                    } shadow-sm focus:border-blue-500 focus:ring-blue-500`}
                  />
                  {errors.email && (
                    <p className="mt-1 text-sm text-red-600 flex items-center">
                      <AlertCircle className="w-4 h-4 mr-1" />
                      {errors.email}
                    </p>
                  )}
                </div>
              </div>

              {/* Téléphone */}
              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  Numéro de téléphone
                </label>
                <div className="mt-1">
                  <input
                    style={{
                      padding: "12px",
                    }}
                    type="tel"
                    name="phone"
                    id="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className={`block w-full rounded-md ${
                      errors.phone ? "border-red-300" : "border-gray-300"
                    } shadow-sm focus:border-blue-500 focus:ring-blue-500`}
                  />
                  {errors.phone && (
                    <p className="mt-1 text-sm text-red-600 flex items-center">
                      <AlertCircle className="w-4 h-4 mr-1" />
                      {errors.phone}
                    </p>
                  )}
                </div>
              </div>

              {/* Entreprise */}
              <div>
                <label
                  htmlFor="company"
                  className="block text-sm font-medium text-gray-700"
                >
                  Entreprise
                </label>
                <div className="mt-1">
                  <input
                    style={{
                      padding: "12px",
                    }}
                    type="text"
                    name="company"
                    id="company"
                    value={formData.company}
                    onChange={handleChange}
                    className={`block w-full rounded-md ${
                      errors.company ? "border-red-300" : "border-gray-300"
                    } shadow-sm focus:border-blue-500 focus:ring-blue-500`}
                  />
                  {errors.company && (
                    <p className="mt-1 text-sm text-red-600 flex items-center">
                      <AlertCircle className="w-4 h-4 mr-1" />
                      {errors.company}
                    </p>
                  )}
                </div>
              </div>

              {/* Session */}
              <div>
                <label
                  htmlFor="session"
                  className="block text-sm font-medium text-gray-700"
                >
                  Session souhaitée
                </label>
                <select
                  style={{
                    padding: "12px",
                  }}
                  id="session"
                  name="session"
                  value={formData.session}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 mb-3"
                >
                  <option value="janvier2025">Session Janvier 2025</option>
                  <option value="avril2025">Session Avril 2025</option>
                </select>
              </div>
            </div>

            <div className="mt-8">
              <button
                type="submit"
                disabled={isSubmitting}
                className={`w-full flex justify-center items-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                  isSubmitting ? "opacity-75 cursor-not-allowed" : ""
                }`}
              >
                <Send className="w-4 h-4 mr-2" />
                {isSubmitting
                  ? "Envoi en cours..."
                  : "Envoyer la pré-inscription"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegistrationForm;
