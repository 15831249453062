import React from 'react';

const statusConfig = {
  completed: {
    label: 'Complété',
    className: 'bg-green-100 text-green-800',
  },
  pending: {
    label: 'En attente',
    className: 'bg-yellow-100 text-yellow-800',
  },
  cancelled: {
    label: 'Annulé',
    className: 'bg-red-100 text-red-800',
  },
};

/**
 * Displays a badge indicating the status of a request.
 *
 * @param {object} props
 * @prop {string} status - The status of the request. One of "completed", "pending", or "cancelled".
 * @returns {React.ReactElement} A <span> element with a class name indicating the status.
 */
function StatusBadge({ status }) {
  const config = statusConfig[status];

  return (
    <span
      className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${config.className}`}
    >
      {config.label}
    </span>
  );
}

export default StatusBadge;
