import { Box } from "@mui/material";
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { Trophy, Users, Gift } from "lucide-react";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";

import { canRedeemForFreeClient } from "./pointsCalculator";
import Add_Client from "../add_client/Add_Client";
import { DeadlineList } from "../add_client/DeadlineList";

import "./style.css";

/**
 * A React component that renders a section of a website dedicated to a seller's dashboard.
 *
 * Displays the seller's current points, client count, referral code, and a button to redeem points
 * for a free client. Also displays a list of instructions on how to earn points.
 *
 * @param {{ points: number, clientCount: number, referralCode: string }} seller
 * @param {function(): void} onRedeemPoints
 * @returns {JSX.Element} The rendered JSX element.
 */
const SellerDashboard = ({ seller, onRedeemPoints }) => {
  const canRedeem = canRedeemForFreeClient(seller.points);

  const [openAddClient, setOpenAddClient] = React.useState(false);
  const [openDeadlineList, setOpenDeadlineList] = React.useState(false);

  /**
   * Handles the user clicking on the "Redeem Points" button
   *
   * Sets the openAdd_Client state to true, which will openAdd_Client the dialog to add a new client
   */
  const handleClickOpen = () => {
    setOpenAddClient(true);
  };

  /**
   * Handles the user closing the dialog to add a new client
   *
   * Sets the openAdd_Client state to false, which will close the dialog
   */
  const handleClose = () => {
    setOpenAddClient(false);
  };

  /**
   * Opens the deadline list dialog.
   *
   * Sets the openDeadlineList state to true, which will open the dialog
   * displaying the list of deadlines.
   */
  const handleClickOpenDeadlineList = () => {
    setOpenDeadlineList(true);
  };

  /**
   * Closes the deadline list dialog.
   *
   * Sets the openDeadlineList state to false, which will close the dialog
   * displaying the list of deadlines.
   */
  const handleCloseDeadlineList = () => {
    setOpenDeadlineList(false);
  };

  return (
    <>
      <div className="Seller_Dashboard mx-auto p-6">
        <div className="bg-white rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-8">
            <h1 className="text-2xl font-bold text-gray-800">
              Seller Dashboard
            </h1>
            <div className="flex items-center space-x-2">
              <Trophy className="text-yellow-500" />
              <span className="text-lg font-semibold">
                {seller.points} Points
              </span>
            </div>
          </div>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                md: "repeat(3, minmax(0, 1fr))",
                xs: "repeat(1, minmax(0, 1fr))",
              },
              gap: "1rem",
            }}
          >
            <div className=" bg-purple-50 p-4 rounded-lg">
              <div className="flex items-center space-x-2 mb-2">
                <Gift className="text-purple-600" />
                <h2 className="text-lg font-semibold text-purple-800">
                  Code de Parrainage / ID commercial
                </h2>
              </div>
              <p className="text-xl font-mono text-purple-900">
                {seller.referralCode}
              </p>
            </div>

            <div className="bg-blue-50 p-4 rounded-lg">
              <div className="flex items-center space-x-2 mb-2">
                <Users className="text-blue-600" />
                <h2 className="text-lg font-semibold text-blue-800">
                  Les Clients
                </h2>
              </div>
              <p className="text-2xl font-bold text-blue-900">
                {seller.clientCount}
              </p>
            </div>

            <div className="bg-green-50 p-4 rounded-lg">
              <div className="flex flex-col">
                <div className="d-flex align-items-center justify-content-between mb-2 ">
                  <h2 className="text-lg font-semibold text-green-800 ">
                    Récompense Client Gratuite
                  </h2>
                  <IconButton onClick={handleClickOpenDeadlineList}>
                    <AddIcon />
                  </IconButton>
                </div>

                <button
                  onClick={handleClickOpen}
                  disabled={!canRedeem}
                  className={`px-4 py-2 rounded-md text-white ${
                    canRedeem
                      ? "bg-green-600 hover:bg-green-700"
                      : "bg-gray-400 cursor-not-allowed"
                  }`}
                >
                  Redeem 50 Points
                </button>

                {!canRedeem && (
                  <p className="text-sm text-gray-600 mt-2">
                    Need {50 - seller.points} more points
                  </p>
                )}
              </div>
            </div>
          </Box>

          <div className="bg-gray-50 p-4 rounded-lg mt-3 ">
            <ul className="space-y-2 text-gray-700">
              <li
                style={{
                  listStyle: "outside disc none",
                }}
              >
                <h5>
                  Programme de Récompense - Comment Gagner des PointsCode de
                  Promotion :
                </h5>{" "}
                <p>
                  Votre IDUtilisez votre ID unique comme code de promotion pour
                  commencer à accumuler des points.
                </p>{" "}
              </li>
              <li
                style={{
                  listStyle: "outside disc none",
                }}
              >
                <h5>Gagnez des points facilement:</h5>{" "}
                <p>
                  5 points pour chaque client ayant effectué un paiement.
                  Lorsque vous atteignez 50 points (soit 10 clients ayant
                  finalisé leur paiement), vous recevez une place gratuite pour
                  participer à une formation.
                </p>{" "}
              </li>
              <li
                style={{
                  listStyle: "outside disc none",
                }}
              >
                <h5>Avantages exclusifs :</h5>{" "}
                <p>
                  La place gratuite peut également être revendue, vous offrant
                  une opportunité supplémentaire de profit. En réclamant vos 50
                  points, non seulement vous bénéficiez d'une place gratuite,
                  mais vous obtenez aussi la possibilité de participer
                  personnellement à une formation.
                </p>{" "}
              </li>
              <li
                style={{
                  listStyle: "outside disc none",
                }}
              >
                <h5>C'est simple:</h5>{" "}
                <p>Plus vous référez de clients, plus vous gagnez!</p>{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Dialog
        open={openAddClient}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            width: "80%",
            maxWidth: "none",
            background: "rgb(243 244 246)",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {
            "Choisissez une session de formation (par mois) dans laquelle vous souhaitez ajouter une personne gratuitement. Une fois cette place gratuite attribuée ou revendue, elle ne sera plus disponible."
          }
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{
            padding: "0px",
          }}
        >
          <DialogContentText id="alert-dialog-description">
            <Add_Client
              onRedeemPoints={onRedeemPoints}
              handleClose={handleClose}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openDeadlineList}
        onClose={handleCloseDeadlineList}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            width: { xs: "80%", md: "50%" },
            maxWidth: "none",
            background: "rgb(243 244 246)",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {"veuillez renseigner les informations du client bénéficiaire."}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDeadlineList}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{
            padding: "0px",
          }}
        >
          <DialogContentText id="alert-dialog-description">
            <DeadlineList />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SellerDashboard;
