import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import IconButton from '@mui/material/IconButton';

import { isOverdue, isUpcoming } from './dateUtils.js';
import { useDeadlineStore } from './deadlineStore.js';

/**
 * Renders a single deadline component with the ability to add a client to it.
 *
 * @param deadline - The deadline object to display.
 *
 * @returns {ReactElement} A React component displaying the deadline and the
 * ability to add a client to it.
 */
const DeadlineComponent = ({ deadline }) => {
  const updateDeadline = useDeadlineStore(state => state.updateDeadline);
  const [formShow, setFormShow] = useState(false);
  const [client, setClient] = useState({
    fullName: '',
    email: '',
    whatsapp: '',
  });

  const handleChangeClient = e => {
    const { name, value } = e.target;
    setClient(prevClient => ({
      ...prevClient,
      [name]: value,
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    updateDeadline(deadline?.id, { client });
  };

  const handlePhoneChange = e => {
    const { name, value } = e.target;

    if (!/^\d*$/.test(value)) {
      setClient(prevClient => ({
        ...prevClient,
        [name]: value.replace(/\D/g, ''),
      }));
    } else {
      setClient(prevClient => ({
        ...prevClient,
        [name]: value,
      }));
    }
  };

  return (
    <div
      key={deadline?.id}
      className={`p-4 rounded-lg shadow-md mb-3 ${
        isOverdue(deadline?.dueDate)
          ? 'bg-red-50 border-red-200'
          : isUpcoming(deadline?.dueDate)
            ? 'bg-yellow-50 border-yellow-200'
            : 'bg-white border-gray-200'
      } border`}
    >
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-semibold">{deadline?.serviceType}</h3>

        <IconButton onClick={() => setFormShow(!formShow)}>
          <AddIcon />
        </IconButton>
      </div>

      <div
        style={{
          display: formShow ? 'block' : 'none',
          transition: 'all 0.3s ease-in-out',
        }}
      >
        {deadline?.client ? (
          <div className="flex items-center justify-between">
            <div className="mt-2 text-sm text-gray-600">
              <p>Nom & prénom: {deadline?.client.fullName}</p>
              <p>Email: {deadline?.client.email}</p>
              <p>Whatsapp: {deadline?.client.whatsapp}</p>
            </div>
          </div>
        ) : (
          <form
            onSubmit={handleSubmit}
            style={{
              padding: '1.5rem',
              background: 'white',
              borderRadius: '0.75rem',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
          >
            <div style={{ marginBottom: '1rem' }}>
              <label
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: '#4b5563',
                  marginBottom: '0.5rem',
                }}
              >
                <PersonOutlineIcon
                  style={{
                    width: '1.25rem',
                    height: '1.25rem',
                    marginRight: '0.5rem',
                  }}
                />
                Nom & prénom
              </label>
              <input
                type="text"
                name="fullName"
                value={client.fullName}
                onChange={handleChangeClient}
                style={{
                  width: '100%',
                  padding: '0.5rem',
                  border: '1px solid #d1d5db',
                  borderRadius: '0.375rem',
                  outline: 'none',
                  transition: 'box-shadow 0.2s',
                }}
                required
              />
            </div>

            <div style={{ marginBottom: '1rem' }}>
              <label
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: '#4b5563',
                  marginBottom: '0.5rem',
                }}
              >
                <AlternateEmailIcon
                  style={{
                    width: '1.25rem',
                    height: '1.25rem',
                    marginRight: '0.5rem',
                  }}
                />
                email
              </label>
              <input
                type="email"
                name="email"
                value={client.email}
                onChange={handleChangeClient}
                style={{
                  width: '100%',
                  padding: '0.5rem',
                  border: '1px solid #d1d5db',
                  borderRadius: '0.375rem',
                  outline: 'none',
                  transition: 'box-shadow 0.2s',
                }}
                required
              />
            </div>

            <div style={{ marginBottom: '1.5rem' }}>
              <label
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: '#4b5563',
                  marginBottom: '0.5rem',
                }}
              >
                <WhatsAppIcon
                  style={{
                    width: '1.25rem',
                    height: '1.25rem',
                    marginRight: '0.5rem',
                  }}
                />
                whatsapp
              </label>
              <input
                type="tel"
                name="whatsapp"
                value={client.whatsapp}
                onChange={handlePhoneChange}
                style={{
                  width: '100%',
                  padding: '0.5rem',
                  border: '1px solid #d1d5db',
                  borderRadius: '0.375rem',
                  outline: 'none',
                  transition: 'box-shadow 0.2s',
                }}
                required
              />
            </div>

            <button
              type="submit"
              style={{
                width: '100%',
                backgroundColor: '#2563eb',
                color: 'white',
                padding: '0.5rem 1rem',
                borderRadius: '0.375rem',
                textAlign: 'center',
                fontWeight: 'bold',
                transition: 'background-color 0.2s',
                border: 'none',
                cursor: 'pointer',
              }}
              onMouseOver={e => (e.target.style.backgroundColor = '#1d4ed8')}
              onMouseOut={e => (e.target.style.backgroundColor = '#2563eb')}
            >
              Ajouter
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default DeadlineComponent;
