import React from "react";
import { Link } from "react-router-dom";
import { scrollToSection } from "../../functions/functions";

export function HeroCard({
  icon: Icon,
  title,
  description,
  buttonText,
  bgColor,
  path,
}) {
  return (
    <div
      className={`${bgColor} rounded-2xl p-8 text-white group hover:scale-[1.02] transition-all duration-300`}
    >
      {/* Icon */}
      <div className="mb-6">
        <Icon className="w-12 h-12 text-primary-400" />
      </div>

      {/* Title */}
      <h3 className="font-heading text-xl font-semibold mb-4">{title}</h3>

      {/* Description */}
      <p className="text-gray-300 text-sm leading-relaxed mb-8">
        {description}
      </p>

      {/* Button */}
      {path ? (
        <Link
          to={path}
          className="inline-flex items-center font-heading text-sm font-semibold text-primary-400 hover:text-primary-300 transition-colors"
        >
          {buttonText}
          <svg
            className="w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </Link>
      ) : (
        <button
          onClick={() => scrollToSection("coursPayant")}
          className="inline-flex items-center font-heading text-sm font-semibold text-primary-400 hover:text-primary-300 transition-colors"
        >
          {buttonText}
          <svg
            className="w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
      )}
    </div>
  );
}
