import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import "../../styles/E-Trainings/Course-Checkout/style.css";

/**
 * This component is used to generate the course checkout page.
 *
 * This component shows the user the payment methods available for the course.
 * It also shows the user the total cost of the course, the tax, and the total
 * amount to be paid.
 *
 * The user can select the payment method they want to use and the number of
 * tranches they want to pay in.
 *
 * The component also shows the user the payment info and the order summary.
 *
 * The user can click on the "Procéder au paiement" button to proceed to the
 * payment page.
 *
 * @param {Object} props The component props.
 */
const E_Trainings_Checkout = () => {
  const [paymentSelected, setPaymentSelected] = useState("");
  const [tranchesSelected, setTranchesSelected] = useState("");
  const [frais, setFrais] = useState(0);
  const navigate = useNavigate();
  let [priceObject, setPriceObject] = useState({});

  const { currency, price, exchangeRate } = priceObject;

  const [formData, setFormData] = useState({
    date_de_session: "",
    date_de_paiement: new Date(),
    code_de_reduction: "",
    mode_de_paiement: "",
    possibilite_de_paiement: "",
    total_de_prix: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const isWeekday = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const [month, dayOfMonth, year] = formData.date_de_session
      .split("/")
      .map(Number);
    const referenceDate = new Date(year, month - 1, dayOfMonth);

    const threeDaysBefore = new Date(referenceDate);
    threeDaysBefore.setDate(referenceDate.getDate() - 3);

    return (
      date <= threeDaysBefore &&
      date <= referenceDate &&
      date >= today &&
      date.getDay() !== 0 &&
      date.getDay() !== 6
    );
  };

  useEffect(() => {
    const participantDataP1 = localStorage.getItem("participantP1");
    const participantDataP2 = localStorage.getItem("participantP2");
    if (participantDataP1) {
      try {
        const parsedDataP1 = JSON.parse(participantDataP1);
        setFormData((prevState) => ({
          ...prevState,
          ...parsedDataP1,
        }));
      } catch (error) {
        console.error("Error parsing participant data:", error);
      }
    }
    if (participantDataP2) {
      try {
        const parsedDataP2 = JSON.parse(participantDataP2);
        setPriceObject(parsedDataP2);
      } catch (error) {
        console.error("Error parsing participant data:", error);
      }
    }
  }, []);

  useEffect(() => {
    console.log("formData", formData);
  }, [formData]);

  const refs = {
    mandat: useRef(null),
    virement: useRef(null),
    crypto: useRef(null),
    paypal: useRef(null),
    d17: useRef(null),
    bank: useRef(null),
  };

  /**
   * Handles the click event on a payment method radio button.
   *
   * This function sets the checked state of all the payment method radio
   * buttons to false, and then sets the checked state of the radio button
   * that was clicked to true.
   *
   * @param {String} method The key of the payment method radio button that
   * was clicked.
   */
  const handlePaymentMethodClick = (method) => {
    Object.keys(refs).forEach((key) => {
      if (refs[key].current) refs[key].current.checked = key === method;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setFormData((prevState) => ({
      ...prevState,
      total_de_prix: (Number(price) + frais * exchangeRate).toFixed(2),
    }));

    console.log("formData", formData);

    navigate("/Course-groups");
  };

  return (
    <div
      style={{
        marginTop: "80px",
      }}
      className="Course-Checkout"
    >
      <div className="container">
        <header className="page-header">
          <h1>Modalités de paiement</h1>
          <p>Découvrez les informations de paiement</p>
        </header>

        <form onSubmit={handleSubmit} className="checkout-container">
          <div className="checkout-form">
            <div className="form-section">
              <h3
                style={{
                  textTransform: "uppercase",
                  fontWeight: "bold",
                }}
                className="form-section-title"
              >
                Informations
              </h3>

              <div>
                <div className="form-group">
                  <label htmlFor="session-date">Date de session</label>
                  <select
                    name="date_de_session"
                    onChange={handleChange}
                    id="session-date"
                    required
                    value={formData.date_de_session}
                  >
                    <option value>Sélectionnez une date...</option>
                    <option value="01/10/2025">
                      Session 1: 01/10/2025 - 01/20/2025
                    </option>
                    <option value="01/20/2025">
                      Session 2: 01/20/2025 - 02/07/2025
                    </option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="payment-date">Date de paiement</label>
                  {/* <input type="date" id="payment-date" required /> */}
                  <DatePicker
                    className="w-100"
                    name="date_de_paiement"
                    selected={formData.date_de_paiement}
                    onChange={(date) =>
                      setFormData({ ...formData, date_de_paiement: date })
                    }
                    filterDate={isWeekday}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="coupon">Code de réduction</label>
                  <input
                    type="text"
                    id="coupon"
                    placeholder="Entrez votre code de réduction"
                  />
                </div>
              </div>
            </div>
            <div className="form-section">
              <h3 className="form-section-title">Mode de paiement</h3>
              <div className="payment-methods">
                <div
                  onClick={() => {
                    handlePaymentMethodClick("mandat");
                    setPaymentSelected("mandat");
                    setFrais(5);
                  }}
                  className={`payment-method  ${
                    paymentSelected === "mandat" ? "selected" : ""
                  } `}
                >
                  <input
                    ref={refs.mandat}
                    type="radio"
                    name="payment"
                    defaultValue="mandat"
                    className="payment-method-radio"
                    required
                  />
                  <div className="payment-method-header">
                    <div className="payment-method-icon">
                      <i className="fas fa-exchange-alt" />
                    </div>
                    <div>
                      <div className="payment-method-title">
                        Mandat International
                      </div>
                      <div className="payment-method-description">
                        Transfert d'argent rapide et sécurisé
                      </div>
                    </div>
                  </div>
                  <div className="payment-providers">
                    <span className="provider-badge">Western Union</span>
                    <span className="provider-badge">Money-Gram</span>
                    <span className="provider-badge">RIA</span>
                  </div>
                </div>
                <div
                  onClick={() => {
                    handlePaymentMethodClick("virement");
                    setPaymentSelected("virement");
                    setFrais(5);
                  }}
                  className={`payment-method ${
                    paymentSelected === "virement" ? "selected" : ""
                  } `}
                >
                  <input
                    ref={refs.virement}
                    type="radio"
                    name="payment"
                    defaultValue="virement"
                    className="payment-method-radio"
                  />
                  <div className="payment-method-header">
                    <div className="payment-method-icon">
                      <i className="fas fa-globe" />
                    </div>
                    <div>
                      <div className="payment-method-title">
                        Virement International
                      </div>
                      <div className="payment-method-description">
                        Solutions de transfert internationales
                      </div>
                    </div>
                  </div>
                  <div className="payment-providers">
                    <span className="provider-badge">Myfin</span>
                    <span className="provider-badge">Redotpay</span>
                    <span className="provider-badge">Wise</span>
                  </div>
                </div>
                <div
                  onClick={() => {
                    handlePaymentMethodClick("crypto");
                    setPaymentSelected("crypto");
                    setFrais(5);
                  }}
                  className={`payment-method  ${
                    paymentSelected === "crypto" ? "selected" : ""
                  } `}
                >
                  <input
                    ref={refs.crypto}
                    type="radio"
                    name="payment"
                    defaultValue="crypto"
                    className="payment-method-radio"
                  />
                  <div className="payment-method-header">
                    <div className="payment-method-icon">
                      <i className="fab fa-bitcoin" />
                    </div>
                    <div>
                      <div className="payment-method-title">Binance</div>
                      <div className="payment-method-description">
                        Plateforme mondiale crypto
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => {
                    handlePaymentMethodClick("paypal");
                    setPaymentSelected("paypal");
                    setFrais(5);
                  }}
                  className={`payment-method  ${
                    paymentSelected === "paypal" ? "selected" : ""
                  } `}
                >
                  <input
                    ref={refs.paypal}
                    type="radio"
                    name="payment"
                    defaultValue="paypal"
                    className="payment-method-radio"
                  />
                  <div className="payment-method-header">
                    <div className="payment-method-icon">
                      <i className="fab fa-paypal" />
                    </div>
                    <div>
                      <div className="payment-method-title">PayPal</div>
                      <div className="payment-method-description">
                        Paiement en ligne sécurisé
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => {
                    handlePaymentMethodClick("d17");
                    setPaymentSelected("d17");
                    setFrais(5);
                  }}
                  className={`payment-method  ${
                    paymentSelected === "d17" ? "selected" : ""
                  } `}
                >
                  <input
                    ref={refs.d17}
                    type="radio"
                    name="payment"
                    defaultValue="d17"
                    className="payment-method-radio"
                  />
                  <div className="payment-method-header">
                    <div className="payment-method-icon">
                      <i className="fas fa-mail-bulk" />
                    </div>
                    <div>
                      <div className="payment-method-title">
                        D17 (Poste Tunisienne)
                      </div>
                      <div className="payment-method-description">
                        Service postal tunisien
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => {
                    handlePaymentMethodClick("bank");
                    setPaymentSelected("bank");
                    setFrais(5);
                  }}
                  className={`payment-method  ${
                    paymentSelected === "bank" ? "selected" : ""
                  } `}
                >
                  <input
                    ref={refs.bank}
                    type="radio"
                    name="payment"
                    defaultValue="bank"
                    className="payment-method-radio"
                  />
                  <div className="payment-method-header">
                    <div className="payment-method-icon">
                      <i className="fas fa-university" />
                    </div>
                    <div>
                      <div className="payment-method-title">Zitouna Bank</div>
                      <div className="payment-method-description">
                        Virement bancaire direct
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="order-summary">
            <div>
              <div className="payment-info">
                <h3>Répartition Coûts de Formation</h3>
                <p>
                  Vous pouvez payer en deux fois- Tranches La première tranche
                  représente 50% du montant total Les frais de transaction ne
                  sont pas calculés lors du paiement (les frais ici sont à votre
                  charge)
                </p>
              </div>

              <div className="payment-options">
                <label
                  onClick={() => {
                    setTranchesSelected("deux-tranches");
                    setFrais(10);
                  }}
                  className={`payment-option ${
                    tranchesSelected === "deux-tranches" ? "selected" : ""
                  } `}
                >
                  <input
                    type="radio"
                    name="payment-type"
                    defaultValue="deux-tranches"
                  />
                  <span>Deux tranches</span>
                  <i className="fas fa-info-circle info-icon" />
                </label>
                <label
                  onClick={() => {
                    setTranchesSelected("montant-total");
                    setFrais(0);
                  }}
                  className={`payment-option ${
                    tranchesSelected === "montant-total" ? "selected" : ""
                  } `}
                >
                  <input
                    type="radio"
                    name="payment-type"
                    defaultValue="montant-total"
                  />
                  <span>Montant Total</span>
                  <i className="fas fa-info-circle info-icon" />
                </label>
              </div>
            </div>

            <h3 className="form-section-title">Récapitulatif de la commande</h3>
            <div className="summary-item">
              <span>Sous-total</span>
              <span>
                {price} {currency}
              </span>
            </div>
            <div className="summary-item">
              <span>Frais de service</span>
              <span>
                {frais * exchangeRate} {currency}{" "}
              </span>
            </div>
            <div className="summary-item">
              <span>TVA</span>
              <span>0 {currency}</span>
            </div>
            <div className="summary-item summary-total">
              <span>Total ( HT )</span>
              <span>
                {(Number(price) + frais * exchangeRate).toFixed(2)} {currency}
              </span>
            </div>
            <div className="secure-badge">
              <i className="fas fa-lock" />
              <span>
                Détails de paiement sécurisés - Discutez avec notre équipe sur
                WhatsApp
              </span>
            </div>

            <button type="submit" className="btn">
              Procéder au paiement
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default E_Trainings_Checkout;
