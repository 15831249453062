import React, { useEffect, useState } from "react";
import { ChevronDown } from "lucide-react";
import { Link } from "react-router-dom";

/**
 * NavDropdown component
 *
 * This component renders a navigation dropdown menu item that can contain a
 * title, subtitle, and potentially a sub-menu with nested links. It highlights
 * the active menu item and manages hover states to show visual cues such as
 * an underline and a dropdown icon.
 *
 * Props:
 * - title (string): The title of the dropdown item.
 * - subtitle (string): The subtitle of the dropdown item.
 * - href (string): The URL path for the dropdown item.
 * - sub_menu (array): An optional array of sub-menu items.
 * - isActive (string): The current active path.
 * - setIsActive (function): A function to update the current active path.
 *
 * State:
 * - isHovered (boolean): Tracks whether the dropdown is hovered.
 *
 * @returns {JSX.Element} A navigation dropdown menu item.
 */
export function NavDropdown({
  title,
  subtitle,
  href,
  sub_menu,
  isActive,
  setIsActive,
}) {
  const [isHovered, setIsHovered] = useState(false);

  /**
   * Determines the y-position based on the given position value.
   *
   * @param {number} position - The position identifier (1 or 2).
   * @returns {string} The corresponding y-position in pixels as a string.
   */
  const handlePosition_y = (position) => {
    if (position === 1) {
      return "-2px";
    } else if (position === 2) {
      return "42px";
    }
  };

  return (
    <span
      className="group relative px-3 py-2"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      // onClick={() => setIsActive(href)}
    >
      <Link to={href}>
        <div className="flex items-center gap-1">
          <div>
            <div
              className={`text-sm font-medium ${
                isActive === href ? "text-blue-600" : "text-gray-900"
              } group-hover:text-blue-600 transition-colors`}
            >
              {title}
            </div>
            <div
              className={`text-xs  ${
                isActive === href ? "text-blue-600" : "text-gray-900"
              } group-hover:text-blue-500 transition-colors`}
            >
              {subtitle}
            </div>
          </div>
          {sub_menu?.length > 0 && (
            <ChevronDown
              className={`w-4 h-4  ${
                isActive === href ? "text-blue-600" : "text-gray-400"
              } group-hover:text-blue-500 transition-colors`}
            />
          )}
        </div>

        {(isHovered || isActive === href) && (
          <span className="absolute bottom-0 left-0 w-full h-0.5 bg-blue-600 rounded-full" />
        )}
      </Link>
      {sub_menu?.length > 0 && (
        <div
          style={{
            overflow: "visible",
          }}
          className="sub-menu"
        >
          <ul className="w-100 ">
            {sub_menu.map((sub, index) => (
              <li key={index} className="cursor-pointer">
                <a> {sub.title} </a>
                {sub.menu?.length > 0 && (
                  <div
                    style={{
                      top: handlePosition_y(sub.position_y),
                      right: "-242px",
                    }}
                    className="lvl2"
                  >
                    <ul className="w-100 ">
                      <li>
                        {sub.menu.map((item, index) => (
                          <Link key={index} to={item.path}>
                            {" "}
                            {item.title}{" "}
                          </Link>
                        ))}
                      </li>
                    </ul>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </span>
  );
}
