import React, { useState } from "react";
import { PlusCircle } from "lucide-react";

import { calculerCommission } from "./commissions";
import { validateWhatsApp, STATUT } from ".";

/**
 * Formulaire pour ajouter un participant
 *
 * @param {{ onAjouterParticipant: (participant: { id: string, nom: string, prenom: string, whatsapp: string, nombreParticipations: number, dateInscription: Date, commissionId: string, statut: STATUT }) => void }}
 */
export function ParticipantForm({ onAjouterParticipant }) {
  const [formData, setFormData] = useState({
    nom_Et_Prenom: "",
    email: "",
    whatsapp: "",
    ajoute_par: "administrateur d'événementiel",
  });

  const [error, setError] = useState("");

  const handleChangeDate = (e) => {
    const { name, value } = e.target;
    setFormData((pravState) => ({
      ...pravState,
      [name]: value,
    }));
  };

  /**
   * Handles the form submission event by preventing the default form action and
   * calling the `onAjouterParticipant` function with the form data.
   *
   * @param {Event} e - The form submission event.
   */
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateWhatsApp(formData.whatsapp)) {
      setError("Numéro WhatsApp invalide");
      return;
    }

    const commission = calculerCommission(formData.nombreParticipations);

    const newParticipant = {
      id: crypto.randomUUID(),
      ...formData,
      dateInscription: new Date(),
      commissionId: commission.id,
      statut: STATUT.EN_ATTENTE,
    };

    onAjouterParticipant(newParticipant);
    setFormData({ nom: "", prenom: "", whatsapp: "" });
    setError("");
  };

  return (
    <form
      style={{
        borderColor: "rgba(37, 211, 102, 1)",
      }}
      onSubmit={handleSubmit}
      className="col-12 col-md-5 d-flex flex-column gap-4 bg-white p-4 rounded shadow  border-4 border-l-4 border-\[\#25D366\] rounded-lg"
    >
      <div className="flex items-center gap-2 mb-6">
        <i
          style={{
            color: "rgb(37 211 102)",
            fontSize: "24px",
          }}
          className="fa-brands fa-whatsapp"
        ></i>
        <h2 className="text-xl font-semibold text-gray-800">
          Nouveau Participant
        </h2>
      </div>

      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-700">
          Nom Et Prenom{" "}
        </label>
        <input
          type="text"
          required
          name="nom_Et_Prenom"
          value={formData.nom_Et_Prenom}
          // onChange={e => setFormData({ ...formData, nom: e.target.value })}
          onChange={handleChangeDate}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#25D366]"
        />
      </div>

      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-700">email</label>
        <input
          type="email"
          required
          name="email"
          value={formData.email}
          // onChange={e => setFormData({ ...formData, email: e.target.value })}
          onChange={handleChangeDate}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#25D366]"
        />
      </div>

      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-700">
          WhatsApp
        </label>
        <div className="relative">
          <div className="d-flex align-items-center border border-gray-300 rounded-md px-3 focus:outline-none focus:ring-2 focus:ring-[#25D366]">
            <i
              style={{
                color: "rgb(37 211 102)",
                fontSize: "24px",
              }}
              className="fa-brands fa-whatsapp"
            ></i>
            <input
              style={{
                border: "none",
                outline: "none",
                paddingLeft: "5px",
              }}
              type="tel"
              name="whatsapp"
              required
              placeholder="+33612345678"
              value={formData.whatsapp}
              // onChange={e =>
              //   setFormData({ ...formData, whatsapp: e.target.value })
              // }
              onChange={handleChangeDate}
              className="w-full py-2 pl-3 "
            />
          </div>
          {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
        </div>
      </div>

      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-700">
          Ajouté par :
        </label>
        <select
          name="ajoute_par"
          value={formData.ajoute_par}
          // onChange={e =>
          //   setFormData({ ...formData, role: e.target.value })
          // }
          onChange={handleChangeDate}
          style={{
            width: "100%",
            padding: "0.5rem",
            border: "1px solid #d1d5db",
            borderRadius: "0.375rem",
            outline: "none",
            transition: "box-shadow 0.2s",
          }}
        >
          <option value="administrateur d'événementiel">
            administrateur d'événementiel
          </option>
          <option value="Formateur">Formateur</option>
        </select>
      </div>

      <button
        type="submit"
        className="w-full flex items-center justify-center gap-2 bg-[#25D366] text-white px-4 py-3 rounded-md hover:bg-[#128C7E] transition-colors"
      >
        <PlusCircle size={20} />
        Ajouter le participant
      </button>
    </form>
  );
}
