import React from "react";
import EmailForm from "./Espace_Commercial_On_Line/EmailForm";
import Features from "./Espace_Commercial_On_Line/Features";

import "./style.css";

/**
 * Formation_Participant component
 *
 * Contains the Formation_Participant page component
 *
 * @returns {JSX.Element} The Formation_Participant page component
 */
const CommercialOnlineLogin = () => {
  return (
    <>
      <div
        style={{
          marginTop: "80px",
        }}
        className="CommercialOnlineLogin min-h-screen bg-gradient-to-br from-blue-50 to-blue-100"
      >
        <div className="container mx-auto px-4 py-12 md:py-24">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            {/* Left Column - Form Section */}
            <div className="space-y-8">
              <div className="space-y-4">
                <h1 className="text-4xl md:text-5xl font-bold text-blue-900">
                  Espace E-Commercial
                </h1>
                <h2 className="text-2xl md:text-3xl font-semibold text-blue-800">
                  Formation en Ligne
                </h2>
                <p className="text-lg text-blue-700">
                  Profitez d'une opportunité unique: obtenez une place gratuite
                  pour nos formations en ligne et transformez-la en une offre
                  revendable.
                </p>
              </div>
              <EmailForm />
            </div>

            {/* Right Column - Features */}
            <Features />
          </div>
        </div>
      </div>
    </>
  );
};

export default CommercialOnlineLogin;
