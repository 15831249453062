import React from "react";

import CandidateCard from "./CandidateCard.jsx";
import { mockCandidates } from "./data/mockCandidates.js";

/**
 * Results is a React component that displays a list of candidate profiles
 * matching the given requirements. It renders a section with a heading,
 * followed by a list of requirement tags and a grid of candidate cards.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.requirements - An object containing the requirements
 * for matching candidates.
 * @returns {JSX.Element} The rendered JSX element displaying the candidate
 * profiles and requirements.
 */

export default function Results({ requirements }) {
  return (
    <div
      style={{
        marginTop: "80px",
      }}
      className="Results bg-gray-50"
    >
      <div className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
        <div className="mb-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Profils correspondants à vos critères
          </h2>
          <div className="flex flex-wrap gap-2">
            {Object.entries(requirements).map(([key, value]) => (
              <span
                key={key}
                className="px-3 py-1 bg-blue-100 text-blue-700 rounded-full text-sm"
              >
                {value}
              </span>
            ))}
          </div>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {mockCandidates.map((candidate) => (
            <CandidateCard key={candidate.id} candidate={candidate} />
          ))}
        </div>
      </div>
    </div>
  );
}
