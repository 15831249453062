export const evaluationData = {
  participation: {
    title: "Participation et engagement",
    metrics: [
      { label: "Niveau d'intérêt et de Participation aux sessions", value: 40 },
      {
        label: "Interaction avec le formateur et les autres Participants",
        value: 75,
      },
      { label: "Poser des questions et des clarifications", value: 65 },
    ],
  },
  performance: {
    title: "Performance Pratique et Applicative",
    metrics: [
      { label: "Capacité à appliquer les concepts et théories", value: 60 },
      {
        label: "Qualité du travail pratique ou des projets présentés",
        value: 70,
      },
      { label: "Capacité à résoudre les problèmes et à innover", value: 70 },
      { label: "Collaboration et travail d'équipe", value: 75 },
    ],
  },
  competences: {
    title: "Compétences Personnelles",
    metrics: [
      { label: "Compétences en communication efficace", value: 10 },
      { label: "Capacité de réflexion critique et analytique", value: 65 },
      { label: "Réceptivité aux retours d'information", value: 100 },
    ],
  },
  engagement: {
    title: "Engagement et Motivation des Participants",
    metrics: [
      {
        label: "Niveau de motivation personnelle tout au long de la formation",
        value: 70,
      },
      {
        label: "Volonté de s'impliquer dans les activités proposées",
        value: 70,
      },
      {
        label: "Initiative dans les discussions et partage d'idées",
        value: 70,
      },
      {
        label: "Capacité à maintenir une attitude positive et proactive",
        value: 70,
      },
      {
        label: "Engagement dans la réflexion sur ses propres apprentissages",
        value: 50,
      },
    ],
  },
};
