import React from "react";
import { FileCheck } from "lucide-react";

/**
 * Header
 *
 * A React component that renders a header section for verification,
 * including an icon, a title, and a descriptive paragraph.
 *
 * This component is designed to visually represent the functionality
 * of verifying an attestation, encouraging users to join the community
 * of certified professionals.
 *
 * @returns {JSX.Element} The JSX element representing the header section
 */
export function Header() {
  return (
    <div className="space-y-4">
      <div className="inline-flex items-center justify-center p-3 bg-blue-50 rounded-full">
        <FileCheck className="w-6 h-6 text-blue-600" />
      </div>
      <h1 className="text-3xl font-bold text-gray-900">
        Vérifier votre Attestation
      </h1>
      <p className="text-gray-600 max-w-md">
        Validez l'authenticité de votre attestation et rejoignez notre
        communauté de professionnels certifiés
      </p>
    </div>
  );
}
