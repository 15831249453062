// import React from 'react';
// import { BookOpen, CheckSquare, Library, BookMarked } from 'lucide-react';

// interface FeatureCardProps {
//   icon: React.ReactNode;
//   title: string;
//   description: string;
// }

// function FeatureCard({ icon, title, description }: FeatureCardProps) {
//   return (
//     <div className="flex items-start space-x-4 p-4 rounded-lg bg-white/10 backdrop-blur-sm">
//       <div className="flex-shrink-0 text-blue-100">
//         {icon}
//       </div>
//       <div>
//         <h3 className="font-semibold text-lg mb-1">{title}</h3>
//         <p className="text-blue-100 text-sm leading-relaxed">{description}</p>
//       </div>
//     </div>
//   );
// }

// export function CourseFeatures() {
//   return (
//     <div className="grid gap-4 mt-8">
//       <FeatureCard
//         icon={<BookOpen size={24} />}
//         title="Articles de Formation"
//         description="Accédez à des articles détaillés et structurés pour un apprentissage approfondi"
//       />
//       <FeatureCard
//         icon={<CheckSquare size={24} />}
//         title="QCM Interactifs"
//         description="Testez vos connaissances avec des questionnaires à choix multiples"
//       />
//       <FeatureCard
//         icon={<Library size={24} />}
//         title="Bibliothèque de Ressources"
//         description="Une collection complète de documents, guides et références"
//       />
//       <FeatureCard
//         icon={<BookMarked size={24} />}
//         title="Suivi de Progression"
//         description="Suivez votre avancement et validez vos acquis étape par étape"
//       />
//     </div>
//   );
// }
// ----------------------------------------------------------------------------------------------
import React from "react";
import { BookOpen, CheckSquare, Library, BookMarked } from "lucide-react";

function FeatureCard({ icon, title, description }) {
  return (
    <div className="flex items-start space-x-4 p-4 rounded-lg bg-white/10 backdrop-blur-sm">
      <div className="flex-shrink-0 text-blue-100">{icon}</div>
      <div>
        <h3 className="font-semibold text-lg mb-1">{title}</h3>
        <p className="text-blue-100 text-sm leading-relaxed">{description}</p>
      </div>
    </div>
  );
}

export function CourseFeatures() {
  return (
    <div className="grid gap-4 mt-8">
      <FeatureCard
        icon={<BookOpen size={24} />}
        title="Articles de Formation"
        description="Accédez à des articles détaillés et structurés pour un apprentissage approfondi"
      />
      <FeatureCard
        icon={<CheckSquare size={24} />}
        title="QCM Interactifs"
        description="Testez vos connaissances avec des questionnaires à choix multiples"
      />
      <FeatureCard
        icon={<Library size={24} />}
        title="Bibliothèque de Ressources"
        description="Une collection complète de documents, guides et références"
      />
      <FeatureCard
        icon={<BookMarked size={24} />}
        title="Suivi de Progression"
        description="Suivez votre avancement et validez vos acquis étape par étape"
      />
    </div>
  );
}
