import React from 'react';

export function TestimonialsHeader() {
  return (
    <div className="text-center mb-16">
      <span className="font-heading text-primary-600 font-medium mb-4 block tracking-wide">
        Nos Témoignages
      </span>
      <h2 className="text-4xl lg:text-5xl font-heading font-bold text-navy-900 bg-gradient-to-r from-navy-900 to-primary-900 bg-clip-text text-transparent">
        Avis de Nos Clients
      </h2>
    </div>
  );
}