import React, { useEffect, useState } from "react";

import { E_Trainings_header } from "../components/E-Trainings/E_Trainings_header";
import { E_Trainings_services } from "../components/E-Trainings/E_Trainings_services";
import { E_Trainings_available } from "../components/E-Trainings/E_Trainings_available";
import { E_Trainings_courses } from "../components/E-Trainings/E_Trainings_courses";
import E_Trainings_Pack_de_formation from "../components/E-Trainings/E_Trainings_Pack_de_formation";
import E_Trainings_Testimonial from "../components/E-Trainings/E_Trainings_Testimonial";
import { get_All } from "../functions/restApi";
import { AccessPlus } from "../components/E-Trainings/Access_Plus/AccessPlus";
import TestimonialsSection from "../components/E-Trainings/testimonials/TestimonialsSection";
import FAQSection from "../components/E-Trainings/faq/FAQSection";

/**
 * E_Trainings
 *
 * This component is responsible for rendering the E-Trainings page of the
 * website. It fetches the data for the class 1 section from the backend API
 * and renders the class 1, class 2, class 3, class 5, class 6 and class 7
 * components.
 *
 * The class 1 component is rendered with the data fetched from the backend
 * API. The class 2, class 3, class 5, class 6 and class 7 components are
 * rendered without any data.
 *
 * The component also renders a div with the id "coursPayant" which contains
 * the class 5 component.
 *
 * @return {React.ReactElement} The E-Trainings page component.
 */
export const E_Trainings = () => {
  const [e_trainings, setE_trainings] = useState({
    class_1: {},
  });

  const [cours, setCours] = useState([]);

  /**
   * Fetches all e-training courses from the backend API and updates
   * the state with the fetched data.
   *
   * @async
   * @function
   * @returns {Promise<void>} Resolves when the data is fetched and the state is updated.
   * Logs an error to the console if the request fails.
   */
  const get_all_cours = async () => {
    try {
      const res = await get_All(
        "https://ma-training-consulting-company-site-backend-one.vercel.app/api/get-all-cours"
      );

      if (res.data) {
        setCours(res.data.cours);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Fetches the data for the class 1 section from the backend API and updates
   * the state with the data.
   *
   * @return {Promise<void>} Resolves when the data is fetched and the state is
   * updated.
   */
  const get_Class_1 = async () => {
    const res = await get_All(
      "https://ma-training-consulting-company-site-backend-one.vercel.app/api/E_Trainings-get_Class_1"
    );

    if (res?.data) {
      setE_trainings((prevState) => ({
        ...prevState,
        class_1: res?.data.training[0],
      }));
    }
  };

  useEffect(() => {
    get_Class_1();
    get_all_cours();
  }, []);

  return (
    <>
      {/* class 1 */}
      <E_Trainings_header class_1={e_trainings.class_1} />
      {/* class 2 */}
      <E_Trainings_services />
      {/* class 3 */}
      <E_Trainings_available />
      {/* class 5 */}
      <div id="coursPayant">
        {" "}
        <E_Trainings_courses cours={cours} />{" "}
      </div>
      {/* class 6 */}
      {/* <E_Trainings_Pack_de_formation /> */}
      <AccessPlus />
      {/* class 7 */}
      {/* <E_Trainings_Testimonial /> */}
      <TestimonialsSection />
      {/* class 8 */}
      {/* <FAQSection /> */}
    </>
  );
};
