import { create } from "zustand";

export const useTrainingStore = create((set) => ({
  trainings: [],

  /**
   * Adds a new training to the store. The new training is expected to not
   * have an id, which will be automatically generated. The training will
   * also have an empty list of participants.
   * @param {Object} training - The new training session to add. Requires
   * a title, trainer, start date, end date, and status.
   * @returns {Object} The updated state of the store.
   */
  addTraining: (training) =>
    set((state) => ({
      trainings: [
        ...state.trainings,
        {
          ...training,
          id: crypto.randomUUID(),
          participants: [],
        },
      ],
    })),

  /**
   * Updates the status of a training session in the store. The training
   * with the given id will have its status updated to the given status.
   * @param {string} id - The id of the training session to update.
   * @param {string} status - The new status of the training session.
   * @returns {Object} The updated state of the store.
   */
  updateTrainingStatus: (id, status) =>
    set((state) => ({
      trainings: state.trainings.map((t) =>
        t.id === id ? { ...t, status } : t
      ),
    })),

  /**
   * Adds a new participant to a training session in the store. The participant
   * will be added to the training session with the given id.
   * @param {string} trainingId - The id of the training session to add the
   * participant to.
   * @param {Object} participant - The new participant to add. Requires a
   * firstName, lastName, and email.
   * @returns {Object} The updated state of the store.
   */
  addParticipant: (trainingId, participant) =>
    set((state) => ({
      trainings: state.trainings.map((t) =>
        t.id === trainingId
          ? {
              ...t,
              participants: [
                ...t.participants,
                { ...participant, id: crypto.randomUUID() },
              ],
            }
          : t
      ),
    })),
}));
