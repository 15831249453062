import React from "react";
import { GraduationCap, Users, UserCheck, Award } from "lucide-react";
import { Box } from "@mui/material";

import AboutSection from "./available/AboutSection";
import { StatCard } from "./StatCard";

import "../../styles/available.css";

/**
 * A functional component that renders a section about the available courses.
 * The section is divided into two parts. The first part is a heading with a
 * title and a paragraph describing what the courses are about. The second part
 * is a component that renders the courses themselves.
 * @returns {React.ReactElement} The rendered React element.
 */
export const E_Trainings_available = () => {
  const stats = [
    {
      icon: Users,
      value: "20+",
      label: "Experts & formateur",
      color: "text-indigo-600",
    },
    {
      icon: UserCheck,
      value: "5000+",
      label: "Participants Inscrits",
      color: "text-blue-600",
    },
    {
      icon: GraduationCap,
      value: "50+",
      label: "Programmes de Formations",
      color: "text-violet-600",
    },
    {
      icon: Award,
      value: "100+",
      label: "Participants Certifiés",
      color: "text-purple-600",
    },
  ];

  return (
    <>
      <div className="E_Trainings_available min-h-screen bg-gradient-to-b from-blue-50 to-white py-4 ">
        <AboutSection />
      </div>

      <section className="E_Trainings_available py-16 bg-gradient-to-br from-blue-600 to-indigo-700">
        <div className="max-w-6xl mx-auto px-4">
          <Box
            sx={{
              gridTemplateColumns: {
                lg: " repeat(4, minmax(0, 1fr))",
                md: " repeat(2, minmax(0, 1fr))",
                xs: " repeat(1, minmax(0, 1fr))",
              },
            }}
            className="grid gap-8"
          >
            {stats.map((stat, index) => (
              <StatCard key={index} {...stat} />
            ))}
          </Box>
        </div>
      </section>
    </>
  );
};
