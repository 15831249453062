import { useEffect, useState } from "react";
import { Button } from "./button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "./card";
import { Input } from "./input";
import { Label } from "./label";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./tabs";
import { Textarea } from "./textarea";
import { RadioGroup, RadioGroupItem } from "./radio-group";
import { Checkbox } from "./checkbox";
import { ScrollArea } from "./scroll-area";
import {
  UserCircle,
  Building2,
  Calendar,
  Clock,
  FileCheck,
} from "lucide-react";

import "./style.css";
import { Box } from "@mui/material";

function FomateurRegister() {
  const [step, setStep] = useState("personal");
  const [formData, setFormData] = useState({
    name: "",
  });

  useEffect(() => {
    console.log("formData", formData);
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div
      style={{
        marginTop: "80px",
      }}
      className="FomateurRegister min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50 p-4 md:p-8"
    >
      <div className="max-w-4xl mx-auto">
        <Card className="border-none shadow-xl bg-white/90 backdrop-blur">
          <CardHeader className="text-center">
            <CardTitle className="text-3xl font-bold text-indigo-900">
              Formulaire d'Inscription - Formateurs Qualifiés
            </CardTitle>
            <CardDescription className="text-lg text-gray-600">
              Rejoignez notre réseau de formateurs d'excellence
            </CardDescription>
          </CardHeader>
          <CardContent>
            <Tabs value={step} onValueChange={setStep} className="space-y-6">
              <TabsList className="grid grid-cols-5 gap-4 bg-muted/50 p-1">
                <TabsTrigger
                  value="personal"
                  className="button data-[state=active]:bg-white"
                >
                  <UserCircle className="mr-2 h-4 w-4" />
                  <span className="d-none d-md-inline-block ms-2">
                    Personnel
                  </span>
                </TabsTrigger>
                <TabsTrigger
                  value="professional"
                  className="button data-[state=active]:bg-white"
                >
                  <Building2 className="mr-2 h-4 w-4" />
                  <span className="d-none d-md-inline-block ms-2">
                    Professionnel
                  </span>
                </TabsTrigger>
                <TabsTrigger
                  value="experience"
                  className="button data-[state=active]:bg-white"
                >
                  <Calendar className="mr-2 h-4 w-4" />
                  <span className="d-none d-md-inline-block ms-2">
                    Expérience
                  </span>
                </TabsTrigger>
                <TabsTrigger
                  value="availability"
                  className="button data-[state=active]:bg-white"
                >
                  <Clock className="mr-2 h-4 w-4" />
                  <span className="d-none d-md-inline-block ms-2">
                    Disponibilités
                  </span>
                </TabsTrigger>
                <TabsTrigger
                  value="documents"
                  className="button data-[state=active]:bg-white"
                >
                  <FileCheck className="mr-2 h-4 w-4" />
                  <span className="d-none d-md-inline-block ms-2">
                    Documents
                  </span>
                </TabsTrigger>
              </TabsList>

              <ScrollArea className="h-[600px] pr-4">
                <TabsContent value="personal" className="space-y-6">
                  <Box
                    sx={{
                      gridTemplateColumns: {
                        md: "repeat(2, minmax(0, 1fr))",
                        xs: "repeat(1, minmax(0, 1fr))",
                      },
                    }}
                    // className="grid grid-cols-1 md:grid-cols-2 gap-6"
                    className="grid gap-6"
                  >
                    <div className="space-y-2">
                      <Label htmlFor="fullName">Nom complet</Label>
                      <Input
                        name="name"
                        onChange={handleChange}
                        id="fullName"
                        placeholder="Jean Dupont"
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="birthDate">Date de naissance</Label>
                      <Input id="birthDate" type="date" />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="street">Rue</Label>
                      <Input
                        id="street"
                        placeholder="123 rue de la Formation"
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="city">Ville</Label>
                      <Input id="city" placeholder="Paris" />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="postalCode">Code postal</Label>
                      <Input id="postalCode" placeholder="75000" />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="country">Pays</Label>
                      <Input id="country" placeholder="France" />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="phone">Téléphone</Label>
                      <Input
                        id="phone"
                        type="tel"
                        placeholder="+33 6 12 34 56 78"
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="email">E-mail professionnel</Label>
                      <Input
                        id="email"
                        type="email"
                        placeholder="jean.dupont@email.com"
                      />
                    </div>
                    <div className="col-span-2 space-y-2">
                      <Label htmlFor="photo">
                        Photo professionnelle (facultatif)
                      </Label>
                      <Input id="photo" type="file" accept="image/*" />
                    </div>
                  </Box>
                </TabsContent>

                <TabsContent value="professional" className="space-y-6">
                  <div className="space-y-4">
                    <div className="space-y-2">
                      <Label htmlFor="specialization">
                        Domaine(s) de spécialisation
                      </Label>
                      <Input
                        id="specialization"
                        placeholder="Management, Communication, Informatique..."
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="experience">
                        Nombre d'années d'expérience
                      </Label>
                      <Input
                        id="experience"
                        type="number"
                        min="0"
                        placeholder="5"
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="currentPosition">
                        Poste actuel ou précédent
                      </Label>
                      <Textarea
                        id="currentPosition"
                        placeholder="Entreprise, Fonction, Durée"
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="certifications">
                        Certifications ou diplômes pertinents
                      </Label>
                      <Textarea
                        id="certifications"
                        placeholder="Listez vos certifications..."
                      />
                    </div>
                  </div>
                </TabsContent>

                <TabsContent value="experience" className="space-y-6">
                  <div className="space-y-4">
                    <div className="space-y-2">
                      <Label>Types de formations dispensées</Label>
                      <Box
                        sx={{
                          gridTemplateColumns: {
                            md: "repeat(3, minmax(0, 1fr))",
                            xs: "repeat(1, minmax(0, 1fr))",
                          },
                        }}
                        // className="grid grid-cols-1 md:grid-cols-3 gap-4"
                        className="grid gap-4"
                      >
                        <div className="flex items-center space-x-2">
                          <Checkbox
                            className="button checkbox "
                            id="inPerson"
                          />
                          <Label htmlFor="inPerson">En présentiel</Label>
                        </div>
                        <div className="flex items-center space-x-2">
                          <Checkbox className="button checkbox " id="remote" />
                          <Label htmlFor="remote">À distance</Label>
                        </div>
                        <div className="flex items-center space-x-2">
                          <Checkbox className="button checkbox" id="hybrid" />
                          <Label htmlFor="hybrid">Hybride</Label>
                        </div>
                      </Box>
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="targetAudience">
                        Public cible déjà formé
                      </Label>
                      <Textarea
                        id="targetAudience"
                        placeholder="Cadres, employés, étudiants..."
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="methods">
                        Méthodes pédagogiques préférées
                      </Label>
                      <Textarea
                        id="methods"
                        placeholder="Apprentissage par projet, jeux de rôle..."
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="achievement">
                        Exemple de réalisation
                      </Label>
                      <Textarea
                        id="achievement"
                        placeholder="Décrivez une formation marquante..."
                      />
                    </div>
                  </div>
                </TabsContent>

                <TabsContent value="availability" className="space-y-6">
                  <div className="space-y-4">
                    <div className="space-y-2">
                      <Label htmlFor="regions">
                        Régions ou pays d'intervention
                      </Label>
                      <Input
                        id="regions"
                        placeholder="France, Belgique, Suisse..."
                      />
                    </div>
                    <div className="space-y-2">
                      <Label>Modes de prestation acceptés</Label>
                      <RadioGroup defaultValue="all">
                        <div className="flex items-center space-x-2">
                          <RadioGroupItem
                            className="button"
                            value="all"
                            id="all"
                          />
                          <Label htmlFor="all">Tous les modes</Label>
                        </div>
                        <div className="flex items-center space-x-2">
                          <RadioGroupItem
                            className="button"
                            value="inPerson"
                            id="inPersonOnly"
                          />
                          <Label htmlFor="inPersonOnly">
                            Présentiel uniquement
                          </Label>
                        </div>
                        <div className="flex items-center space-x-2">
                          <RadioGroupItem
                            className="button"
                            value="remote"
                            id="remoteOnly"
                          />
                          <Label htmlFor="remoteOnly">
                            En ligne uniquement
                          </Label>
                        </div>
                      </RadioGroup>
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="availability">Disponibilités</Label>
                      <Textarea
                        id="availability"
                        placeholder="Jours fixes, plages horaires..."
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="rate">
                        Tarif horaire ou forfaitaire souhaité (facultatif)
                      </Label>
                      <Input id="rate" placeholder="€/heure ou forfait" />
                    </div>
                  </div>
                </TabsContent>

                <TabsContent value="documents" className="space-y-6">
                  <div className="space-y-4">
                    <div className="space-y-2">
                      <Label htmlFor="cv">Curriculum Vitae (CV)</Label>
                      <Input id="cv" type="file" accept=".pdf,.doc,.docx" />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="certificates">
                        Copies des certifications
                      </Label>
                      <Input
                        id="certificates"
                        type="file"
                        accept=".pdf,.doc,.docx"
                        multiple
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="portfolio">Portfolio pédagogique</Label>
                      <Input
                        id="portfolio"
                        type="file"
                        accept=".pdf,.doc,.docx,.ppt,.pptx"
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="motivation">Lettre de motivation</Label>
                      <Input
                        id="motivation"
                        type="file"
                        accept=".pdf,.doc,.docx"
                      />
                    </div>
                    <div className="space-y-4 pt-4">
                      <div className="flex items-center space-x-2">
                        <Checkbox className="button" id="consent" />
                        <Label htmlFor="consent" className="text-sm">
                          Je confirme que les informations fournies sont exactes
                          et j'accepte que ces données soient utilisées dans le
                          cadre de la sélection des formateurs qualifiés.
                        </Label>
                      </div>
                      <div className="space-y-2">
                        <Label htmlFor="signature">
                          Signature électronique
                        </Label>
                        <Input id="signature" placeholder="Votre nom complet" />
                      </div>
                      <div className="space-y-2">
                        <Label htmlFor="signatureDate">Date</Label>
                        <Input id="signatureDate" type="date" />
                      </div>
                    </div>
                  </div>
                </TabsContent>
              </ScrollArea>

              <div className="flex justify-between mt-6">
                <Button
                  variant="outline"
                  onClick={() => {
                    const steps = [
                      "personal",
                      "professional",
                      "experience",
                      "availability",
                      "documents",
                    ];
                    const currentIndex = steps.indexOf(step);
                    if (currentIndex > 0) {
                      setStep(steps[currentIndex - 1]);
                    }
                  }}
                  disabled={step === "personal"}
                >
                  Précédent
                </Button>
                <Button
                  className="submit-btn"
                  onClick={() => {
                    const steps = [
                      "personal",
                      "professional",
                      "experience",
                      "availability",
                      "documents",
                    ];
                    const currentIndex = steps.indexOf(step);
                    if (currentIndex < steps.length - 1) {
                      setStep(steps[currentIndex + 1]);
                    } else {
                      // Submit form
                      console.log("Form submitted");
                    }
                  }}
                >
                  {step === "documents" ? "Soumettre" : "Suivant"}
                </Button>
              </div>
            </Tabs>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export default FomateurRegister;
