import React from "react";

export function FormSelect({ label, options }) {
  return (
    <div>
      <label
        style={{
          display: "block",
          fontSize: "0.875rem",
          fontWeight: "500",
          marginBottom: "8px",
        }}
      >
        {label}
      </label>
      <select
        style={{
          width: "100%",
          backgroundColor: "rgba(255, 255, 255, 0.05)",
          border: "1px solid rgba(255, 255, 255, 0.1)",
          borderRadius: "8px",
          padding: "10px 16px",
          outline: "none",
          transition: "all 0.3s",
        }}
        onFocus={(e) => {
          e.target.style.border = "2px solid #60A5FA"; // Focus border color
          e.target.style.boxShadow = "0 0 0 2px rgba(96, 165, 250, 0.4)"; // Focus ring
        }}
        onBlur={(e) => {
          e.target.style.border = "1px solid rgba(255, 255, 255, 0.1)"; // Restore original border
          e.target.style.boxShadow = "none"; // Remove focus ring
        }}
      >
        <option
          style={{
            color: "black",
          }}
          value=""
        >
          Sélectionnez votre domaine
        </option>
        {options.map((option) => (
          <option
            style={{
              color: "black",
            }}
            key={option}
            value={option}
          >
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}
