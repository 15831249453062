import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';

import { validateEmail, validatePhone } from './validation.js';

/**
 * A React component that renders a form for creating or editing a client.
 *
 * The form renders input fields for the client's name, email, phone number, and
 * address. The form also renders buttons for submitting the form and canceling it.
 *
 * @param {object} client - The client object to edit. If no client is provided, the
 *   form will create a new client.
 * @param {function} onSubmit - A callback function that is called when the form is
 *   submitted. The function should take a client object as an argument.
 * @param {function} onClose - A callback function that is called when the cancel button
 *   is clicked.
 *
 * @returns {JSX.Element} The rendered JSX element.
 */
const ClientForm = ({ client, onSubmit, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    themeDeFormation: '',
    montant: 0,
    dateLimiteDePaiement: '',
  });

  useEffect(() => {
    console.log('formData', formData);
  }, [formData]);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (client) {
      setFormData({
        name: client.name,
        email: client.email,
        phone: client.phone,
        address: client.address,
        themeDeFormation: client.themeDeFormation,
        montant: client.montant,
        dateLimiteDePaiement: client.dateLimiteDePaiement,
      });
    }
  }, [client]);

  /**
   * Validates the form data and returns true if it is valid, or false otherwise.
   *
   * The method checks that the name, email, phone, and address fields are not
   * empty and that the email and phone fields are valid according to the
   * validateEmail and validatePhone functions.
   *
   * The method also updates the errors state with any validation errors that
   * are encountered.
   *
   * @returns {boolean} True if the form data is valid, or false otherwise.
   */
  const validate = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = 'Le nom est requis';
    }

    if (!validateEmail(formData.email)) {
      newErrors.email = 'Email invalide';
    }

    if (!validatePhone(formData.phone)) {
      newErrors.phone = 'Numéro de téléphone invalide';
    }

    if (!formData.address.trim()) {
      newErrors.address = "L'adresse est requise";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (validate()) {
      onSubmit(formData);
    }
  };

  return (
    <>
      <div className="ClientForm bg-white rounded-lg p-6 w-full max-w-md">
        <form onSubmit={handleSubmit}>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Nom compléte
              </label>
              <input
                type="text"
                value={formData.name}
                onChange={e =>
                  setFormData({ ...formData, name: e.target.value })
                }
                className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
              {errors.name && (
                <p className="mt-1 text-sm text-red-600">{errors.name}</p>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                value={formData.email}
                onChange={e =>
                  setFormData({ ...formData, email: e.target.value })
                }
                className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
              {errors.email && (
                <p className="mt-1 text-sm text-red-600">{errors.email}</p>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Whatsapp
              </label>
              <input
                type="tel"
                value={formData.phone}
                onChange={e =>
                  setFormData({ ...formData, phone: e.target.value })
                }
                className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
              {errors.phone && (
                <p className="mt-1 text-sm text-red-600">{errors.phone}</p>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Théme de formation
              </label>
              <input
                type="text"
                value={formData.themeDeFormation}
                onChange={e =>
                  setFormData({ ...formData, themeDeFormation: e.target.value })
                }
                className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
              {/* {errors.address && (
                <p className="mt-1 text-sm text-red-600">{errors.address}</p>
              )} */}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Montant
              </label>
              <input
                type="number"
                min={0}
                value={formData.montant}
                onChange={e =>
                  setFormData({ ...formData, montant: e.target.value })
                }
                className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
              {/* {errors.address && (
                <p className="mt-1 text-sm text-red-600">{errors.address}</p>
              )} */}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Date limite de paiement
              </label>
              <input
                type="date"
                min={0}
                value={formData.dateLimiteDePaiement}
                onChange={e =>
                  setFormData({
                    ...formData,
                    dateLimiteDePaiement: e.target.value,
                  })
                }
                className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
              {/* {errors.address && (
                <p className="mt-1 text-sm text-red-600">{errors.address}</p>
              )} */}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Adresse
              </label>
              <textarea
                value={formData.address}
                onChange={e =>
                  setFormData({ ...formData, address: e.target.value })
                }
                rows={3}
                className="p-2 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
              {errors.address && (
                <p className="mt-1 text-sm text-red-600">{errors.address}</p>
              )}
            </div>
          </div>
          <div className="mt-6 flex justify-end space-x-3">
            <Box
              component="button"
              sx={{
                '&:hover': {
                  backgroundColor: 'rgb(229 229 229)',
                },
              }}
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
            >
              Annuler
            </Box>
            <Box
              component="button"
              sx={{
                backgroundColor: 'rgb(37 99 235 / 1)',
                '&:hover': {
                  backgroundColor: 'rgb(29 78 216 / 1)',
                },
              }}
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            >
              {client ? 'Modifier' : 'Créer'}
            </Box>
          </div>
        </form>
      </div>
    </>
  );
};

export default ClientForm;
