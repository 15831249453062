import React, { useState } from "react";
import { Search } from "lucide-react";

/**
 * A form to input an ID to verify an attestation.
 *
 * @param {function(string): void} onVerify - The function to call when the form is submitted
 * @param {boolean} isLoading - Whether the verification is in progress
 * @returns {JSX.Element} The form element
 */
export function VerificationInput({ onVerify, isLoading }) {
  const [id, setId] = useState("");

  /**
   * Handles the form submission.
   *
   * Prevents the default form action, and then verifies the attestation with
   * the provided ID if the ID is non-empty and verification is not in progress.
   *
   * @param {Event} e - The form submission event
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    if (id.trim() && !isLoading) {
      onVerify(id);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full max-w-lg mx-auto">
      <div className="relative">
        <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
        <input
          type="text"
          value={id}
          onChange={(e) => setId(e.target.value)}
          placeholder="Entrez votre ID de attestation"
          disabled={isLoading}
          className="w-full pl-10 pr-4 py-3 rounded-lg border border-gray-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 outline-none transition-colors disabled:bg-gray-50"
        />
      </div>
      <button
        type="submit"
        disabled={isLoading || !id.trim()}
        className={`mt-4 w-full py-3 px-4 bg-blue-600 text-white font-medium rounded-lg transition-colors ${
          id.trim() || (isLoading && "hover:bg-blue-700")
        } disabled:bg-blue-300`}
      >
        {isLoading ? "Vérification..." : "Vérifier"}
      </button>
    </form>
  );
}
