import React from 'react';
import { Play } from 'lucide-react';

export function ImageSection() {
  return (
    <div className="relative">
      <div className="absolute inset-0 bg-yellow-400 rounded-full transform translate-x-4 translate-y-4 -z-10"></div>
      <div className="relative overflow-hidden rounded-2xl aspect-[4/3]">
        <img
          src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?auto=format&fit=crop&q=80&w=1600"
          alt="Formation professionnelle"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent"></div>
        <div className="absolute bottom-4 left-4 right-4 text-white">
          <div className="flex items-center space-x-2">
            <div className="w-12 h-12 bg-blue-600 rounded-full flex items-center justify-center">
              <Play className="w-6 h-6 text-white" />
            </div>
            <p className="font-medium">Découvrez nos formations</p>
          </div>
        </div>
      </div>
    </div>
  );
}