import React, { useState } from "react";
import { Layout, Calendar, Users } from "lucide-react";
import { Box } from "@mui/material";

import Navbar from "../dashboard_navbar/formateur/Navbar";
import Dashboard from "../dashboard_navbar/dashboard/Dashboard";
import Formations from "../dashboard_navbar/formations/Formations";
import Participants from "../dashboard_navbar/participants/Participants";

import "../../styles/dashboard/css/style.css";
import "../../styles/bootstrap.min.css";

/**
 * E_Trainings_Formateur component
 *
 * This component renders the E-Trainings dashboard for a formateur
 *
 * @param {function} setAuth - function to set the authentication state
 * @returns {JSX.Element} - the E-Trainings formateur dashboard component
 */
const E_Trainings_Formateur = ({ setAuth }) => {
  const [navigate, setNavigate] = useState("Dashboard");
  const menuItems = [
    { icon: Layout, label: "Formations", path: "Dashboard" },
    { icon: Calendar, label: "Gestion des séances", path: "Formations" },
    { icon: Users, label: "Participants", path: "participants" },
  ];

  return (
    <div
      style={{
        marginTop: "80px",
      }}
      className="E_Trainings_Formateur"
    >
      <Navbar
        setNavigate={setNavigate}
        navigate={navigate}
        setAuth={setAuth}
        menuItems={menuItems}
      />

      <Box
        sx={{
          marginTop: "50px",
          paddingTop: "10px",
          paddingLeft: {
            lg: "270px",
          },
        }}
        component="section"
        className="nftmax-adashboard bg-gray-100 nftmax-show"
      >
        <div className="row">
          <div className="col-xxl-11 col-12 nftmax-main__column mt-5 pt-5">
            <div className="nftmax-body Formateur">
              {navigate === "Dashboard" && <Dashboard />}
              {navigate === "Formations" && <Formations />}
              {navigate === "participants" && <Participants />}
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default E_Trainings_Formateur;
