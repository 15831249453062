import React from "react";
import { Calendar as CalendarIcon, Users, BookOpen } from "lucide-react";

/**
 * DashboardHeader
 *
 * Un composant React qui affiche le titre de la page de tableau de bord des formations
 * ainsi que les liens vers les différentes pages de gestion des sessions, des formateurs
 * et des formations.
 *
 * @returns {React.ReactElement} Le JSX élément à afficher.
 */
export function DashboardHeader() {
  return (
    <div className="bg-white shadow-md rounded-lg p-6 mb-6">
      <h1 className="text-2xl font-bold text-gray-800 mb-6">
        Tableau de Bord des Formations
      </h1>
      <div className="row g-4">
        <div className="col-12 col-md-4">
          <div className="d-flex align-items-center p-3 bg-primary bg-opacity-10 rounded">
            <CalendarIcon
              className="me-3 text-primary"
              style={{ width: "2rem", height: "2rem" }}
            />
            <div>
              <h3 className="h5 fw-semibold text-dark">Sessions</h3>
              <p className="small text-secondary">Gestion des séances</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="d-flex align-items-center p-3 bg-success bg-opacity-10 rounded">
            <Users
              className="me-3 text-success"
              style={{ width: "2rem", height: "2rem" }}
            />
            <div>
              <h3 className="h5 fw-semibold text-dark">Formateurs</h3>
              <p className="small text-secondary">Équipe pédagogique</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="flex items-center p-4 bg-purple-50 rounded-lg">
            <BookOpen
              className="lucide lucide-book-open w-8 h-8 text-purple-500 mr-3"
              style={{ width: "2rem", height: "2rem" }}
            />
            <div>
              <h3 className="h5 fw-semibold text-dark">Formations</h3>
              <p className="small text-secondary">Programmes & thèmes</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
