export const level = (lvl) => {
  if (lvl < 50) {
    return "bg-emerald-500";
  } else if (lvl > 49 && lvl < 75) {
    return "bg-blue-500";
  } else if (lvl > 74 && lvl < 100) {
    return "bg-purple-500";
  } else if (lvl > 99) {
    return "bg-rose-500";
  }
};
