import React from 'react';

import { DeadlineForm } from './DeadlineForm.js';
import { AlertList } from './AlertList';

import './style.css';

/**
 * A React component that renders a page for adding a client to the
 * commercial online service.
 *
 * This component renders a form to add a new deadline and a list of
 * existing deadlines. It also renders a list of alerts that are displayed
 * if the user does not have enough points to redeem a free client.
 *
 * @param {{ onRedeemPoints: function(): void }} props
 * @returns {JSX.Element} The rendered JSX element.
 */
function Add_Client({ onRedeemPoints, handleClose }) {
  return (
    <div className="Add_Client min-h-screen bg-gray-100">
      <main className="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
        <div>
          <DeadlineForm
            onRedeemPoints={onRedeemPoints}
            handleClose={handleClose}
          />
        </div>
        <AlertList />
      </main>
    </div>
  );
}

export default Add_Client;
