import React from "react";
import { CountdownUnit } from "./CountdownUnit.jsx";

export function CountdownTimer() {
  return (
    <div className="grid grid-cols-4 gap-4 max-w-xl">
      <CountdownUnit value={0} label="JOURS" />
      <CountdownUnit value={0} label="HEURES" />
      <CountdownUnit value={0} label="MINUTES" />
      <CountdownUnit value={0} label="SECONDES" />
    </div>
  );
}
