import React, { useState } from 'react';
import TabContext from '@mui/lab/TabContext';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import DashboardHeader from './DashboardHeader';
import PaymentTable from './PaymentTable';
import ClientManagement from './ClientManagement';

import './style.css';

const initialPayments = [
  {
    id: '1',
    client: 'Entreprise ABC',
    amount: 1500.0,
    date: '2024-03-15',
    status: 'completed',
  },
  {
    id: '2',
    client: 'Société XYZ',
    amount: 2300.5,
    date: '2024-03-16',
    status: 'pending',
  },
  {
    id: '3',
    client: 'Client 123',
    amount: 750.75,
    date: '2024-03-17',
    status: 'completed',
  },
];

/**
 * DiminuirPpartenir component.
 *
 * A dashboard for managing payments and clients. The component is divided into
 * two sections: "Paiements" and "Clients". The first section displays a table
 * with all the payments, and the second section displays a table with all the
 * clients.
 *
 * The component also allows the user to edit the payments and clients.
 *
 * @param {string} currentMonth The current month.
 * @param {number} currentYear The current year.
 * @param {string} service The current service.
 * @param {Payment[]} payments The list of payments.
 * @param {function} onMonthChange The callback function to change the month.
 * @param {function} onYearChange The callback function to change the year.
 * @param {function} onServiceChange The callback function to change the service.
 * @param {function} onEditPayment The callback function to edit a payment.
 * @param {function} onDeletePayment The callback function to delete a payment.
 * @param {function} onStatusChange The callback function to change the status of
 * a payment.
 *
 * @returns {JSX.Element} The rendered component.
 */
function DiminuirPpartenir() {
  const [currentMonth, setCurrentMonth] = useState('Mars');
  const [currentYear, setCurrentYear] = useState(2024);
  const [payments, setPayments] = useState(initialPayments);
  const [value, setValue] = useState('Paiements');

  /**
   * Handles the change event for the tab selection, updating the component state
   * with the new value.
   *
   * @param {Event} event - The event object associated with the tab change.
   * @param {string} newValue - The new selected tab value.
   */
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEditPayment = payment => {
    console.log('Edit payment:', payment);
  };

  const handleDeletePayment = id => {
    setPayments(payments.filter(payment => payment.id !== id));
  };

  /**
   * Updates the status of a payment in the list of payments.
   *
   * @param {string} id - The unique identifier of the payment to update.
   * @param {string} status - The new status to assign to the payment.
   */
  const handleStatusChange = (id, status) => {
    setPayments(
      payments.map(payment =>
        payment.id === id ? { ...payment, status } : payment
      )
    );
  };

  return (
    <div className="DiminuirPpartenir min-h-screen bg-gray-100">
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <DashboardHeader
          currentMonth={currentMonth}
          currentYear={currentYear}
          onMonthChange={setCurrentMonth}
          onYearChange={setCurrentYear}
        />

        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Paiements" value="Paiements" />
                <Tab label="Clients" value="Clients" />
              </TabList>
            </Box>
            <TabPanel className="px-0" value="Paiements">
              {' '}
              <PaymentTable
                payments={payments}
                partnerPercentage={15}
                onEditPayment={handleEditPayment}
                onDeletePayment={handleDeletePayment}
                onStatusChange={handleStatusChange}
              />
            </TabPanel>
            <TabPanel className="px-0" value="Clients">
              {' '}
              <ClientManagement />
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </div>
  );
}

export default DiminuirPpartenir;
