/**
 * Initiates a download of a file from the given URL.
 *
 * @param {string} fileUrl - The URL of the file to be downloaded.
 *
 * This function creates a temporary anchor element, sets its href to the provided
 * file URL, and triggers a click event to start the download. The downloaded file
 * will be named "CV.pdf". The anchor element is then removed from the document.
 */
export const downloadFile = (fileUrl) => {
  const link = document.createElement("a");
  link.href = fileUrl;
  link.download = "CV.pdf";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * Scrolls the page to the section with the given ID.
 *
 * @param {string} sectionId - The ID of the section to scroll to.
 */
export const scrollToSection = (sectionId) => {
  const element = document.getElementById(sectionId);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};
