import React from "react";
import EventCard from "./EventCard.jsx";
import { events } from "./data/events.js";

/**
 * A React component that renders a section of the homepage that displays a list
 * of upcoming recruitment events.
 *
 * The component renders a heading, a subheading, and a paragraph of text that
 * describe the events. It then renders a grid of EventCard components, one for
 * each event in the events array.
 *
 * @returns {JSX.Element} The rendered JSX element.
 */
export default function EventsSection() {
  return (
    <section className="bg-gray-50 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-extrabold text-blue-600 mb-3">
            Team Building
          </h2>
          <h3 className="text-3xl font-bold text-gray-900 mb-4">
            Événements à venir
          </h3>
          <p className="text-xl text-gray-600">
            Participez à nos événements de recrutement et rencontrez directement
            les talents
          </p>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {events.map((event) => (
            <EventCard key={event.id} event={event} />
          ))}
        </div>
      </div>
    </section>
  );
}
