import { format, isAfter, isBefore, addDays } from "date-fns";
import { fr } from "date-fns/locale";

/**
 * Format a date as a French-formatted string (dd MMMM yyyy).
 *
 * @param {Date} date - The date to format.
 * @returns {string} The formatted date string.
 */
export const formatDate = (date) => {
  return format(date, "dd MMMM yyyy", { locale: fr });
};

/**
 * Check if a given due date is overdue.
 *
 * @param {Date} dueDate - The due date to check.
 * @returns {boolean} True if the due date is before the current date, otherwise false.
 */
export const isOverdue = (dueDate) => {
  return isBefore(dueDate, new Date());
};

/**
 * Check if a given due date is coming up soon (i.e. within 7 days).
 *
 * @param {Date} dueDate - The due date to check.
 * @returns {boolean} True if the due date is after the current date and
 *   within 7 days, otherwise false.
 */
export const isUpcoming = (dueDate) => {
  const warningDate = addDays(new Date(), 7);
  return isBefore(new Date(), dueDate) && isAfter(warningDate, dueDate);
};
