import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import ClientList from './ClientList';
import ClientForm from './ClientForm';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const initialClients = [
  {
    id: '1',
    name: 'Entreprise ABC',
    email: 'contact@abc.fr',
    phone: '0123456789',
    address: '123 Rue de Paris, 75001 Paris',
    createdAt: new Date().toISOString(),
  },
];

/**
 * ClientManagement is a React component that manages a list of clients.
 * It displays a list of clients and allows the user to add, edit, and delete
 * clients. The component also displays a form to add or edit a client when
 * the user clicks on the "Nouveau Client" or "Modifier le Client" button.
 *
 * @returns {JSX.Element} The rendered component.
 */
const ClientManagement = () => {
  const [clients, setClients] = useState(initialClients);
  const [selectedClient, setSelectedClient] = useState(undefined);
  const [open, setOpen] = useState(false);

  /**
   * Closes the client management dialog.
   *
   * Sets the state 'open' to false, which will close the dialog.
   */
  const handleClose = () => {
    setOpen(false);
  };

  const handleAddClient = data => {
    const newClient = {
      id: Date.now().toString(),
      ...data,
      createdAt: new Date().toISOString(),
    };
    setClients([...clients, newClient]);
  };

  const handleEditClient = data => {
    if (selectedClient) {
      setClients(
        clients.map(client =>
          client.id === selectedClient.id ? { ...client, ...data } : client
        )
      );
      setSelectedClient(undefined);
    }
  };

  const handleDeleteClient = id => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer ce client ?')) {
      setClients(clients.filter(client => client.id !== id));
    }
  };

  return (
    <div className="space-y-6">
      <ClientList
        clients={clients}
        onEdit={client => {
          setSelectedClient(client);
          setOpen(true);
        }}
        onDelete={handleDeleteClient}
        onAddNew={() => {
          setSelectedClient(undefined);
          setOpen(true);
        }}
      />

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {selectedClient ? 'Modifier le Client' : 'Nouveau Client'}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={theme => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <ClientForm
            client={selectedClient}
            onSubmit={selectedClient ? handleEditClient : handleAddClient}
            onClose={() => {
              setOpen(false);
              setSelectedClient(undefined);
            }}
          />
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default ClientManagement;
