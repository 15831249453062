import React from "react";
import { Quote } from "lucide-react";
// import { TestimonialType } from "../../types/testimonial";

export function TestimonialCard({ name, role, image, content }) {
  return (
    <div className="bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-shadow duration-300 border border-gray-100">
      <div className="flex items-center gap-4 mb-6">
        <div className="relative">
          <div className="absolute inset-0 bg-primary-400/20 rounded-full transform translate-x-1 translate-y-1"></div>
          <img
            src={image}
            alt={name}
            className="w-16 h-16 rounded-full object-cover relative z-10"
          />
        </div>
        <div>
          <h3 className="font-heading font-semibold text-lg text-navy-900">
            {name}
          </h3>
          <p className="text-primary-600 font-medium">{role}</p>
        </div>
      </div>
      <div className="relative">
        <Quote className="absolute -top-2 -left-2 w-8 h-8 text-primary-200 transform -scale-x-100" />
        <p className="text-navy-700 leading-relaxed pl-6">{content}</p>
      </div>
    </div>
  );
}
