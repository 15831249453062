import React, { useState } from "react";

import { ParticipantForm } from "./ParticipantForm";
import { ParticipantList } from "./ParticipantList";

import "./style.css";

/**
 * Participants component
 *
 * This component manages a list of participants, allowing for addition and status updates.
 * It renders a form to add new participants and a list to display existing participants.
 * Each participant has a status that can be updated.
 *
 * State:
 * - participants: Array of participant objects, each containing an id and statut.
 *
 * Functions:
 * - handleAjouterParticipant: Adds a new participant to the list.
 * - handleUpdateStatut: Updates the status of an existing participant by id.
 *
 * @returns {JSX.Element} The rendered participants management interface, including
 * a form for adding participants and a list displaying current participants.
 */
function Participants() {
  const [participants, setParticipants] = useState([]);

  /**
   * Adds a new participant to the list.
   *
   * @param {participant} participant - The participant object to add, containing an id and statut.
   * @returns {void} Does not return a value, but updates the state with the new participant.
   */
  const handleAjouterParticipant = (participant) => {
    setParticipants([...participants, participant]);
  };

  /**
   * Updates the status of an existing participant by id.
   *
   * @param {string} participantId - The id of the participant to update.
   * @param {string} nouveauStatut - The new status to update the participant to.
   * @returns {void} Does not return a value, but updates the state with the new status.
   */
  const handleUpdateStatut = (participantId, nouveauStatut) => {
    setParticipants(
      participants.map((participant) =>
        participant.id === participantId
          ? { ...participant, statut: nouveauStatut }
          : participant
      )
    );
  };

  return (
    <div className="Participants min-h-screen bg-[#ECE5DD] py-8 px-4">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl font-bold text-center text-[#075E54] mb-8">
          Gestion des Participants
        </h1>

        <div className="row justify-content-around row-cols-1 row-cols-md-2 g-4 mt-4 ">
          <ParticipantForm onAjouterParticipant={handleAjouterParticipant} />
          <ParticipantList
            participants={participants}
            onUpdateStatut={handleUpdateStatut}
          />
        </div>
      </div>
    </div>
  );
}

export default Participants;
