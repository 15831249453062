import React from "react";

import { level } from "./level";

const levels = [
  {
    name: "Débutant",
    score: 25,
    color: "bg-emerald-500",
    description: "Connaissances fondamentales",
  },
  {
    name: "Intermédiaire",
    score: 50,
    color: "bg-blue-500",
    description: "Maîtrise des concepts essentiels",
  },
  {
    name: "Avancé",
    score: 75,
    color: "bg-purple-500",
    description: "Expertise approfondie",
  },
  {
    name: "Expert",
    score: 100,
    color: "bg-rose-500",
    description: "Maîtrise exceptionnelle",
  },
];

export default function EvaluationCard({ evaluation_Generale_score }) {
  return (
    <div className="max-w-4xl mx-auto p-6">
      {/* Score Global */}
      <div
        className={` ${level(
          evaluation_Generale_score
        )} rounded-xl p-8 mb-8 text-center text-white`}
      >
        <h2 className="text-2xl font-bold mb-2">Évaluation globale</h2>
        <div className="text-6xl font-bold mb-4">
          {evaluation_Generale_score}
        </div>
        <p className="text-sm opacity-90">
          Moyenne des performances sur l'ensemble des critères
        </p>
      </div>

      {/* Niveaux */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {levels.map((level) => (
          <div
            key={level.name}
            className="bg-white rounded-lg shadow-lg overflow-hidden"
          >
            <div className={`${level.color} p-4 text-white`}>
              <h3 className="text-xl font-bold">{level.name}</h3>
              <div className="text-3xl font-bold">{level.score}</div>
            </div>
            <div className="p-4">
              <p className="text-gray-600 text-sm">{level.description}</p>
              <div className="mt-2 bg-gray-200 rounded-full h-2">
                <div
                  className={`${level.color} h-2 rounded-full`}
                  style={{
                    // width: `${Math.min(
                    //   100,
                    //   (globalScore / level.score) * 100
                    // )}%`,
                    width: "100%",
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
