export const mockCandidates = [
  {
    id: 1,
    name: "Sophie Martin",
    title: "Développeuse Full Stack",
    location: "Paris",
    experience: "5 ans d'expérience",
    education: "Master en Informatique",
    skills: ["React", "Node.js", "TypeScript", "PostgreSQL"],
    avatar:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&w=128&h=128",
  },
  {
    id: 2,
    name: "Thomas Dubois",
    title: "Chef de Projet Digital",
    location: "Lyon",
    experience: "7 ans d'expérience",
    education: "École de Commerce",
    skills: ["Gestion de Projet", "Agile", "Marketing Digital"],
    avatar:
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?auto=format&fit=crop&w=128&h=128",
  },
  {
    id: 3,
    name: "Emma Bernard",
    title: "UX Designer",
    location: "Bordeaux",
    experience: "4 ans d'expérience",
    education: "Master Design Numérique",
    skills: ["Figma", "Adobe XD", "Prototypage", "Design System"],
    avatar:
      "https://images.unsplash.com/photo-1517841905240-472988babdf9?auto=format&fit=crop&w=128&h=128",
  },
];
