import Sans_titre1 from "./images/photo de site/Sans titre1.png";
import Sans_titre2 from "./images/photo de site/Sans titre2.png";
import Sans_titre3 from "./images/photo de site/Sans titre3.png";
import Sans_titre4 from "./images/photo de site/Sans titre4.png";
import Sans_titre5 from "./images/photo de site/Sans titre5.png";
import videoimage1 from "./images/videoimage1.png";
import videoimage2 from "./images/videoimage2.png";

export const formation_payant = [
  {
    id: 1,
    imgUrl: Sans_titre1,
    videoImage: videoimage2,
    videoLink:
      "https://www.youtube.com/watch?v=RD8dScOt5fI&ab_channel=MA-Training-consulting",
    imgAlt: "course rajibraj91 rajibraj",
    price: {
      Premium: 69,
      Standard: 22,
    },
    date: "Session :  Janv / Févr 2025",
    reviewCount: "03 reviews",
    title: "Superviseur HSE",
    duration: "6 séances ( 12H )",
    type: "ONLINE",
    authorImgAlt: "course author rajibraj91 rajibraj",
    niveau: "Niveau Intermédiaire",
    btnText: "PLUS EN DÉTAIL",
    dategorie: "QHSE (Qualité, Hygiène, Sécurité, Environnement)",
    path: "/course-single",
    Session_date: [
      {
        link: "#",
        text: "Session Janvier 2025",
        className: "session-badge",
        date: "du 16 Janvier au 30 Janvier",
        Jours: ["Mercredi", "jeudi", "vendredi"],
        Horaire: "18H30GMT - 20H30GMT",
        availableSpots: 15,
        totalSpots: 20,
      },
      {
        link: "#",
        text: "Session Avril 2025",
        className: "session-badge success",
        date: "du 17 Avril au 1 Mai",
        Jours: ["Mercredi", "jeudi", "vendredi"],
        Horaire: "18H30 - 20H30GMT",
        availableSpots: 15,
        totalSpots: 20,
      },
    ],

    Objectifs_list: [
      "Identifier et évaluer les risques en hygiène, sécurité et environnement.",
      "Veiller au respect et à l'application des consignes de sécurité transmises.",
      "Prévoir les équipements nécessaires à la prévention des situations d'accidents.",
      "Développer les performances HSE.",
      "Maîtriser la réglementation en vigueur en matière d'hygiène, sécurité et environnement.",
    ],

    Programme_list: [
      {
        icon: "fa-chart-line",
        text: "Évaluation des risques SST",
      },
      {
        icon: "fa-leaf",
        text: "Évaluation des aspects environnementaux",
      },
      {
        icon: "fa-exclamation-triangle",
        text: "Risques SST",
      },
      {
        icon: "fa-ambulance",
        text: "Situations d’urgence",
      },
      {
        icon: "fa-tools",
        text: "Outils de pilotage et reporting HSE",
      },
      {
        icon: "fa-search",
        text: "Analyse des accidents de travail",
      },
      {
        icon: "fa-book",
        text: "Veille légale et réglementaire HSE",
      },
    ],

    Seances_list: [
      {
        title: "Séance 1 + 2",
        text: "Gestion des accidents du travail et des maladies professionnelles",
      },
      {
        title: "Séance 3",
        text: "Évaluation des aspects environnementaux",
      },
      {
        title: "Séance 4",
        text: " Maîtrise des situations d'urgence HSE",
      },
      {
        title: "Séance 5 + 6",
        text: "ISO 45001:2018 / ISO 14001:2015 / Audit (HSE)",
      },
    ],
  },
  {
    id: 2,
    imgUrl: Sans_titre2,
    videoImage: videoimage1,
    videoLink:
      "https://www.youtube.com/watch?v=I3bBu1_g3Dk&ab_channel=MA-Training-consulting",
    imgAlt: "course rajibraj91 rajibraj",
    price: {
      Premium: 132,
      Standard: 44,
    },
    date: "Session : Févr / Mars 2025",
    reviewCount: "03 reviews",
    title: "Devenir Responsable QHSE ",
    Session_date: [
      {
        link: "#",
        text: "Session fevrier 2025",
        className: "course-date",
        date: "du 06 fev au 28 fev",
        Jours: ["Mercredi", "jeudi", "vendredi"],
        Horaire: "18H30GMT - 20H30GMT",
        availableSpots: 15,
        totalSpots: 20,
      },
      {
        link: "#",
        text: "Session juin 2025",
        className: "course-offer",
        date: "du 15 juin au 10 juillet",
        Jours: ["Mercredi", "Jeudi", "Vendredi"],
        Horaire: "18H30 - 20H30GMT",
        availableSpots: 15,
        totalSpots: 20,
      },
    ],
    Objectifs_list: [
      "Comprendre les normes ISO 9001, ISO 45001 et ISO 14001.",
      "Réaliser des audits internes selon la norme ISO 19011.",
      "Appliquer les outils de résolution des problèmes dans un cadre HSE.",
      "Maîtriser les exigences et les processus liés aux systèmes de management.",
    ],

    Programme_list: [
      {
        icon: "fa-chart-line",
        text: "Introduction aux normes ISO",
      },
      {
        icon: "fa-leaf",
        text: "Systèmes de management de la qualité",
      },
      {
        icon: "fa-exclamation-triangle",
        text: "Systèmes de management de la santé et sécurité au travail",
      },
      {
        icon: "fa-ambulance",
        text: "Systèmes de management environnemental",
      },
      {
        icon: "fa-tools",
        text: " Outils de résolution des problèmes",
      },
      // {
      //   icon: "fa-search",
      //   text: "Analyse des accidents de travail",
      // },
      // {
      //   icon: "fa-book",
      //   text: "Veille légale et réglementaire HSE",
      // },
    ],
    Seances_list: [
      {
        title: "Séance 1",
        text: "ISO 9001 (Partie 1)",
      },
      {
        title: "Séance 2",
        text: "ISO 9001 (Partie 2)",
      },
      {
        title: "Séance 9",
        text: "Audit interne selon ISO 19011",
      },
      {
        title: "Séance 4",
        text: "ISO 45001 (Partie 1)",
      },
      {
        title: "Séance 5",
        text: "ISO 45001 (Partie 2)",
      },
      {
        title: "Séance 6",
        text: "ISO 14001 (Partie 1)",
      },
      {
        title: "Séance 7",
        text: "ISO 14001 (Partie 2)",
      },
      {
        title: "Séance 8",
        text: "Outils de résolution des problèmes",
      },
    ],

    duration: "8 séances ( 24 H )",
    type: "ONLINE",
    authorImgAlt: "course author rajibraj91 rajibraj",
    niveau: "Niveau Intermédiaire",
    btnText: "PLUS EN DÉTAIL",
    dategorie: "QHSE (Qualité, Hygiène, Sécurité, Environnement)",
    path: "/course-single",
    desc: "planing de Session",
  },
  // -------------------------------------------------------------------------------
  {
    id: "",
    path: "#",
    imgUrl: Sans_titre3,
    imgAlt: "course rajibraj91 rajibraj",
    price: "$30",
    date: "À venir",
    reviewCount: "03 reviews",
    title: "Développement Web",
    duration: "Inconnu ",
    type: "ONLINE",
    authorImgAlt: "course author rajibraj91 rajibraj",
    niveau: "Niveau Intermédiaire",
    btnText: "PLUS EN DÉTAIL",
    dategorie: "Développement web",
  },
  {
    id: "",
    path: "#",
    imgUrl: Sans_titre4,
    imgAlt: "course rajibraj91 rajibraj",
    price: "$30",
    date: "À venir",
    reviewCount: "03 reviews",
    title: "UX/UI Design",
    duration: "Inconnu ",
    type: "ONLINE",
    authorImgAlt: "course author rajibraj91 rajibraj",
    niveau: "Niveau Intermédiaire",
    btnText: "PLUS EN DÉTAIL",
    dategorie: "Conception UX/UI (UX/UI Design)",
  },
  {
    id: "",
    path: "#",
    imgUrl: Sans_titre5,
    imgAlt: "course rajibraj91 rajibraj",
    price: "$30",
    date: "À venir",
    reviewCount: "03 reviews",
    title: "Gestion commerciale",
    duration: "Inconnu ",
    type: "ONLINE",
    authorImgAlt: "course author rajibraj91 rajibraj",
    niveau: "Niveau Intermédiaire",
    btnText: "PLUS EN DÉTAIL",
    dategorie: "Gestion commerciale",
  },
];
