import React from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { Search, Users, Briefcase } from "lucide-react";

import EventsSection from "./EventsSection.jsx";

import "./style.css";

/**
 * The Hero component displays a hero section with a heading, a paragraph, a
 * call-to-action button and three columns of features.
 *
 * @returns {React.ReactElement} The Hero component.
 */
export default function Hero() {
  const navigate = useNavigate();

  return (
    <div
      style={{
        marginTop: "80px",
      }}
      className="reactitment"
    >
      <div className="relative bg-gradient-to-br from-blue-600 to-blue-800 text-white">
        <div className="max-w-7xl mx-auto px-4 py-24 sm:px-6 lg:px-8">
          <div className="text-center">
            <Box
              sx={{
                fontSize: {
                  md: "3.75rem",
                  xs: "2.25rem",
                },
                lineHeight: {
                  md: "1",
                  xs: "2.5rem",
                },
                fontWeight: "700",
              }}
              component="h1"
              className="mb-6"
            >
              Trouvez les meilleurs talents pour votre entreprise
            </Box>
            <p className="text-xl md:text-2xl mb-8 text-blue-100">
              Notre algorithme intelligent trouve les candidats parfaits pour
              vos besoins
            </p>
            <button
              onClick={() => navigate("/recruter")}
              className="bg-white text-blue-600 px-8 py-4 rounded-lg text-lg font-semibold 
                hover:bg-blue-50 transition-colors shadow-lg"
            >
              Commencer le recrutement
            </button>
          </div>

          <div className="grid md:grid-cols-3 gap-8 mt-20">
            <div className="bg-white/10 backdrop-blur-lg rounded-xl p-6">
              <Search className="w-12 h-12 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Recherche précise</h3>
              <p className="text-blue-100">
                Définissez vos critères et trouvez les candidats qui
                correspondent exactement à vos besoins.
              </p>
            </div>
            <div className="bg-white/10 backdrop-blur-lg rounded-xl p-6">
              <Users className="w-12 h-12 mb-4" />
              <h3 className="text-xl font-semibold mb-2">
                Matching intelligent
              </h3>
              <p className="text-blue-100">
                Notre algorithme analyse les profils pour vous proposer les
                meilleurs candidats.
              </p>
            </div>
            <div className="bg-white/10 backdrop-blur-lg rounded-xl p-6">
              <Briefcase className="w-12 h-12 mb-4" />
              <h3 className="text-xl font-semibold mb-2">
                Recrutement simplifié
              </h3>
              <p className="text-blue-100">
                Un processus fluide et efficace pour vous faire gagner du temps.
              </p>
            </div>
          </div>
        </div>
      </div>
      <EventsSection />
    </div>
  );
}
