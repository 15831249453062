import React from "react";
import { useForm } from "react-hook-form";

import { useTrainingStore } from "./useTrainingStore";

/**
 * A form to create a new training session. It requires a title, trainer, start
 * date, and end date. The form will validate the input and if it is valid, it
 * will call the `addTraining` action to add the new training session to the
 * store.
 *
 * @returns {React.ReactElement} The rendered React element.
 */
export function TrainingForm() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      trainer: "",
      startDate: "",
      endDate: "",
    },
  });

  const addTraining = useTrainingStore((state) => state.addTraining);

  /**
   * Handles the form submission for creating a new training session. If the
   * title or trainer fields are empty or contain less than 3 characters, it
   * will alert the user. Otherwise, it will call the `addTraining` action to add
   * the new training session to the store and reset the form.
   * @param {Object} data - The form data containing the title, trainer, start
   * date, and end date of the training session.
   */
  const onSubmit = (data) => {
    if (data.title.length < 3) {
      alert("Le titre doit contenir au moins 3 caractères");
      return;
    }

    addTraining({
      title: data.title,
      trainer: data.trainer,
      startDate: new Date(data.startDate),
      endDate: new Date(data.endDate),
      status: "scheduled",
    });
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Théme
          <input
            type="text"
            {...register("title")}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        </label>
        {errors.title && (
          <p className="mt-1 text-sm text-red-600">{errors.title.message}</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Choisissez
          <select
            value="formation"
            // onChange={e =>
            // }
            style={{
              width: "100%",
              padding: "0.5rem",
              border: "1px solid #d1d5db",
              borderRadius: "0.375rem",
              outline: "none",
              transition: "box-shadow 0.2s",
            }}
          >
            <option value="formation">formation</option>
            <option value="événement">événement</option>
          </select>
        </label>
        {errors.trainer && (
          <p className="mt-1 text-sm text-red-600">{errors.trainer.message}</p>
        )}
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Date de début
            <input
              type="datetime-local"
              {...register("startDate")}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </label>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Date de fin
            <input
              type="datetime-local"
              {...register("endDate")}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </label>
        </div>
      </div>

      <button
        type="submit"
        className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors mt-3"
      >
        Créer
      </button>
    </form>
  );
}
