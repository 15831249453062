// import React from 'react';
// import { motion } from 'framer-motion';

// interface MetricScoreProps {
//   value: number;
//   size?: 'sm' | 'md' | 'lg';
// }

// export default function MetricScore({ value, size = 'md' }: MetricScoreProps) {
//   const sizeClasses = {
//     sm: 'text-sm px-2 py-0.5',
//     md: 'text-base px-3 py-1',
//     lg: 'text-lg px-4 py-1.5'
//   };

//   const getScoreColor = (score: number) => {
//     if (score < 30) return 'bg-red-100 text-red-700';
//     if (score < 50) return 'bg-orange-100 text-orange-700';
//     if (score < 70) return 'bg-yellow-100 text-yellow-700';
//     if (score < 90) return 'bg-green-100 text-green-700';
//     return 'bg-emerald-100 text-emerald-700';
//   };

//   return (
//     <motion.div
//       initial={{ scale: 0.9, opacity: 0 }}
//       animate={{ scale: 1, opacity: 1 }}
//       className={`font-medium rounded-full ${sizeClasses[size]} ${getScoreColor(value)}`}
//     >
//       {value}%
//     </motion.div>
//   );
// }
// ------------------------------------------------------------
import React from "react";
import { motion } from "framer-motion";

export default function MetricScore({ value, size = "md" }) {
  const sizeClasses = {
    sm: "text-sm px-2 py-0.5",
    md: "text-base px-3 py-1",
    lg: "text-lg px-4 py-1.5",
  };

  const getScoreColor = (score) => {
    if (score < 30) return "bg-red-100 text-red-700";
    if (score < 50) return "bg-orange-100 text-orange-700";
    if (score < 70) return "bg-yellow-100 text-yellow-700";
    if (score < 90) return "bg-green-100 text-green-700";
    return "bg-emerald-100 text-emerald-700";
  };

  return (
    <motion.div
      initial={{ scale: 0.9, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      className={`font-medium rounded-full ${sizeClasses[size]} ${getScoreColor(
        value
      )}`}
    >
      {value}%
    </motion.div>
  );
}
