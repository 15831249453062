import { motion } from "framer-motion";

/**
 * @function Header
 * @description Header component for the certification page
 * @param {string} code The code to display
 * @param {string} userName The user name to display
 * @returns {ReactElement} The Header component
 */
const Header = ({ code, userName }) => {
  return (
    <motion.div
      initial={{ y: -20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      className="bg-white rounded-xl shadow-lg p-8"
    >
      <div className="flex items-center justify-center mb-6">
        <div className="w-16 h-16 bg-blue-600 rounded-full flex items-center justify-center">
          <span className="text-2xl text-white">👤</span>
        </div>
      </div>

      <h1 className="text-2xl font-bold text-gray-900 mb-2">
        Bonjour, {userName}
      </h1>

      <p className="text-gray-600 mb-4">
        Voici votre attestation d'identité numérique
      </p>

      <div className="bg-gray-50 rounded-lg p-4">
        <p className="text-sm text-gray-600 mb-1">Code d'identification</p>
        <code className="text-lg font-mono text-blue-600 font-semibold">
          {code}
        </code>
      </div>
    </motion.div>
  );
};

export default Header;
