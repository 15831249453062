import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

/**
 * TrainingCalendar is a React component that renders a calendar interface using FullCalendar.
 * It allows users to view events and select date ranges on the calendar.
 *
 * @param {Array} events - An array of event objects to be displayed on the calendar.
 * @param {Function} onSelectEvent - Callback function triggered when an event is clicked.
 * Receives the clicked event as an argument.
 * @param {Function} onSelectSlot - Callback function triggered when a date range is selected
 * on the calendar. Receives the start and end dates of the selected range.
 *
 * @returns {JSX.Element} A styled calendar component with interaction capabilities.
 */
export function TrainingCalendar({ events, onSelectEvent, onSelectSlot }) {
  /**
   * Handles event clicks on the calendar by calling the onSelectEvent callback function.
   *
   * @param {Object} info - Contains information about the event that was clicked.
   * @param {Object} info.event - The event object that was clicked.
   */
  const handleEventClick = (info) => {
    if (onSelectEvent) {
      onSelectEvent(info.event);
    }
  };

  /**
   * Handles date range selection on the calendar by calling the onSelectSlot callback function.
   *
   * @param {Object} info - Contains information about the selected date range.
   * @param {Date} info.start - The start date of the selected range.
   * @param {Date} info.end - The end date of the selected range.
   */
  const handleDateSelect = (info) => {
    if (onSelectSlot) {
      onSelectSlot({
        start: info.start,
        end: info.end,
      });
    }
  };

  return (
    <div
      style={{
        height: "600px",
      }}
      className="h-[600px] bg-white rounded-lg shadow-lg p-4 mb-3"
    >
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        selectable={true}
        events={events}
        eventClick={handleEventClick}
        select={handleDateSelect}
        height="100%"
        headerToolbar={{
          start: "prev,next today",
          center: "title",
          end: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        locale="fr"
        buttonText={{
          today: "Aujourd'hui",
          month: "Mois",
          week: "Semaine",
          day: "Jour",
        }}
      />
    </div>
  );
}
