import React from "react";
import { FAQHeader } from "./FAQHeader.jsx";
import { FAQGrid } from "./FAQGrid.jsx";

import "./style.css";

const FAQSection = () => {
  return (
    <section className="FAQSection py-24 bg-navy-950 relative overflow-hidden">
      {/* Background Elements */}
      <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1557426272-fc759fdf7a8d?auto=format&fit=crop&q=80')] bg-cover bg-center opacity-5"></div>
      <div className="absolute inset-0 bg-gradient-to-b from-navy-950 to-navy-900/95"></div>

      <div className="relative z-10 max-w-6xl mx-auto px-4">
        <FAQHeader />
        <FAQGrid />
      </div>
    </section>
  );
};

export default FAQSection;
