import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { GraduationCap, Clock, Award, ExternalLink } from "lucide-react";

import { formation_payant } from "../../db";

import "../../styles/cours/style.css";

/**
 * E_Trainings_courses is a React component that renders a section of a website
 * dedicated to e-trainings. It displays a list of courses, with filters by
 * category and by level (beginner, intermediate, advanced). The courses are
 * displayed in a grid, with each course represented by a card containing its
 * title, category, level, duration, schedule, and a link to its details page.
 * The component uses the useState hook to keep track of the active filter and
 * the selected category.
 *
 * @param {Object} props The component props.
 * @prop {array} cours The list of courses to display.
 *
 * @returns {JSX.Element} The rendered JSX element.
 */
export const E_Trainings_courses = ({ cours }) => {
  const [active, setActive] = useState("");
  const [categorie, setCategorie] = useState("");

  const categories = new Set(formation_payant.map((item) => item.dategorie));

  return (
    <>
      <div className="E_Trainings_courses min-h-screen bg-gradient-to-b from-blue-50 to-white">
        {/* Header */}
        <header className="bg-blue-600 text-white py-16 px-4">
          <div className="max-w-6xl mx-auto text-center">
            <h1 className="text-4xl font-bold mb-4">
              DÉVELOPPEZ VOS COMPÉTENCES
            </h1>
            <p className="text-xl text-blue-100">Avec le soutien d'experts</p>
          </div>
        </header>

        {/* Categories */}
        <div className="max-w-6xl mx-auto px-4 py-8">
          <div className="flex flex-wrap gap-4 justify-center mb-12">
            <button
              onClick={() => setCategorie("")}
              className={`px-6 py-2 rounded-full bg-blue-100 text-blue-600 hover:bg-blue-200 transition-colors ${
                categorie === "" && "active"
              } `}
            >
              Tous
            </button>
            {[...categories]?.map((category) => (
              <button
                key={category}
                onClick={() => setCategorie(category)}
                className={`px-6 py-2 rounded-full bg-blue-100 text-blue-600 hover:bg-blue-200 transition-colors ${
                  categorie === category && "active"
                } `}
              >
                {category}
              </button>
            ))}
          </div>

          {/* Courses Grid */}
          <Box
            sx={{
              gridTemplateColumns: {
                lg: "repeat(3, minmax(0, 1fr))",
                md: "repeat(2, minmax(0, 1fr))",
                xs: " repeat(1, minmax(0, 1fr))",
              },
            }}
            className="grid gap-8"
          >
            {formation_payant
              .filter(
                (item) => item.dategorie === categorie || categorie === ""
              )
              .map((course) => (
                <div
                  key={course.title}
                  className="bg-white rounded-xl shadow-lg overflow-hidden transition-transform hover:scale-105"
                >
                  <div className="relative h-48">
                    <img
                      src={course.imgUrl}
                      alt={course.title}
                      className="w-full h-full object-cover"
                    />
                    <span className="absolute top-4 right-4 bg-orange-500 text-white px-3 py-1 rounded-full text-sm">
                      {course.niveau}
                    </span>
                  </div>

                  <div className="p-6">
                    <div className="text-sm text-blue-600 mb-2">
                      {course.date}
                    </div>
                    <h3 className="text-xl font-bold mb-4">{course.title}</h3>

                    <div className="flex items-center gap-2 text-gray-600 mb-2">
                      <Clock className="w-4 h-4" />
                      <span className="text-sm">{course.duration}</span>
                    </div>

                    <div className="flex items-center gap-2 text-gray-600 mb-4">
                      <Award className="w-4 h-4" />
                      <span className="text-sm">Attestation Officielle</span>
                    </div>

                    <div className="flex justify-between items-center">
                      <span className="inline-flex items-center gap-1 text-blue-600">
                        <GraduationCap className="w-4 h-4" />
                        {course.type}
                      </span>
                      <Link
                        to={`${course.path}/${course.id}`}
                        className="flex items-center gap-2 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
                      >
                        Plus de détails
                        <ExternalLink className="w-4 h-4" />
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </Box>
        </div>
      </div>
    </>
  );
};
