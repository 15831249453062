import React, { useEffect, useState } from "react";
import { Menu, X, Home } from "lucide-react";
import Box from "@mui/material/Box";
import { navigationItems } from "./navigationData";
import { Link, useLocation } from "react-router-dom";
import { ChevronDown } from "lucide-react";

import { NavDropdown } from "./NavDropdown";

import "./style.css";

/**
 * DropdownMobile
 *
 * Renders a dropdown menu for mobile devices
 *
 * @param {object} sub_menu - sub menu items
 * @param {function} setIsOpen - function to set isOpen state
 * @param {function} setShowList - function to set showList state
 *
 * @returns {JSX.Element} - A dropdown menu for mobile devices
 */
const DropdownMobile = ({ sub_menu, setIsOpen, setShowList }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <li>
        <a onClick={() => setShow(!show)} href="#">
          {sub_menu.title}
        </a>
        <ul
          style={{
            position: "relative",
            zIndex: 1,
            paddingLeft: "15px",
          }}
          className={`dropdown-menu ${show && "show"}`}
        >
          {sub_menu.menu?.map((item, index) => (
            <Link
              key={index}
              onClick={() => {
                setIsOpen(false);
                setShow(false);
                setShowList(false);
              }}
              to={item.path}
            >
              {item.title}
            </Link>
          ))}
        </ul>
      </li>
    </>
  );
};

/**
 * A menu item for the mobile navigation menu.
 *
 * @param {object} item - the menu item
 * @param {function} setIsOpen - function to set isOpen state
 *
 * @returns {JSX.Element} - A menu item for the mobile navigation menu
 */
const MobileMenu = ({ item, setIsOpen }) => {
  const [showList, setShowList] = useState(false);

  return (
    <span
      style={{
        display: "flex",
      }}
      className="block px-3 py-2 rounded-md"
    >
      <Link
        style={{
          flexGrow: 6,
        }}
        onClick={() => setIsOpen(false)}
        to={item.href}
      >
        <div className="text-sm font-medium text-gray-900">{item.title}</div>
        <div className="text-xs text-gray-500">{item.subtitle}</div>
      </Link>
      {item.sub_menu?.length > 0 && (
        <span
          style={{
            flexGrow: 1,
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            transition: "all .5s",
            transform: `rotate(${showList ? "-179deg" : "0deg"})`,
          }}
          onClick={() => setShowList(!showList)}
        >
          <ChevronDown className="w-4 h-4 text-gray-400 group-hover:text-blue-500 transition-colors" />
        </span>
      )}
      {item.sub_menu?.length > 0 && (
        <ul className={`dropdown-menu ${showList && "show"}`}>
          {item.sub_menu?.map((item, index) => (
            <DropdownMobile
              key={index}
              sub_menu={item}
              setIsOpen={setIsOpen}
              setShowList={setShowList}
            />
          ))}
        </ul>
      )}
    </span>
  );
};

/**
 * NavBar component
 *
 * This component renders a navigation bar with menu items for top-level
 * navigation. It includes icons and labels for different sections such as
 * Dashboard, Formations, Participants, and more. The navigation bar is
 * responsive and can toggle visibility.
 *
 * State:
 * - isOpen (boolean): Controls the visibility of the mobile menu.
 * - isActive (string): Current active path.
 *
 * Props:
 * - None
 */
const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState("/E-Trainings");
  const location = useLocation();

  // useEffect(() => {
  //   if (
  //     location.pathname === "/" ||
  //     location.pathname === "/Formateur" ||
  //     location.pathname === "/Participant" ||
  //     location.pathname === "/Commercial-Online" ||
  //     location.pathname === "/Dashboard-Register" ||
  //     location.pathname === "/Commercial-Online-Register"
  //   ) {
  //     setIsActive("/E-Trainings");
  //   } else if (location.pathname === "/recruter") {
  //     setIsActive("/recruitment");
  //   } else if (isActive === "") {
  //     setIsActive(location.pathname);
  //   } else {
  //     setIsActive(location.pathname);
  //   }
  // }, [location.pathname]);

  return (
    <nav className="NavBar fixed top-0 left-0 right-0 z-50 bg-white/80 backdrop-blur-md border-b border-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-20">
          {/* Logo and Home */}
          <div className="flex items-center gap-8">
            <Link
              to="/"
              className="flex items-center gap-2 text-blue-600 font-semibold"
            >
              <Home className="w-5 h-5" />
              <span>MATC</span>
            </Link>
          </div>

          {/* Desktop Navigation */}
          <Box
            sx={{
              display: {
                lg: "flex",
                xs: "none",
              },
            }}
            className="lg:items-center lg:gap-2"
          >
            {navigationItems.map((item) => (
              <NavDropdown
                key={item.href}
                title={item.title}
                subtitle={item.subtitle}
                href={item.href}
                sub_menu={item.sub_menu}
                isActive={isActive}
                setIsActive={setIsActive}
              />
            ))}
          </Box>

          {/* Mobile menu button */}
          <Box
            sx={{
              display: {
                lg: "none",
                xs: "flex",
              },
            }}
          >
            <button
              type="button"
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-600 hover:text-blue-600 hover:bg-blue-50 transition-colors"
            >
              <span className="sr-only">Open main menu</span>
              {isOpen ? (
                <X className="block h-6 w-6" />
              ) : (
                <Menu className="block h-6 w-6" />
              )}
            </button>
          </Box>
        </div>
      </div>

      {/* Mobile menu */}
      <div className={`lg:hidden ${isOpen ? "block" : "hidden"}`}>
        <div className="px-2 pt-2 pb-3 space-y-1 bg-white border-b border-gray-200">
          {navigationItems.map((item) => (
            <MobileMenu key={item.href} item={item} setIsOpen={setIsOpen} />
          ))}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
