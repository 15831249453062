import React from "react";
import { Header } from "./Header.jsx";
import { CourseCard } from "./CourseCard.jsx";
import { EventCard } from "./EventCard.jsx";
import { freeCourses } from "./courses";
import { upcomingEvents } from "./events";
import { Box } from "@mui/material";

const CoursesAndEvents = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-sky-50 to-white">
      <Header />

      <main className="max-w-7xl mx-auto px-4 py-8">
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-gray-900 mb-6">
            Cours d'Initiation Gratuits
          </h2>
          <Box
            sx={{
              gridTemplateColumns: {
                lg: "repeat(3, minmax(0, 1fr))",
                md: "repeat(2, minmax(0, 1fr))",
                xs: "repeat(1, minmax(0, 1fr))",
              },
            }}
            className="grid gap-6"
          >
            {freeCourses.map((course) => (
              <CourseCard key={course.id} {...course} />
            ))}
          </Box>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-gray-900 mb-6">
            Événements à Venir
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {upcomingEvents.map((event) => (
              <EventCard key={event.id} {...event} />
            ))}
          </div>
        </section>
      </main>
    </div>
  );
};

export default CoursesAndEvents;
