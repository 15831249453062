import React from "react";
import { MapPin, Briefcase, GraduationCap } from "lucide-react";

/**
 * A component that displays a candidate card with information about the candidate.
 *
 * @param {{ candidate: { avatar: string, name: string, title: string, location: string, experience: string, education: string, skills: string[] } }} props
 * @returns {JSX.Element}
 */
export default function CandidateCard({ candidate }) {
  return (
    <div className="bg-white rounded-xl shadow-md overflow-hidden hover:shadow-lg transition-shadow">
      <div className="p-6">
        <div className="flex items-center mb-4">
          <img
            src={candidate.avatar}
            alt={candidate.name}
            className="w-16 h-16 rounded-full object-cover"
          />
          <div className="ml-4">
            <h3 className="text-xl font-semibold text-gray-900">
              {candidate.name}
            </h3>
            <p className="text-blue-600">{candidate.title}</p>
          </div>
        </div>

        <div className="space-y-2 mb-4">
          <div className="flex items-center text-gray-600">
            <MapPin className="w-5 h-5 mr-2" />
            {candidate.location}
          </div>
          <div className="flex items-center text-gray-600">
            <Briefcase className="w-5 h-5 mr-2" />
            {candidate.experience}
          </div>
          <div className="flex items-center text-gray-600">
            <GraduationCap className="w-5 h-5 mr-2" />
            {candidate.education}
          </div>
        </div>

        <div className="flex flex-wrap gap-2 mb-4">
          {candidate.skills.map((skill) => (
            <span
              key={skill}
              className="px-3 py-1 bg-blue-100 text-blue-700 rounded-full text-sm"
            >
              {skill}
            </span>
          ))}
        </div>

        <button className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition-colors">
          Voir le profil
        </button>
      </div>
    </div>
  );
}
