import React, { useEffect } from "react";
import {
  Users,
  MessageCircle,
  CheckCircle,
  XCircle,
  Clock,
} from "lucide-react";

import { COMMISSION_BASE, COMMISSION_FIDELE } from "./commissions";
import { STATUT } from ".";

/**
 * Affiche la liste des participants, avec des boutons pour confirmer ou
 * annuler leur inscription. Les participants sont affichés avec leur nom,
 * prénom, numéro de téléphone WhatsApp et statut (en attente, confirmé ou
 * annulé). Les boutons de confirmation et d'annulation sont désactivés si
 * le participant a déjà été confirmé ou annulé.
 *
 * @param {object[]} participants - La liste des participants
 * @param {function(number, string):void} onUpdateStatut - La fonction à
 *   appeler pour mettre à jour le statut d'un participant
 *
 * @returns {JSX.Element} - Le JSX de la liste des participants
 */
export function ParticipantList({ participants, onUpdateStatut }) {
  const handleWhatsAppClick = (number) => {
    window.open(`https://wa.me/${number.replace(/\+/g, "")}`, "_blank");
  };

  /**
   * Retourne un badge indiquant le statut d'un participant
   *
   * @param {string} statut - Le statut du participant (en attente, confirmé ou annulé)
   * @returns {JSX.Element} - Le badge indiquant le statut
   */
  const getStatutBadge = (statut) => {
    switch (statut) {
      case STATUT.CONFIRME:
        return (
          <span className="flex items-center gap-1 px-2 py-1 bg-green-100 text-green-800 rounded-full text-sm">
            <CheckCircle size={14} />
            Confirmé
          </span>
        );
      case STATUT.ANNULE:
        return (
          <span className="flex items-center gap-1 px-2 py-1 bg-red-100 text-red-800 rounded-full text-sm">
            <XCircle size={14} />
            Annulé
          </span>
        );
      default:
        return (
          <span className="flex items-center gap-1 px-2 py-1 bg-yellow-100 text-yellow-800 rounded-full text-sm">
            <Clock size={14} />
            En attente
          </span>
        );
    }
  };

  return (
    <div
      className=" col-12 col-md-5 d-flex flex-column gap-4 bg-white p-4 rounded shadow  border-4 border-l-4 border-\[\#25D366\] rounded-lg "
      style={{
        borderColor: "#25D366",
      }}
    >
      <div className="flex items-center gap-2 mb-6">
        <Users className="text-[#25D366]" size={24} />
        <h2 className="text-xl font-semibold text-gray-800">
          Liste des participants
        </h2>
      </div>

      {participants.length === 0 ? (
        <div className="text-center py-8">
          <Users className="mx-auto text-gray-400 mb-3" size={48} />
          <p className="text-gray-500">Aucun participant enregistré</p>
        </div>
      ) : (
        <div className="space-y-4">
          {participants.map((participant) => (
            <div
              key={participant.id}
              className="border border-gray-200 rounded-lg p-4 hover:bg-gray-50 transition-colors"
            >
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="font-medium text-gray-800">
                    {participant.nom_Et_Prenom}
                  </h3>
                  <p className="font-medium text-gray-800">
                    {participant.email}
                  </p>
                  <button
                    onClick={() => handleWhatsAppClick(participant.whatsapp)}
                    className="flex items-center gap-1 text-sm text-[#25D366] hover:text-[#128C7E] mt-1"
                  >
                    <MessageCircle size={16} />
                    {participant.whatsapp}
                  </button>
                </div>
                <div className="flex flex-col gap-2 items-end">
                  <span
                    className={`px-3 py-1 rounded-full text-sm ${
                      participant.commissionId === COMMISSION_FIDELE.id
                        ? "bg-green-100 text-green-800"
                        : "bg-blue-100 text-blue-800"
                    }`}
                  >
                    {participant.commissionId === COMMISSION_FIDELE.id
                      ? `${COMMISSION_FIDELE.pourcentage}% (Fidélité)`
                      : `${COMMISSION_BASE.pourcentage}% (Standard)`}
                  </span>
                  {getStatutBadge(participant.statut)}
                </div>
              </div>
              <div className="mt-2 text-sm text-gray-500">
                Inscrit le:{" "}
                {new Date(participant.dateInscription).toLocaleDateString()}
                <span className="mx-2">•</span>
                {participant.nombreParticipations} participation
                {participant.nombreParticipations > 1 ? "s" : ""}
              </div>
              <div className="mt-2 text-sm text-gray-500">
                Ajouté par : <span>{participant.ajoute_par}</span>
              </div>
              <div className="mt-3 flex gap-2 justify-end">
                <button
                  onClick={() =>
                    onUpdateStatut(participant.id, STATUT.CONFIRME)
                  }
                  className="px-3 py-1 text-sm bg-green-600 text-white rounded hover:bg-green-700 transition-colors flex items-center gap-1"
                  disabled={participant.statut === STATUT.CONFIRME}
                >
                  <CheckCircle size={14} />
                  Confirmer
                </button>
                <button
                  onClick={() => onUpdateStatut(participant.id, STATUT.ANNULE)}
                  className="px-3 py-1 text-sm bg-red-600 text-white rounded hover:bg-red-700 transition-colors flex items-center gap-1"
                  disabled={participant.statut === STATUT.ANNULE}
                >
                  <XCircle size={14} />
                  Annuler
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
