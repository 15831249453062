// import React from 'react';
// import { CheckCircle2 } from 'lucide-react';
// import { Advantage } from '../types/advantage';

// export function AdvantageCard({ title, description }: Advantage) {
//   return (
//     <div className="flex items-start space-x-4 p-6 rounded-xl bg-blue-50 hover:bg-blue-100 transition-colors">
//       <CheckCircle2 className="w-6 h-6 text-blue-600 flex-shrink-0 mt-1" />
//       <div>
//         <h3 className="font-semibold text-lg mb-2">{title}</h3>
//         <p className="text-gray-600">{description}</p>
//       </div>
//     </div>
//   );
// }
// ------------------------------------------------------------
import React from "react";
import { CheckCircle2 } from "lucide-react";

export function AdvantageCard({ title, description }) {
  return (
    <div style={styles.card}>
      <CheckCircle2 style={styles.icon} />
      <div>
        <h3 style={styles.title}>{title}</h3>
        <p style={styles.description}>{description}</p>
      </div>
    </div>
  );
}

const styles = {
  card: {
    display: "flex",
    alignItems: "flex-start",
    gap: "16px",
    padding: "24px",
    borderRadius: "12px",
    backgroundColor: "#EBF8FF",
    transition: "background-color 0.3s",
    ":hover": {
      backgroundColor: "#DBF4FF",
    },
  },
  icon: {
    width: "24px",
    height: "24px",
    color: "#2563EB",
    flexShrink: 0,
    marginTop: "4px",
  },
  title: {
    fontWeight: "600",
    fontSize: "18px",
    marginBottom: "8px",
  },
  description: {
    color: "#4B5563",
  },
};
